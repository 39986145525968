<template>
    <bigmodal @close="closeWindow()" :title="lang['activeSessions']">
        <div v-if="currentSession" class="ul-container">
            <div class="description">
                <b-alert variant="info" show>
                    <p>{{ lang['sessionDesc1'] }}</p>
                    <p>{{ lang['sessionDesc2'] }}</p>
                </b-alert>
            </div>
            <hr>
            <div class="current-session" @click="terminateSession(currentSession)">
                <h5>{{ lang['currentSession'] }}</h5>
                <ul class="session-list">
                    <li class="session">
                        <div class="session__avatar">
                            <img :src="currentSession.token_info.icon">
                        </div>
                        <div class="session__info">
                            <div class="session__name">{{ mutateBrowserData(currentSession.token_info.device) }}</div>
                            <div class="session__status">{{ mutateDeviceData(currentSession.token_info.device) }}</div>
                            <div class="session__time">{{ mutateLocationTimeData(currentSession) }}</div>
                        </div>
                    </li>
                </ul>
            </div>
            <div v-if="sessionData.length > 0" class="out-block">
                <span class="exit" @click="terminateAllSessions()">{{ lang['exitAllDevices'] }}</span>
            </div>
            <div v-if="sessionData.length > 0" class="main-list">
                <hr>
                <h5>{{ lang['activeSessions'] }}</h5>
                <ul class="session-list">
                    <li v-for="(item, index) in sessionData" :key="index" @click="terminateSession(item)" class="session">
                        <div class="session__avatar">
                            <img :src="item.token_info.icon">
                        </div>
                        <div class="session__info">
                            <div class="session__name">{{ mutateBrowserData(item.token_info.device) }}</div>
                            <div class="session__status">{{ mutateDeviceData(item.token_info.device) }}</div>
                            <div class="session__time">{{ mutateLocationTimeData(item) }}</div>
                        </div>
                        <span class="close-btn">✕</span>
                    </li>
                </ul>
            </div>
            <hr>
            <div class="auto-close"  v-show="false">
                <h5>{{ lang['autoCloseSessions'] }}</h5>
                <div class="activity-close">
                    <span class="span-close">{{ lang['nonActivePeriod'] }}:</span>
                    <b-form-select class="select" @change="selectPeriod(selected)" v-model="selected"
                        :options="options"></b-form-select>
                </div>
            </div>
        </div>
        <div v-else>
            <h5 class="noData">{{ lang['noData'] }}</h5>
        </div>
        <!-- модалка завершения сессий -->
        <smallModal v-if="showConfirmModal" @escape="showConfirmModal = false">
            <div v-if="closeAllSessions">
                <div>
                    {{ lang['sureToTerminate'] }}
                </div>
            </div>
            <div v-else>
                <div class="modal__header">
                    <div class="modal__avatar">
                        <img :src="choosenSession.token_info.icon">
                    </div>
                    <div class="modal__name">{{ mutateBrowserData(choosenSession.token_info.device) }}</div>
                    <div class="session__time">{{ choosenSession.last_active ? choosenSession.last_active :
                        lang['more3monthAgo'] }}</div>
                </div>
                <hr>
                <div class="modal__body">
                    <h6>{{ lang['info'] }}:</h6>
                    <div class="modal__info__block">
                        <div class="modal__info">{{ choosenSession.token_info.ip }}</div>
                        <div class="modal__desc_el">{{ lang['ip'] }}</div>
                    </div>
                    <div class="modal__info__block">
                        <div class="modal__info">{{ mutateLocation(choosenSession) }}</div>
                        <div class="modal__desc_el">{{ lang['geoPos'] }}</div>
                    </div>
                </div>
            </div>
            <hr>
            <div v-if="closeAllSessions" class="buttons">
                <button class="btn btn-secondary modal-buttons"
                    @click="showConfirmModal = closeAllSessions = false">{{ lang['zakrit'] }}
                </button>
                <button class="btn btn-danger modal-buttons" @click="confirmTerminateAllSessions()">{{
                    lang['Confirm'] }}
                </button>
            </div>
            <div v-else class="buttons">
                <button class="btn btn-secondary modal-buttons"
                    :style="choosenSession.token !== currentSession.token ? 'width: 49%' : 'width: 100%'"
                    @click="showConfirmModal = false">{{ lang['zakrit'] }}
                </button>
                <button v-if="choosenSession.token !== currentSession.token"
                    class="btn btn-danger modal-buttons" @click="confirmTerminate()">{{ lang['terminate'] }}
                </button>
            </div>
        </smallModal>
        <template v-slot:buttons>
            <div class="buttonitem">
                <button class="btn btn-secondary main-buttons" @click="$emit('close')">
                    {{ lang['zakrit'] }}
                </button>
            </div>
        </template>
    </bigmodal>
</template>

<script>
import { store } from '../../store';
import { regAPi } from '@/API.js';
import smallModal from "../modals/smallModal";
import bigmodal from "../modals/bigModal";
import UAParser from 'ua-parser-js';
//import socket from "@/socket/websocket.js";
const parser = new UAParser();
const apiList = new regAPi();

export default {
    components: {
        bigmodal,
        smallModal
    },
    data() {
        return {
            sessionData: null,
            selected: 1,
            options: [],
            showConfirmModal: false,
            closeAllSessions: false,
            choosenSession: null,
            currentSession: null,
        }
    },
    created() {
        this.options = [
            { value: 1, text: this.lang['oneWeek'] },
            { value: 2, text: this.lang['oneMonth'] },
            { value: 3, text: this.lang['threeMonth'] },
            { value: 4, text: this.lang['sixMonth'] }
        ]
        this.getData()
    },
    methods: {
        getData() {


        // let activeByAccountIdData = {
        //     send: "activeByAccountId",
        //     accountId: store.userSettings.accountId
        // };
        // console.log("activeByAccountId",activeByAccountIdData);
        // socket.send(JSON.stringify(activeByAccountIdData));



            apiList.getSessions().then(resp => {
                if (resp.data.status == "done") {
                    this.sessionData = resp.data.data.sessions
                    this.selected = resp.data.data.terminate_old_sessions_after
                    this.option = this.options.find(option => option.value == resp.data.data.terminate_old_sessions_after)
                    // находим текущую сессию
                    this.currentSession = this.sessionData.find(item => item.token === store.token);

                    // подбираем иконку в зависимости от браузера
                    this.sessionData = this.sessionData.map(item => {
                        const result = parser.setUA(item.token_info.device).getResult();
                        console.log(result)
                        switch (result.browser.name) {
                            case "Opera":
                                item.token_info.icon = require("@/assets/OperaBrowser.svg")
                                break;
                            case "Chrome":
                                item.token_info.icon = require("@/assets/ChromeBrowser.svg")
                                break;
                            case "IE":
                                item.token_info.icon = require("@/assets/ExlorerBrowser.svg")
                                break;
                            case "Mozilla":
                                item.token_info.icon = require("@/assets/MozillaBrowser.svg")
                                break;
                            case "Edge":
                                item.token_info.icon = require("@/assets/EdgeBrowser.svg")
                                break;
                            case "Safari":
                            case "Mobile Safari":
                                item.token_info.icon = require("@/assets/SafariBrowser.svg")
                                break;
                            case "WebKit":
                            case "Chrome WebView":
                                item.token_info.icon = require("@/assets/SkyserviceLogo2022.svg")
                                break;
                            default:
                                item.token_info.icon = require("@/assets/browserDefault.svg")
                                break;
                        }
                        return item
                    })
                    // кикаем текущую сессию с общего списка
                    this.sessionData = this.sessionData.filter(item => item.token !== store.token)
                } else {
                    window.say.messageError(this.lang['Error']);
                }
            })
        },
        mutateBrowserData(device) {
            const result = parser.setUA(device).getResult();
            const browserName = result.browser.name ? result.browser.name : ''
            const BrowserMajor = result.browser.major ? result.browser.major : ''
            return browserName + ' ' + BrowserMajor
        },
        mutateDeviceData(device) {
            const result = parser.setUA(device).getResult();
            const osName = result.os.name ? result.os.name : ''
            const osVersion = result.os.version ? result.os.version : ''
            return osName + ' ' + osVersion
        },
        mutateLocationTimeData(item) {
            const country = item.token_info.country ? item.token_info.country : '';
            const city = item.token_info.city ? ', ' + item.token_info.city : '';
            const lastActive = item.last_active ? ' - ' + item.last_active : ' - ' + this.lang['more3monthAgo'];
            return country + city + lastActive
        },
        mutateLocation(item) {
            const country = item.token_info.country ? item.token_info.country : '';
            const city = item.token_info.city ? ', ' + item.token_info.city : '';
            return country + city
        },
        terminateSession(session) {
            session.token == this.currentSession.token ? session.currentSession = true : ''
            this.choosenSession = session
            this.showConfirmModal = true
        },
        confirmTerminate() {
            apiList.terminateChoosenSession(this.choosenSession.token).then(resp => {
                if (resp.data.status == "done") {
                    this.getData()
                    window.say.message(this.lang['Saved']);
                } else {
                    window.say.messageError(this.lang['Error']);
                }
            })
            this.showConfirmModal = this.closeAllSessions = false
        },
        terminateAllSessions() {
            this.showConfirmModal = this.closeAllSessions = true
        },
        confirmTerminateAllSessions() {
            apiList.terminateAllSessions().then(resp => {
                if (resp.data.status == "done") {
                    this.getData()
                    window.say.message(this.lang['Saved']);
                } else {
                    window.say.messageError(this.lang['Error']);
                }
            })
            this.showConfirmModal = this.closeAllSessions = false
        },
        selectPeriod(period) {
            apiList.periodSessionsNonActive(period).then(resp => {
                if (resp.data.status == "done") {
                    this.getData()
                    window.say.message(this.lang['Saved']);
                } else {
                    window.say.messageError(this.lang['Error']);
                }
            })
        },
        closeWindow() {
            this.$emit("close");
        },
    },
    computed: {
        lang() {
            return window.lang;
        },
    }
}
</script>

<style scoped>
.modal__header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-buttons {
    width: 49%;
}

.buttons {
    display: flex;
    justify-content: space-between;
}

.span-close {
    font-weight: bold;
    margin-right: 5px;
}

.span__option {
    color: blue;
}

.select {
    margin-left: 1%;
    width: 20%;
}

.activity-close {
    display: flex;
    align-items: center;
    padding-left: 15px;
}

.out-block {
    width: 100%;
    align-items: center;
    text-align: center;
}

.exit {
    color: red;
    font-size: large;
    cursor: pointer;
}

.main-buttons {
    width: 100%
}

.ul-container {
    padding: 15px;
}

.buttonitem {
    width: 100%;
}

.session-list {
    list-style: none;
    padding: 0;
}

.session {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.session:hover {
    background-color: #f5f5f5;
}

.modal__avatar {
    flex: none;
}

.modal__avatar img {
    width: 60px;
    height: 60px;
    margin-bottom: 5px;
}

.modal__body h6 {
    font-weight: bold;
    font-size: 16px;
}

.session__avatar {
    flex: none;
    margin-right: 10px;
}

.session__avatar img {
    width: 40px;
    height: 40px;
}

.session__info {
    flex: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.session__name,
.modal__name {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.session__status {
    font-size: 14px;
    color: #999;
}

.session__time,
.modal__desc_el {
    font-size: 14px;
    color: #999;
}

.close-btn {
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
    font-weight: bold;
    padding-right: 10px;
}

.noData {
    text-align: center;
    margin-top: 1rem;
    width: 100%;
    color: gray;
    font-weight: 400;
}

.trash-icon-red {
    color: red;
}

.description p {
    font-size: 16px;
    line-height: 1.5;
}

.modal__info__block {
    padding-top: 10px;
}

.options-info {
    width: 25%;
    white-space: nowrap;
}

.options-modal__desc,
.options-modal__radio {
    max-width: 300px;
    padding: 5px;
}

@media screen and (max-width: 700px) {
    .options-modal__desc {
        max-width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .activity-close {
        flex-direction: column;
        align-items: baseline;
    }

    .select {
        width: 50%;
    }

    .span-close {
        margin-bottom: 5px;
    }
}
</style>