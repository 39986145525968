/* eslint-disable */ 
import { webviewCheck } from './webviewCheck';
function androidFixMono (idContainer) {
    const androidContainer = document.querySelector("#" + idContainer);

    try{
        if (webviewCheck() == 'android_webview') {

            let s;
            const androidBrov = Android.getDisplayCutoutTop();
            
            if (window.devicePixelRatio > 1.0) {
                s = androidBrov / window.devicePixelRatio;
            }
            if (androidBrov != 0) {
                if(androidContainer){
                    androidContainer.style.paddingTop =  s + 'px';
                }
            }
        }
    } catch(err) {
        console.log(err);
    }
}
export { androidFixMono }