import { keyevent } from '@/main.js';
export default window.addEventListener('keydown', event => {
    if (event.keyCode === 27) {
        keyevent.$emit('esc');
    } else if (event.keyCode === 16) {
        keyevent.$emit('shift');
    } else if (event.keyCode === 46){
        keyevent.$emit('delete');
    } else if(event.keyCode === 13){
        keyevent.$emit('enter');
    }
})