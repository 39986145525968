<template>
  <smallModal @escape="$emit('close')">
    <h5>{{ lang["restore"] }}</h5>
    <hr />
    <div>
      <label :for="newCompanyName">{{ lang["DoYouWantRestore"] }} {{ newCompanyName }}?</label>
    </div>
    <div v-if="ccc">
      <div class="buttons-flex-end">
        <button @click="getCaptcha" class="btn">
          <b-icon-arrow-repeat id="captcha-leave"></b-icon-arrow-repeat>
        </button>
      </div>
      <div class="imgDiv">
        <img :src="ccc" class="capImg">
      </div>
      <label :for="captchaName">{{ lang["EnterCaptcha"] }}</label>
      <b-form-input v-model="cap.captcha" :state="capState" :name="captchaName" autofocus />
    </div>
    <label :for="accountPassword">{{ lang["Password"] }}</label>
    <b-form-input v-model="accountPassword" type="password" :state="passState" :name="accountPassword" />
    <hr />
    <div class="buttons">
      <button class="btn btn-secondary" @click="$emit('close')">{{ lang["Сancel"] }}</button>
      <button class="btn btn-success" @click="save" :disabled="!(passState && capState)">{{ lang["Proceed"] }}</button>
    </div>
  </smallModal>
</template>

<script>
import { keyevent } from '@/main.js';
import { rotate } from '../js/rotateBtn';
import { guid } from '../js/guid.js';
import { regAPi } from '@/API.js';
import smallModal from "../modals/smallModal";

const apiList = new regAPi();

export default {
  props: ["targetCompany"],
  components: {
    smallModal,
  },
  data() {
    return {
      cap: {
        captcha: "",
        captcha_guid: "",
      },
      accountPassword: "",
      ccc: null,
      captchaName: `captcha_${Math.floor(Math.random() * (99999 - 999 + 1) + 999)}`,
      newCompanyName: "",
    };
  },
  mounted() {
    console.log(this.targetCompany);
    this.newCompanyName = this.targetCompany.company_name;
    keyevent.$off('enter');
    keyevent.$on('enter', this.save);
  },
  created() {
    this.getCaptcha();
  },
  methods: {
    getCaptcha() {
      try {
        const captchaGuid = guid();
        const captchaUrl = apiList.captchaUID(captchaGuid);
        this.ccc = captchaUrl;
        this.cap.captcha_guid = captchaGuid;
        rotate("#captcha-leave");
      } catch (err) {
        console.log(err);
      }
    },
    save() {
      if (this.passState && this.capState) {
        const data = {
          password: this.accountPassword,
          ...this.cap,
          company_id: this.targetCompany.company_id,
        };
        console.log(this.targetCompany.company_id);
        this.$emit("save", data);
      } else {
        window.say.messageError(this.lang['Password']);
      }
    },
  },
  computed: {
    passState() {
      return this.accountPassword.length >= 4;
    },
    capState() {
      return this.cap.captcha.length === 4;
    },
    lang() {
      return window.lang;
    },
  },
};
</script>

<style scoped>
.btn-secondary, .btn-success{
  width: 49%;
}
.buttons{
  display: flex;
  justify-content: space-between;
}
.imgDiv{
  text-align: center; 
  height: 100px;
}
.capImg{
  height: 100px;
}
.buttons-flex-end{
  display: flex; 
  justify-content: flex-end;
}
#captcha-leave{
  height: 2rem; 
  width: 2rem;
}
</style>