import { store } from '@/store.js';

export default function() {

	let lang = store.langs[store.lang]()

	return { 
		jsonLang :{
			hint: lang['cropImageHint'],
			loading: lang['Zagruzka...'],
			noSupported: lang['cropImageNoSupported'],
			success: lang['cropImageSuccess'],
			fail: lang['cropImageFail'],
			preview: lang['preview'],
			btn: {
				off: lang['Сancel'],
				close: lang['zakrit'],
				back: lang['nazad'],
				save: lang['save']
			},
			error: {
				onlyImg: lang['cropImageOnlyImgNeed'],
				outOfSize: lang['cropImageOutOfSize']+": ",
				lowestPx: lang['cropImageLowestPx']+': '
			}
		}
	}
}
