const lang = {
    "ToAccept": "Accepter",
    "Reject": "Rejeter",
    "Password": "Mot de passe",
    "RepeatPassword": "Répéter le mot de passe",
    "OldPassword": "ancien mot de passe",
    "MyCompany": "Mes entreprises",
    "Company": "Société",
    "CreateNewCompany": "Créez votre entreprise",
    "Сancel": "Annuler",
    "Save": "Sauver",
    "GeneralInformation": "informations générales",
    "CompanyName": "Nom de la compagnie",
    "LogOut": "Se déconnecter",
    "SignOutOfAccount": "Déconnexion",
    "ChangePassword": "Changer le mot de passe",
    "AddPassword": "Ajouter un mot de passe",
    "Language": "Langue",
    "ConfirmThisMail": "Votre email a été confirmé",
    "Invites": "invitations",
    "AcceptInvitation": "Accepter l'invitation",
    "EMailOrPhone": "Téléphone ou courrier",
    "Saved": "Enregistré",
    "Error": "Erreur",
    "FillInAllTheFields": "Remplissez tous les champs",
    "FirstCreateAnAccountPassword": "Tout d'abord, créez un mot de passe de compte",
    "Settings": "Paramètres",
    "Next": "Davantage",
    "MinPassLength": "Le mot de passe est trop court",
    "action": "Action",
    "NewOwner": "Nouveau propriétaire",
    "UserMustBeRegistered": "L'utilisateur doit être enregistré",
    "LeaveTheCompany": "Quitter l'entreprise",
    "Confirm": "Confirmer",
    "WrongFormat": "Mauvais format",
    "DeleteAccount": "Supprimer le compte",
    "WrongPassword": "Mauvais mot de passe",
    "PhoneAlreadyRegistered": "Téléphone déjà enregistré",
    "EmailAlreadyRegistered": "Courrier déjà enregistré",
    "Yes": "Oui",
    "CreateACompany": "Créer une entreprise",
    "MyReferrals": "Mes recommandations",
    "Edit": "Changement",
    "NewEmail": "Nouveau courrier",
    "Proceed": "Continuez",
    "IncorrectCaptcha": "Mauvais captcha",
    "CaptchaIsNotValid": "Le captcha n'est plus valide",
    "EnterCaptcha": "Entrez le capcha",
    "EnterSMS": "Entrez SMS",
    "SMSCodeIsNoLongerValid": "Le code SMS n'est plus valide",
    "SMSCodeIsIncorrect": "Le code SMS est incorrect",
    "MailNotVerifiedLinkTimedOut": "Mail non confirmé, lien expiré",
    "ReferralLink": "Lien de référence",
    "Date": "date de",
    "EarnWithUs": "Gagnez avec nous",
    "RecommendSkyservicePOSToYourFriendsAndGetRealMoneyToYourAccount.": "Recommandez Skyservice POS à vos amis et obtenez de l'argent réel sur votre compte.",
    "HowItWorks": "Comment ça fonctionne?",
    "ItsVerySimpleYouBringCustomersToSkyservicePOSAndWeShareTheirPaymentsWithYou.": "C'est très simple : vous amenez des clients à Skyservice POS, et nous partageons leurs paiements avec vous.",
    "Copied": "Copié",
    "TheMinimumWithdrawalAmountIs100USD": "*Montant minimum de retrait 100 USD",
    "AddEmail": "Ajouter un e-mail",
    "YouDontHaveCompanyYet": "Vous n'avez pas encore d'entreprise",
    "NoCompanyText": "Pour commencer à travailler avec la caisse enregistreuse, vous devez créer une entreprise ou accepter une invitation d'un employeur.",
    "ConfirmEmail": "Un e-mail a été envoyé pour confirmer le changement.",
    "description": "La description",
    "NoDescriptionSpecified": "Pas de description",
    "DateCreate": "Date de création:",
    "DelComp": "Supprimer l'entreprise",
    "Help": "Soutien",
    "Email": "Poster",
    "Phone": "Téléphone",
    "SureAccDel": "Voulez-vous vraiment dissocier votre compte ?",
    "no": "Pas",
    "yes": "Oui",
    "confirm": "Confirmer",
    "cancel": "Annuler",
    "Search": "Rechercher",
    "dateDelete": "Date de suppression :",
    "active": "Actif",
    "delete": "Supprimé",
    "services": "Prestations de service",
    "status": "Statut",
    "recover": "Rétablir",
    "restored": "restauré",
    "restore": "Récupération",
    "DoYouWantRestore": "Voulez-vous restaurer la campagne",
    "deletedCompamies": "ENTREPRISES ÉLOIGNÉES",
    "CompanyNotSearch": "Aucune entreprise trouvée correspondant à vos critères",
    "writeCorrectlyName": "Entrez le nom d'utilisateur correctement",
    "addlogo": "Ajouter un logo",
    "Logo": "Logo",
    "nipmg": "Cliquez ou faites glisser un fichier dans cette fenêtre",
    "ErrorDownload": "erreur de chargement",
    "preview": "Aperçu",
    "onlyimg": "Images uniquement",
    "imgisbig": "L'image dépasse la limite de taille",
    "minimgsize": "Taille minimale de l'image",
    "Zagruzka...": "Chargement...",
    "Youbrowserisold": "Votre navigateur n'est pas pris en charge, veuillez utiliser IE10+ ou d'autres navigateurs",
    "failZagruzhen": "Fichier téléchargé avec succès !",
    "zakrit": "Fermer",
    "nazad": "Dos",
    "save": "Sauver",
    "zagruzit_foto": "Télécharger une photo",
    "clear": "Dégager",
    "logoIsAdd": "Logo ajouté",
    "logoIsDel": "Logo effacé",
    "editComp": "Modifier l'entreprise",
    "giveComp": "Société de transfert",
    "thisUserNorRegistered": "Cet utilisateur n'est pas enregistré",
    "cantTransferToYou": "Vous ne pouvez pas céder votre entreprise à vous-même",
    "youNotOwner": "Vous n'êtes pas le propriétaire de l'entreprise",
    "Ok": "D'ACCORD",
    "notYetCreateComp": "Pour le moment, la création de nouvelles entreprises n'est pas disponible",
    "EnterToCompany": "Connectez-vous à l'entreprise",
    "startDescription": "Bienvenue sur votre compte personnel, connectez-vous à l'entreprise pour commencer",
    "inviteComp": "Invitation à l'entreprise",
    "visitDemo": "Visitez la société de démonstration",
    "makeUrChoice": "Fais un choix",
    "ImWorker": "je suis salarié",
    "ImOwner": "Je suis un employeur, représentant de l'employeur",
    "needInviteComp": "Vous devez accepter une invitation dans une entreprise existante",
    "needCreateComp": "Vous devez créer une entreprise pour commencer",
    "daysLeft": "Jours restants",
    "cannotDel": "La désinstallation est actuellement indisponible",
    "NoCompanyCreateText": "Pour commencer à travailler avec la caisse enregistreuse, vous devez accepter une invitation de l'employeur.",
    "withoutName": "Sans nom",
    "cropImageHint": "Cliquez ou faites glisser un fichier pour le télécharger",
    "cropImageNoSupported": "Navigateur non pris en charge, veuillez utiliser IE10+ ou d'autres navigateurs.",
    "cropImageSuccess": "Téléchargement réussi",
    "cropImageFail": "Échec du téléchargement",
    "cropImageOnlyImgNeed": "Images uniquement",
    "cropImageOutOfSize": "La taille de l'image dépasse la limite",
    "cropImageLowestPx": "Taille minimale de l'image",
    "uReachedLimitComp": "Vous avez atteint la limite du nombre d'entreprises créées",
    "accAlreadyRegister": "Ce compte est déjà lié à un autre compte",
    "accNotRegister": "Un tel compte n'existe pas",
    "areYourCountry": "est-ce votre pays?",
    "enterCorrectNumber": "Entrez le numéro au format international",
    "attach": "lier",
    "UserEmailAlreadyExists": "Utilisateur avec ce courriel existe déjà",
    "AccountAlreadyLinked": "Compte déjà lié",
    "facebookAccIsAlreadyRegistered": "Ce compte Facebook est déjà lié à un autre compte Skyservice",
    "googleAccIsAlreadyRegistered": "Ce compte Google est déjà lié à un autre compte Skyservice",
    "telegramAccAlreadyRegister": "Ce télégramme est déjà lié à un autre compte Skyservice",
    "companyBlocked": "Cette société est inactive",
    "allSessions": "Afficher toutes les séances",
    "activeSessions": "Sessions actives",
    "exitAllDevices": "Déconnectez-vous sur tous les autres appareils",
    "currentSession": "Session actuelle",
    "autoCloseSessions": "Auto-complétion de session",
    "nonActivePeriod": "Se termine automatiquement si la session n'est pas active",
    "oneWeek": "1 semaine",
    "oneMonth": "1 mois",
    "threeMonth": "3 mois",
    "sixMonth": "6 mois",
    "terminate": "Complet",
    "sessionDesc1": "Tous les appareils connectés à votre compte Skyservice sont répertoriés ici. Vous pouvez vous déconnecter de votre compte sur chacun d'eux individuellement ou sur tous à la fois.",
    "sessionDesc2": "Si vous trouvez un appareil inconnu dans la liste, déconnectez-vous immédiatement de cet appareil et modifiez le mot de passe de votre compte Skyservice.",
    "sureToTerminate": "Voulez-vous vraiment mettre fin à toutes les sessions actives ?",
    "ip": "adresse IP",
    "geoPos": "Géoposition",
    "info": "Information",
    "sessionDesc3": "Les sessions qui n'ont aucune activité pendant cette période se termineront automatiquement.",
    "terminatesSessions": "Fin des sessions",
    "more3monthAgo": "Il y a plus de trois mois",
    "noData": "Pas de données",
    "goSettingsCompany": "Nous créons votre entreprise, donnez-nous une minute...",
    "preparingCompany1": "Nous créons votre entreprise, accordez-nous une minute...",
    "preparingCompany2": "Nous préparons une interface de gestion des biens et services...",
    "preparingCompany3": "Nous travaillons sur une comptabilité financière pratique...",
    "preparingCompany4": "Nous effectuons les derniers réglages, presque prêts...",
    "appleAccIsAlreadyRegistered": "Cet AppleId est déjà lié à un autre compte Skyservice",
    "notGetDataFromApple": "Échec de la réception des données d'Apple"
}

export default function() {
    return lang;
}