<template>
    <smallModal>
        <div>
            <span>{{ lang['CreateNewCompany'] }}?</span>
            <hr>
            <div class="buttons">
                <button class="btn btn-secondary" @click="cansel">{{ lang['Сancel'] }}</button>
                <button class="btn btn-success" @click="yes">{{ lang['Yes'] }}</button>
            </div>
        </div>
    </smallModal>
</template>

<script>
import smallModal from '../modals/smallModal'
export default {
    components: {
        smallModal
    },
    methods: {
        cansel() {
            this.$emit('close');
            this.$emit('add_mail');
        },
        yes() {
            this.$emit('goNext');
            this.$emit('close');
        }
    },
    computed: {
        lang() {
            return window.lang;
        }
    }
}
</script>

<style scoped>
.buttons{
    display: flex; 
    justify-content: space-between;
}
.btn-secondary, .btn-success{
  width: 49%;
}
</style>