<template>
    <smallModal @escape="$emit('close')">
        <div
            class="confirm-remove-acc"
        >
        <span>{{ lang['SureAccDel'] + ' ' + service }}?</span>
      </div>
      <hr />
      <div class="buttons">
        <button class="btn btn-secondary" @click="$emit('n')">{{ lang['Сancel'] }}</button>
        <button class="btn btn-success" @click="save">{{ lang['Confirm'] }}</button>
      </div>
      <!-- этот див для автофокуса, чтобы работал Enter -->
      <div ref="confirmRemoveAcc"
        tabindex="0">
      </div>
    </smallModal>
  </template>

<script>
import { keyevent } from '@/main.js';
import smallModal from '../modals/smallModal';

export default {
    props: ['service'],
    components: {
        smallModal
    },
    data() {
        return {
        }
    },
    created() {
    },
    mounted(){
        // автофокус для дива
        this.$nextTick(() => {
            this.$refs.confirmRemoveAcc.focus();
        });
        keyevent.$off('enter');
        keyevent.$on('enter', () =>
            this.save()
        );
    },
    methods: {
        // событие для клавишы (можно удалить после теста)
        // onKeyDown(event) {
        // if (event.key === "Enter") {
        //     this.save()
        // }
        // },
        save() {
           this.$emit("y");
           this.$emit("close");
        }
    },
    computed: {
        lang() {
            return window.lang
        },
    }
}
</script>

<style scoped>
.buttons{
    display: flex; 
    justify-content: space-between;
}
.btn-secondary, .btn-success{
  width: 49%;
}
.confirm-remove-acc{
    text-align: center;
}
</style>