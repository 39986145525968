<template>
    <smallModal @escape="$emit('close')">
        <div v-if="ccc != null">
            <div class="buttons-flex-end">
                <button @click="captchaImg" class="btn">
                    <b-icon-arrow-repeat id="captcha-add-company"></b-icon-arrow-repeat>
                </button>
            </div>
            <div class="imgDiv">
                <img :src="ccc" class="capImg">
            </div>
            <label :for="captchaName">{{ lang['EnterCaptcha'] }}</label>
            <b-form-input v-model="captcha" :state="capState" autofocus :name="captchaName" />
        </div>
        <div>
            <label for="phone">{{ lang['Password'] }}</label>
            <b-form-input type="password" id="password-input" :state="statePass" v-model="password" />
        </div>
        <hr>
        <div class="buttons">
            <button class="btn btn-secondary" @click="cansel">{{ lang['Сancel'] }}</button>
            <button :disabled="$store.disableButton" class="btn btn-success" @click="save">{{ lang['Save'] }}</button>
        </div>
    </smallModal>
</template>

<script>
import { keyevent } from '@/main.js';
import { rotate } from '../js/rotateBtn';
import { guid } from '../js/guid';
import smallModal from '../modals/smallModal';
import { regAPi } from '@/API.js';
import { store } from "@/store.js"
const apiList = new regAPi();
export default {
    components: {
        smallModal
    },
    data() {
        return {
           password: '',
           captcha: '',
           guidNow: '',
           ccc: null,
           captchaName:'captcha_'+this.getRandNum(999,99999)
        }
    },
    created() {
        this.captchaImg()
    },
    mounted() {
        keyevent.$off('enter');
        keyevent.$on('enter', () => 
            this.save()
        )
    },
    methods: {
        getRandNum(min, max) {
            return Math.random() * (max - min) + min;
        },
        captchaImg() {
            try {
                // создаем гуид (файл guid.js) который скидываем для капичи состарта, потом, если юзер нажал сохранить, то скидываем текущий гуид на сервак для сравнения
                const a = guid();
                const c = apiList.captchaUID(a);
                this.ccc = c;
                this.guidNow = a;
                // крутилка для кнопки
                rotate('#captcha-add-company');
            } catch(err) {
                console.log(err)
            }
        },
        cansel() {
           this.$emit('close')
        },
        // клик по кнопке, закрываем окно и перебрасываем пароль в родителя и данные по капче
        save() {
            if (this.statePass) {
                store.disableButton = true;
                this.$emit('checkPass', { account_password: this.password, captcha: this.captcha, captcha_guid: this.guidNow });
            } else {
                window.say.messageError(this.lang['MinPassLength']);
            }
        }
    },
    computed: {
        lang() {
            return window.lang;
        },
        statePass() {
            return this.password.length >= 1 ? true : false;
        },
        capState() {
            return this.captcha.length == 4 ? true : false;
        }
    }
}
</script>

<style scoped>
.buttons{
    display: flex; 
    justify-content: space-between;
}
.btn-secondary, .btn-success{
  width: 49%;
}
.imgDiv{
  text-align: center; 
  height: 100px;
}
.capImg{
  height: 100px;
}
#captcha-add-company{
  height: 2rem; 
  width: 2rem;
}
.buttons-flex-end{
  display: flex; 
  justify-content: flex-end;
}
</style>