//Модуль поиска по обьектов по массивам обьектов
let search = function (array, searchline, serchType = 'like', prop) {
    let compare = "";
    let contentData = [];
    let searchFunc = function (compareString, context) {
        if (serchType == 'like') {
            let regularSearch = new RegExp(searchline.toString().replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
            if (regularSearch.test(compareString)) {
                contentData.push(context);
                return true;
            }
        } else if (serchType == 'match') {
            if (compareString.toString().toLowerCase() == searchline.toLowerCase()) {
                contentData.push(context);
                return true;
            }
        }
    }

    for (let key in array) {
        if (typeof prop != 'undefined') {
            compare = array[key][prop];
        } else {
            compare = array[key];
        }
        if (Array.isArray(prop)) {
            for (let k in prop) {
                let kkk = prop[k];
                let compares = array[key][kkk]
                if(searchFunc(compares, array[key])){
                    break;
                }
            }
        } else {
            searchFunc(compare, array[key])
        }
    }
    return contentData;
}

export default search;