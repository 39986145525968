const lang = {
    "ToAccept": "Прийняти",
    "Reject": "Відхилити",
    "Password": "Пароль",
    "RepeatPassword": "Повторити пароль",
    "OldPassword": "Старий пароль",
    "MyCompany": "Мої компанії",
    "Company": "Компанія",
    "CreateNewCompany": "Створити нову компанію",
    "Сancel": "Скасувати",
    "Save": "Зберегти",
    "GeneralInformation": "Загальна інформація",
    "CompanyName": "Назва компанії",
    "LogOut": "Вийти",
    "SignOutOfAccount": "Вийти з облікового запису",
    "ChangePassword": "Змінити пароль",
    "AddPassword": "Додати пароль",
    "Language": "Мова",
    "ConfirmThisMail": "Ваш e-mail підтверджений",
    "Invites": "Запрошує",
    "AcceptInvitation": "Прийняти запрошення",
    "EMailOrPhone": "Телефон або пошта",
    "Saved": "Збережено",
    "Error": "Помилка",
    "FillInAllTheFields": "Заповніть всі поля",
    "FirstCreateAnAccountPassword": "Для початку, створіть пароль облікового запису",
    "Settings": "Налаштування",
    "Next": "Далі",
    "MinPassLength": "Пароль занадто короткий",
    "action": "Дія",
    "NewOwner": "Новий власник",
    "UserMustBeRegistered": "Користувач має бути зареєстрований",
    "LeaveTheCompany": "Залишити компанію",
    "Confirm": "Підтвердити",
    "WrongFormat": "Невірний формат",
    "DeleteAccount": "Видалити акаунт",
    "WrongPassword": "Невірний пароль",
    "PhoneAlreadyRegistered": "Телефон уже зареєстрований",
    "EmailAlreadyRegistered": "Пошта вже зареєстрована",
    "Yes": "Так",
    "CreateACompany": "Створити компанію",
    "MyReferrals": "Мої реферали",
    "Edit": "Змінити",
    "NewEmail": "Нова пошта",
    "Proceed": "Продовжити",
    "IncorrectCaptcha": "Неправильна капча",
    "CaptchaIsNotValid": "Капча більше недійсна",
    "EnterCaptcha": "Введіть капчу",
    "EnterSMS": "Введіть SMS",
    "SMSCodeIsNoLongerValid": "СМС код не дійсний",
    "SMSCodeIsIncorrect": "СМС код невірний",
    "MailNotVerifiedLinkTimedOut": "Пошта не підтверджена, минув час посилання",
    "ReferralLink": "Реферальне посилання",
    "Date": "Дата",
    "EarnWithUs": "Заробляйте з нами",
    "RecommendSkyservicePOSToYourFriendsAndGetRealMoneyToYourAccount.": "Рекомендуйте Skyservice POS вашим друзям та отримуйте реальні гроші на свій рахунок.",
    "HowItWorks": "Як це працює?",
    "ItsVerySimpleYouBringCustomersToSkyservicePOSAndWeShareTheirPaymentsWithYou.": "Все дуже просто: Ви наводите клієнтів у Skyservice POS, а ми ділимося з Вами їхніми платежами.",
    "Copied": "Скопійовано",
    "TheMinimumWithdrawalAmountIs100USD": "*Мінімальна сума для виведення 100 USD",
    "AddEmail": "Додати пошту",
    "YouDontHaveCompanyYet": "У вас поки що немає компанії",
    "NoCompanyText": "Для початку роботи з касою необхідно створити компанію або прийняти запрошення від роботодавця.",
    "ConfirmEmail": "На пошту було надіслано листа для підтвердження зміни",
    "description": "Опис",
    "NoDescriptionSpecified": "Немає опису",
    "DateCreate": "Дата створення:",
    "DelComp": "Видалити компанію",
    "Help": "Техпідтримка",
    "Email": "Пошта",
    "Phone": "Телефон",
    "SureAccDel": "Ви дійсно хочете відв'язати ваш обліковий запис",
    "no": "Ні",
    "yes": "Так",
    "confirm": "Підтвердити",
    "cancel": "Скасувати",
    "Search": "Пошук",
    "dateDelete": "Дата видалення:",
    "active": "Активний",
    "delete": "Видалений",
    "services": "Сервіси",
    "status": "Статус",
    "recover": "Відновити",
    "restored": "Відновлено",
    "restore": "Відновлення",
    "DoYouWantRestore": "Ви бажаєте відновити кампанію",
    "deletedCompamies": "ВИДАЛЕНІ КОМПАНІЇ",
    "CompanyNotSearch": "Не знайдено компаній за даними критеріями",
    "writeCorrectlyName": "Введіть ім'я користувача коректно",
    "addlogo": "Додати логотип",
    "Logo": "Логотип",
    "nipmg": "Натисніть або перетягніть файл у вікно",
    "ErrorDownload": "Помилка завантаження",
    "preview": "Передпрогляд",
    "onlyimg": "Тільки зображення",
    "imgisbig": "Зображення перевищує граничний розмір",
    "minimgsize": "Мінімальний розмір зображення",
    "Zagruzka...": "Завантаження...",
    "Youbrowserisold": "Ваш браузер не підтримується, будь ласка, використовуйте IE10+ або інші браузери",
    "failZagruzhen": "Файл успішно завантажено!",
    "zakrit": "Закрити",
    "nazad": "назад",
    "save": "Зберегти",
    "zagruzit_foto": "Завантажити фото",
    "clear": "Очистити",
    "logoIsAdd": "Логотип додано",
    "logoIsDel": "Логотип очищений",
    "editComp": "Редагувати компанію",
    "giveComp": "Передати компанію",
    "thisUserNorRegistered": "Такий користувач не зареєстрований",
    "cantTransferToYou": "Не можна передавати свою компанію собі",
    "youNotOwner": "Ви не є власником компанії",
    "Ok": "Ок",
    "notYetCreateComp": "На даний момент створення нових компаній недоступне",
    "EnterToCompany": "Увійти до компанії",
    "startDescription": "Вітаємо Вас у вашому персональному кабінеті, увійдіть до компанії для початку роботи",
    "inviteComp": "Запрошення до компанії",
    "visitDemo": "Відвідати демо-компанію",
    "makeUrChoice": "Зробіть вибір",
    "ImWorker": "Я найманий працівник",
    "ImOwner": "Я роботодавець, представник роботодавця",
    "needInviteComp": "Необхідно прийняти запрошення до існуючої компанії",
    "needCreateComp": "Необхідно створити компанію для початку роботи",
    "daysLeft": "Залишилось днів",
    "cannotDel": "Видалення зараз недоступне",
    "NoCompanyCreateText": "Для початку роботи з касою необхідно прийняти запрошення від роботодавця.",
    "withoutName": "Без імені",
    "cropImageHint": "Натисніть або перетягніть файл для завантаження",
    "cropImageNoSupported": "Браузер не підтримується, використовуйте IE10+ або інші браузери.",
    "cropImageSuccess": "Завантаження пройшло успішно",
    "cropImageFail": "Завантаження не вдалося",
    "cropImageOnlyImgNeed": "Тільки зображення",
    "cropImageOutOfSize": "Розмір зображення перевищує ліміт",
    "cropImageLowestPx": "Мінімальний розмір зображення",
    "uReachedLimitComp": "Ви досягли ліміту за кількістю створених компаній",
    "accAlreadyRegister": "Цей обліковий запис вже прив'язаний до іншого облікового запису",
    "accNotRegister": "Немає такого облікового запису",
    "areYourCountry": "це ваша країна?",
    "enterCorrectNumber": "Введіть номер у міжнародному форматі",
    "attach": "Прив'язати",
    "UserEmailAlreadyExists": "Користувач із такою поштою вже існує",
    "AccountAlreadyLinked": "Обліковий запис вже прив'язаний",
    "facebookAccIsAlreadyRegistered": "Цей Facebook обліковий запис вже прив'язаний до іншого облікового запису Skyservice",
    "googleAccIsAlreadyRegistered": "Цей обліковий запис Google вже прив'язаний до іншого облікового запису Skyservice",
    "telegramAccAlreadyRegister": "Цей Telegram вже прив'язаний до іншого облікового запису Skyservice",
    "companyBlocked": "Ця компанія неактивна",
    "allSessions": "Показати всі сесіі",
    "activeSessions": "Активні сесії",
    "exitAllDevices": "Вийти на всіх інших пристроях",
    "currentSession": "Поточна сесія",
    "autoCloseSessions": "Автозавершення сесій",
    "nonActivePeriod": "Автоматично завершувати, якщо сесія не активна",
    "oneWeek": "1 тиждень",
    "oneMonth": "1 місяць",
    "threeMonth": "3 місяці",
    "sixMonth": "6 місяців",
    "terminate": "Завершити",
    "sessionDesc1": "Тут перераховані всі пристрої, на яких виконано вхід до вашого облікового запису Skyservice. Ви можете вийти з облікового запису на кожному окремо або на всіх відразу.",
    "sessionDesc2": "Якщо ви знайдете у списку незнайомий пристрій, негайно виконайте вихід на цьому пристрої та змініть пароль від вашого облікового запису Skyservice.",
    "sureToTerminate": "Ви дійсно хочете завершити усі активні сесії?",
    "ip": "IP-адреса",
    "geoPos": "Геопозиція",
    "info": "Інформація",
    "sessionDesc3": "Сеанси, з яких не буде активності протягом цього періоду, будуть завершуватися автоматично.",
    "terminatesSessions": "Завершення сеансів",
    "more3monthAgo": "Більше трьох місяців тому",
    "noData": "Немає даних",
    "goSettingsCompany": "Створюємо вашу компанію, дайте нам хвилинку...",
    "preparingCompany1": "Створюємо вашу компанію, будь ласка, дайте нам хвилинку...",
    "preparingCompany2": "Готуємо інтерфейс для керування товарами та послугами...",
    "preparingCompany3": "Працюємо над зручним обліком фінансів...",
    "preparingCompany4": "Виконуємо фінальні налаштування, майже готово...",
    "appleAccIsAlreadyRegistered": "Цей AppleId вже прив'язаний до іншого облікового запису Skyservice",
    "notGetDataFromApple": "Не вдалося отримати дані від Apple"
}

export default function() {
    return lang;
}