const lang = {
    "ToAccept": "Მიიღოს",
    "Reject": "Უარი თქვას",
    "Password": "პაროლი",
    "RepeatPassword": "გაიმეორეთ პაროლი",
    "OldPassword": "ძველი პაროლი",
    "MyCompany": "ჩემი კომპანიები",
    "Company": "კომპანია",
    "CreateNewCompany": "შექმენით თქვენი კომპანია",
    "Сancel": "გაუქმება",
    "Save": "Გადარჩენა",
    "GeneralInformation": "ზოგადი ინფორმაცია",
    "CompanyName": "Კომპანიის სახელი",
    "LogOut": "Გამოსვლა",
    "SignOutOfAccount": "Სისტემიდან გამოსვლა",
    "ChangePassword": "Პაროლის შეცვლა",
    "AddPassword": "პაროლის დამატება",
    "Language": "Ენა",
    "ConfirmThisMail": "თქვენი ელფოსტა დადასტურდა",
    "Invites": "მოსაწვევები",
    "AcceptInvitation": "Მოწვევის მიღება",
    "EMailOrPhone": "ტელეფონი ან ფოსტა",
    "Saved": "შენახულია",
    "Error": "შეცდომა",
    "FillInAllTheFields": "შეავსეთ ყველა ველი",
    "FirstCreateAnAccountPassword": "პირველი, შექმენით ანგარიშის პაროლი",
    "Settings": "პარამეტრები",
    "Next": "Უფრო",
    "MinPassLength": "პაროლი ძალიან მოკლეა",
    "action": "მოქმედება",
    "NewOwner": "ახალი მფლობელი",
    "UserMustBeRegistered": "მომხმარებელი უნდა იყოს დარეგისტრირებული",
    "LeaveTheCompany": "დატოვე კომპანია",
    "Confirm": "დაადასტურეთ",
    "WrongFormat": "Არასწორი ფორმატი",
    "DeleteAccount": "Ანგარიშის წაშლა",
    "WrongPassword": "Არასწორი პაროლი",
    "PhoneAlreadyRegistered": "ტელეფონი უკვე დარეგისტრირებულია",
    "EmailAlreadyRegistered": "ფოსტა უკვე დარეგისტრირებულია",
    "Yes": "დიახ",
    "CreateACompany": "შექმენით კომპანია",
    "MyReferrals": "ჩემი მიმართვები",
    "Edit": "შეცვლა",
    "NewEmail": "ახალი ფოსტა",
    "Proceed": "განაგრძეთ",
    "IncorrectCaptcha": "არასწორი captcha",
    "CaptchaIsNotValid": "Captcha აღარ მოქმედებს",
    "EnterCaptcha": "შეიყვანეთ captcha",
    "EnterSMS": "შეიყვანეთ SMS",
    "SMSCodeIsNoLongerValid": "SMS კოდი აღარ არის მოქმედი",
    "SMSCodeIsIncorrect": "SMS კოდი არასწორია",
    "MailNotVerifiedLinkTimedOut": "ფოსტა არ არის დადასტურებული, ბმული ამოიწურა",
    "ReferralLink": "რეფერალური ბმული",
    "Date": "თარიღი",
    "EarnWithUs": "იშოვე ჩვენთან ერთად",
    "RecommendSkyservicePOSToYourFriendsAndGetRealMoneyToYourAccount.": "ურჩიეთ Skyservice POS თქვენს მეგობრებს და მიიღეთ რეალური ფული თქვენს ანგარიშზე.",
    "HowItWorks": "Როგორ მუშაობს?",
    "ItsVerySimpleYouBringCustomersToSkyservicePOSAndWeShareTheirPaymentsWithYou.": "ეს ძალიან მარტივია: თქვენ მიჰყავთ მომხმარებლები Skyservice POS-ში და ჩვენ გაგიზიარებთ მათ გადახდებს.",
    "Copied": "კოპირებულია",
    "TheMinimumWithdrawalAmountIs100USD": "*გატანის მინიმალური თანხა 100 აშშ დოლარი",
    "AddEmail": "ფოსტის დამატება",
    "YouDontHaveCompanyYet": "თქვენ ჯერ არ გაქვთ კომპანია",
    "NoCompanyText": "სალარო აპარატთან მუშაობის დასაწყებად, თქვენ უნდა შექმნათ კომპანია ან მიიღოთ მოწვევა დამსაქმებლისგან.",
    "ConfirmEmail": "ელ.წერილი გაიგზავნა ცვლილების დასადასტურებლად.",
    "description": "აღწერა",
    "NoDescriptionSpecified": "აღწერის გარეშე",
    "DateCreate": "შექმნის თარიღი:",
    "DelComp": "კომპანიის წაშლა",
    "Help": "მხარდაჭერა",
    "Email": "ფოსტა",
    "Phone": "ტელეფონი",
    "SureAccDel": "დარწმუნებული ხართ, რომ გსურთ თქვენი ანგარიშის მიბმის გაუქმება?",
    "no": "არა",
    "yes": "დიახ",
    "confirm": "დაადასტურეთ",
    "cancel": "გაუქმება",
    "Search": "ძიება",
    "dateDelete": "წაშლის თარიღი:",
    "active": "აქტიური",
    "delete": "ამოღებულია",
    "services": "სერვისები",
    "status": "სტატუსი",
    "recover": "აღადგინეთ",
    "restored": "აღადგინა",
    "restore": "აღდგენა",
    "DoYouWantRestore": "გსურთ კამპანიის აღდგენა",
    "deletedCompamies": "დისტანციური კომპანიები",
    "CompanyNotSearch": "ვერცერთი კომპანია ვერ მოიძებნა, რომელიც შეესაბამება თქვენს კრიტერიუმებს",
    "writeCorrectlyName": "სწორად შეიყვანეთ მომხმარებლის სახელი",
    "addlogo": "ლოგოს დამატება",
    "Logo": "ლოგო",
    "nipmg": "დააწკაპუნეთ ან გადაიტანეთ ფაილი ამ ფანჯარაში",
    "ErrorDownload": "ჩატვირთვის შეცდომა",
    "preview": "გადახედვა",
    "onlyimg": "მხოლოდ სურათები",
    "imgisbig": "სურათი აღემატება ზომის ლიმიტს",
    "minimgsize": "სურათის მინიმალური ზომა",
    "Zagruzka...": "Ჩატვირთვა...",
    "Youbrowserisold": "თქვენი ბრაუზერი არ არის მხარდაჭერილი, გთხოვთ, გამოიყენოთ IE10+ ან სხვა ბრაუზერები",
    "failZagruzhen": "ფაილი წარმატებით აიტვირთა!",
    "zakrit": "დახურვა",
    "nazad": "უკან",
    "save": "Გადარჩენა",
    "zagruzit_foto": "Ატვირთეთ ფოტო",
    "clear": "წმინდა",
    "logoIsAdd": "ლოგო დამატებულია",
    "logoIsDel": "ლოგო გასუფთავდა",
    "editComp": "კომპანიის რედაქტირება",
    "giveComp": "გადაცემის კომპანია",
    "thisUserNorRegistered": "ეს მომხმარებელი არ არის რეგისტრირებული",
    "cantTransferToYou": "თქვენ არ შეგიძლიათ თქვენი კომპანიის საკუთარ თავზე გადაცემა",
    "youNotOwner": "თქვენ არ ხართ კომპანიის მფლობელი",
    "Ok": "კარგი",
    "notYetCreateComp": "ამ დროისთვის ახალი კომპანიების შექმნა მიუწვდომელია",
    "EnterToCompany": "შედით კომპანიაში",
    "startDescription": "კეთილი იყოს თქვენი მობრძანება თქვენს პირად ანგარიშზე, დასაწყებად შედით კომპანიაში",
    "inviteComp": "მოწვევა კომპანიაში",
    "visitDemo": "ეწვიეთ დემო კომპანიას",
    "makeUrChoice": "Გააკეთე არჩევანი",
    "ImWorker": "თანამშრომელი ვარ",
    "ImOwner": "ვარ დამსაქმებელი, დამსაქმებლის წარმომადგენელი",
    "needInviteComp": "თქვენ უნდა დაეთანხმოთ მოწვევას არსებულ კომპანიაში",
    "needCreateComp": "თქვენ უნდა შექმნათ კომპანია, რომ დაიწყოთ",
    "daysLeft": "Დღე დარჩა",
    "cannotDel": "დეინსტალაცია ამჟამად მიუწვდომელია",
    "NoCompanyCreateText": "სალარო აპარატთან მუშაობის დასაწყებად უნდა მიიღოთ დამსაქმებლის მოწვევა.",
    "withoutName": "Სახელის გარეშე",
    "cropImageHint": "დააწკაპუნეთ ან გადაიტანეთ ფაილი ასატვირთად",
    "cropImageNoSupported": "ბრაუზერი არ არის მხარდაჭერილი, გთხოვთ, გამოიყენოთ IE10+ ან სხვა ბრაუზერები.",
    "cropImageSuccess": "ჩამოტვირთვა წარმატებით დასრულდა",
    "cropImageFail": "ჩამოტვირთვა ვერ მოხერხდა",
    "cropImageOnlyImgNeed": "მხოლოდ სურათები",
    "cropImageOutOfSize": "სურათის ზომა აღემატება ლიმიტს",
    "cropImageLowestPx": "სურათის მინიმალური ზომა",
    "uReachedLimitComp": "თქვენ მიაღწიეთ შექმნილი კომპანიების რაოდენობის ლიმიტს",
    "accAlreadyRegister": "ეს ანგარიში უკვე დაკავშირებულია სხვა ანგარიშთან",
    "accNotRegister": "ასეთი ანგარიში არ არსებობს",
    "areYourCountry": "ეს შენი ქვეყანაა?",
    "enterCorrectNumber": "შეიყვანეთ ნომერი საერთაშორისო ფორმატში",
    "attach": "შებოჭვა",
    "UserEmailAlreadyExists": "მომხმარებელი ამ ელფოსტით უკვე არსებობს",
    "AccountAlreadyLinked": "ანგარიში უკვე დაკავშირებულია",
    "facebookAccIsAlreadyRegistered": "ეს Facebook ანგარიში უკვე დაკავშირებულია Skyservice-ის სხვა ანგარიშთან",
    "googleAccIsAlreadyRegistered": "ეს Google ანგარიში უკვე დაკავშირებულია Skyservice-ის სხვა ანგარიშთან",
    "telegramAccAlreadyRegister": "ეს Telegram უკვე დაკავშირებულია Skyservice-ის სხვა ანგარიშთან",
    "companyBlocked": "ეს კომპანია არააქტიურია",
    "allSessions": "ყველა სესიის ჩვენება",
    "activeSessions": "აქტიური სესიები",
    "exitAllDevices": "გადით ყველა სხვა მოწყობილობაზე",
    "currentSession": "მიმდინარე სესია",
    "autoCloseSessions": "სესიის ავტომატური დასრულება",
    "nonActivePeriod": "ავტომატურად დასრულდება, თუ სესია არ არის აქტიური",
    "oneWeek": "1 კვირის",
    "oneMonth": "1 თვე",
    "threeMonth": "3 თვე",
    "sixMonth": "6 თვე",
    "terminate": "სრული",
    "sessionDesc1": "ყველა მოწყობილობა, რომელიც შესულია თქვენს Skyservice ანგარიშში, აქ არის ჩამოთვლილი. თქვენ შეგიძლიათ გამოხვიდეთ თქვენი ანგარიშიდან თითოეულ მათგანზე ინდივიდუალურად ან ერთდროულად.",
    "sessionDesc2": "თუ სიაში იპოვით უცნობ მოწყობილობას, დაუყოვნებლივ გამოდით ამ მოწყობილობიდან და შეცვალეთ პაროლი თქვენი Skyservice ანგარიშისთვის.",
    "sureToTerminate": "დარწმუნებული ხართ, რომ გსურთ ყველა აქტიური სესიის დასრულება?",
    "ip": "IP მისამართი",
    "geoPos": "გეოპოზიცია",
    "info": "ინფორმაცია",
    "sessionDesc3": "სესიები, რომლებსაც არ აქვთ რაიმე აქტივობა ამ პერიოდში, ავტომატურად დასრულდება.",
    "terminatesSessions": "სესიების დასრულება",
    "more3monthAgo": "სამ თვეზე მეტი ხნის წინ",
    "noData": "Მონაცემები არ არის",
    "goSettingsCompany": "ჩვენ ვქმნით თქვენს კომპანიას, მოგვეცით ერთი წუთი...",
    "preparingCompany1": "ჩვენ ვქმნით თქვენს კომპანიას, გთხოვთ დაგვიტოვოთ ერთი წუთი...",
    "preparingCompany2": "ჩვენ ვამზადებთ ინტერფეისს საქონლისა და სერვისების მართვისთვის...",
    "preparingCompany3": "ჩვენ ვმუშაობთ მოხერხებულ ფინანსურ აღრიცხვაზე...",
    "preparingCompany4": "ჩვენ ვაკეთებთ საბოლოო პარამეტრებს, თითქმის მზად...",
    "appleAccIsAlreadyRegistered": "ეს AppleId უკვე დაკავშირებულია სხვა Skyservice ანგარიშთან",
    "notGetDataFromApple": "Apple-ისგან მონაცემების მიღება ვერ მოხერხდა"
}

export default function() {
    return lang;
}