<template>
    <smallModal @escape="$emit('close')">
        <div class="leave-container">
            <h5>{{ lang['DelComp'] }}: {{ companyData.company_name }}?</h5>
            <hr>
            <div v-if="ccc != null">
                <div class="buttons-flex-end">
                    <button @click="captchaImg" class="btn">
                        <b-icon-arrow-repeat id="captcha-leave"></b-icon-arrow-repeat>
                    </button>
                </div>
                <div class="imgDiv">
                    <img :src="ccc" class="capImg">
                </div>
                <label :for="captchaName">{{ lang['EnterCaptcha'] }}</label>
                <b-form-input v-model="cap.captcha" :state="capState" :name="captchaName" autofocus />
            </div>

            <label for="accountPassword">{{ lang['Password'] }}</label>
            <b-form-input v-model="accountPassword" type="password" :state="passState" name="accountPassword"  />
            <hr>
            <div class="buttons">
                <button class="btn btn-secondary" @click="$emit('close')">{{ lang['Сancel'] }}</button>
                <button class="btn btn-success longWord" @click="save">{{ lang['Confirm'] }}</button>
            </div>
        </div>
    </smallModal>
</template>

<script>
import { store } from '@/store.js';
import { keyevent } from '@/main.js';
import { rotate } from '../js/rotateBtn';
import { guid } from '../js/guid.js';
import { errList } from '../js/CaptchaAndPassError';
import smallModal from '../modals/smallModal';
import { regAPi } from '@/API.js';

const apiList = new regAPi()
export default {
    props: ['companyData'],
    components: {
        smallModal
    },
    data() {
        return {
            cap: {
                captcha: '',
                captcha_guid: ''
            },
            accountPassword: '',
            ccc: null,
            captchaName:'captcha_'+this.getRandNum(999,99999)
        }
    },
    created() {
        this.captchaImg();
    },
    mounted() {
        keyevent.$off('enter');
        keyevent.$on('enter', () =>
            this.save()
        );
    },
    methods: {
        getRandNum(min, max) {
            return Math.random() * (max - min) + min;
        },
        captchaImg() {
            try {
                // создаем гуид который (файл guid.js) скидываем для капичи состарта, потом, если юзер нажал сохранить, то скидываем текущий гуид на сервак для сравнения
                const a = guid();
                const c = apiList.captchaUID(a);
                this.ccc = c;
                this.cap.captcha_guid = a;
                // крутилка для кнопки
                rotate('#captcha-leave');
            } catch(err) {
                console.log(err)
            }
        },
        save() {
            try {
                // передаем токен, пароль и ид компании
                if (this.passState && this.capState) {
                    apiList.deleteCompany({ token: store.token, password: this.accountPassword, company_id: this.companyData.company_id, ...this.cap }).then(resp => {
                        if (resp.data.status == 'done') {
                            window.say.message(this.lang['Saved']);
                            this.$emit('close');
                        } else {
                            errList(resp.data.error[0]);
                        }
                    })
                } else {
                    window.say.messageError(this.lang['FillInAllTheFields'])
                }
            } catch(err) {
                window.say.messageError(this.lang['Error']);
            }
        }
    },
    computed: {
        lang() {
            return window.lang;
        },
        passState() {
            return this.accountPassword.length >= 4 ? true : false;
        },
        capState() {
            return this.cap.captcha.length == 4 ? true : false;
        }
    }
}
</script>

<style scoped>
    .btn-secondary, .btn-success{
        width: 49%;
    }
    .buttons{
        display: flex;
        justify-content: space-between;
    }
    .imgDiv{
        text-align: center; 
        height: 100px;
    }
    .capImg{
        height: 100px;
    }
    .buttons-flex-end{
        display: flex; 
        justify-content: flex-end;
    }
    #captcha-leave{
        height: 2rem; 
        width: 2rem;
    }
    .longWord {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>