const lang = {
    "ToAccept": "Accettare",
    "Reject": "Rifiutare",
    "Password": "Parola d'ordine",
    "RepeatPassword": "ripeti la password",
    "OldPassword": "vecchia password",
    "MyCompany": "Le mie aziende",
    "Company": "Azienda",
    "CreateNewCompany": "Crea la tua azienda",
    "Сancel": "Annulla",
    "Save": "Salva",
    "GeneralInformation": "Informazione Generale",
    "CompanyName": "Nome della ditta",
    "LogOut": "Disconnettersi",
    "SignOutOfAccount": "disconnessione",
    "ChangePassword": "Cambia la password",
    "AddPassword": "Aggiungi password",
    "Language": "Lingua",
    "ConfirmThisMail": "la tua email è stata confermata",
    "Invites": "inviti",
    "AcceptInvitation": "Accetta l'invito",
    "EMailOrPhone": "Telefono o posta",
    "Saved": "Salvato",
    "Error": "Errore",
    "FillInAllTheFields": "Compila tutti i campi",
    "FirstCreateAnAccountPassword": "Innanzitutto, crea una password per l'account",
    "Settings": "Impostazioni",
    "Next": "Ulteriore",
    "MinPassLength": "La password è troppo corta",
    "action": "Azione",
    "NewOwner": "Nuovo proprietario",
    "UserMustBeRegistered": "L'utente deve essere registrato",
    "LeaveTheCompany": "Lascia la compagnia",
    "Confirm": "Confermare",
    "WrongFormat": "Formato errato",
    "DeleteAccount": "Eliminare l'account",
    "WrongPassword": "Password errata",
    "PhoneAlreadyRegistered": "Telefono già registrato",
    "EmailAlreadyRegistered": "Posta già registrata",
    "Yes": "sì",
    "CreateACompany": "Crea un'azienda",
    "MyReferrals": "I miei riferimenti",
    "Edit": "Modificare",
    "NewEmail": "Nuova posta",
    "Proceed": "Continua",
    "IncorrectCaptcha": "Captcha errato",
    "CaptchaIsNotValid": "Captcha non è più valido",
    "EnterCaptcha": "Inserisci captcha",
    "EnterSMS": "Inserisci SMS",
    "SMSCodeIsNoLongerValid": "Il codice SMS non è più valido",
    "SMSCodeIsIncorrect": "Il codice SMS non è corretto",
    "MailNotVerifiedLinkTimedOut": "Mail non confermata, link scaduto",
    "ReferralLink": "Link di riferimento",
    "Date": "data di",
    "EarnWithUs": "Guadagna con noi",
    "RecommendSkyservicePOSToYourFriendsAndGetRealMoneyToYourAccount.": "Consiglia Skyservice POS ai tuoi amici e ottieni soldi veri sul tuo conto.",
    "HowItWorks": "Come funziona?",
    "ItsVerySimpleYouBringCustomersToSkyservicePOSAndWeShareTheirPaymentsWithYou.": "È molto semplice: tu porti i clienti al POS Skyservice e noi condividiamo i loro pagamenti con te.",
    "Copied": "Copiato",
    "TheMinimumWithdrawalAmountIs100USD": "*Importo minimo di prelievo 100 USD",
    "AddEmail": "Aggiungi posta",
    "YouDontHaveCompanyYet": "Non hai ancora un'azienda",
    "NoCompanyText": "Per iniziare a lavorare con il registratore di cassa, è necessario creare un'azienda o accettare un invito da un datore di lavoro.",
    "ConfirmEmail": "È stata inviata un'e-mail per confermare la modifica.",
    "description": "Descrizione",
    "NoDescriptionSpecified": "Nessuna descrizione",
    "DateCreate": "Data di creazione:",
    "DelComp": "Elimina azienda",
    "Help": "Supporto",
    "Email": "Posta",
    "Phone": "Telefono",
    "SureAccDel": "Sei sicuro di voler scollegare il tuo account?",
    "no": "Non",
    "yes": "sì",
    "confirm": "Confermare",
    "cancel": "Annulla",
    "Search": "Ricerca",
    "dateDelete": "Data di cancellazione:",
    "active": "Attivo",
    "delete": "RIMOSSO",
    "services": "Servizi",
    "status": "Stato",
    "recover": "Ristabilire",
    "restored": "restaurato",
    "restore": "Recupero",
    "DoYouWantRestore": "Vuoi ripristinare la campagna",
    "deletedCompamies": "AZIENDE REMOTE",
    "CompanyNotSearch": "Nessuna azienda trovata corrispondente ai tuoi criteri",
    "writeCorrectlyName": "Inserisci correttamente il nome utente",
    "addlogo": "Aggiungi logo",
    "Logo": "Logo",
    "nipmg": "Fare clic o trascinare un file in questa finestra",
    "ErrorDownload": "errore di caricamento",
    "preview": "anteprima",
    "onlyimg": "Solo immagini",
    "imgisbig": "L'immagine supera il limite di dimensione",
    "minimgsize": "Dimensione minima dell'immagine",
    "Zagruzka...": "Caricamento in corso...",
    "Youbrowserisold": "Il tuo browser non è supportato, utilizza IE10+ o altri browser",
    "failZagruzhen": "Il file è stato caricato con successo!",
    "zakrit": "chiudere",
    "nazad": "Di ritorno",
    "save": "Salva",
    "zagruzit_foto": "Carica una foto",
    "clear": "Chiaro",
    "logoIsAdd": "Logo aggiunto",
    "logoIsDel": "Logo cancellato",
    "editComp": "Modifica società",
    "giveComp": "Società di trasferimento",
    "thisUserNorRegistered": "Questo utente non è registrato",
    "cantTransferToYou": "Non puoi trasferire la tua azienda a te stesso",
    "youNotOwner": "Non sei il proprietario dell'azienda",
    "Ok": "OK",
    "notYetCreateComp": "Al momento, la creazione di nuove società non è disponibile",
    "EnterToCompany": "Accedi all'azienda",
    "startDescription": "Benvenuto nel tuo account personale, accedi all'azienda per iniziare",
    "inviteComp": "Invito alla compagnia",
    "visitDemo": "Visita l'azienda dimostrativa",
    "makeUrChoice": "Fai una scelta",
    "ImWorker": "Sono un dipendente",
    "ImOwner": "Sono un datore di lavoro, rappresentante del datore di lavoro",
    "needInviteComp": "Devi accettare un invito a un'azienda esistente",
    "needCreateComp": "Devi creare un'azienda per iniziare",
    "daysLeft": "Giorni rimasti",
    "cannotDel": "La disinstallazione non è al momento disponibile",
    "NoCompanyCreateText": "Per iniziare a lavorare con il registratore di cassa, devi accettare un invito dal datore di lavoro.",
    "withoutName": "Senza nome",
    "cropImageHint": "Fare clic o trascinare un file da caricare",
    "cropImageNoSupported": "Browser non supportato, utilizzare IE10+ o altri browser.",
    "cropImageSuccess": "Download riuscito",
    "cropImageFail": "Scaricamento fallito",
    "cropImageOnlyImgNeed": "Solo immagini",
    "cropImageOutOfSize": "La dimensione dell'immagine supera il limite",
    "cropImageLowestPx": "Dimensione minima dell'immagine",
    "uReachedLimitComp": "Hai raggiunto il limite del numero di aziende create",
    "accAlreadyRegister": "Questo account è già collegato a un altro account",
    "accNotRegister": "Nessun account di questo tipo esiste",
    "areYourCountry": "questo è il tuo paese?",
    "enterCorrectNumber": "Inserisci il numero in formato internazionale",
    "attach": "legamento",
    "UserEmailAlreadyExists": "L'utente con questa email esiste già",
    "AccountAlreadyLinked": "Account già collegato",
    "facebookAccIsAlreadyRegistered": "Questo account Facebook è già collegato a un altro account Skyservice",
    "googleAccIsAlreadyRegistered": "Questo account Google è già collegato a un altro account Skyservice",
    "telegramAccAlreadyRegister": "Questo Telegram è già collegato a un altro account Skyservice",
    "companyBlocked": "Questa azienda è inattiva",
    "allSessions": "Mostra tutte le sessioni",
    "activeSessions": "Sessioni Attive",
    "exitAllDevices": "Esci su tutti gli altri dispositivi",
    "currentSession": "Sessione corrente",
    "autoCloseSessions": "Completamento automatico della sessione",
    "nonActivePeriod": "Termina automaticamente se la sessione non è attiva",
    "oneWeek": "1 settimana",
    "oneMonth": "1 mese",
    "threeMonth": "3 mesi",
    "sixMonth": "6 mesi",
    "terminate": "Completare",
    "sessionDesc1": "Tutti i dispositivi che hanno effettuato l'accesso al tuo account Skyservice sono elencati qui. Puoi uscire dal tuo account su ciascuno di essi singolarmente o su tutti in una volta.",
    "sessionDesc2": "Se trovi un dispositivo sconosciuto nell'elenco, disconnettiti immediatamente su questo dispositivo e modifica la password per il tuo account Skyservice.",
    "sureToTerminate": "Sei sicuro di voler terminare tutte le sessioni attive?",
    "ip": "indirizzo IP",
    "geoPos": "Geoposizione",
    "info": "Informazione",
    "sessionDesc3": "Le sessioni che non hanno alcuna attività durante questo periodo termineranno automaticamente.",
    "terminatesSessions": "Sessioni finali",
    "more3monthAgo": "Più di tre mesi fa",
    "noData": "Nessun dato",
    "goSettingsCompany": "Stiamo creando la tua azienda, dacci un minuto...",
    "preparingCompany1": "Stiamo creando la tua azienda, ti preghiamo di darci un minuto...",
    "preparingCompany2": "Stiamo predisponendo un'interfaccia per la gestione di beni e servizi...",
    "preparingCompany3": "Stiamo lavorando su una contabilità finanziaria conveniente...",
    "preparingCompany4": "Stiamo effettuando le impostazioni finali, quasi pronto...",
    "appleAccIsAlreadyRegistered": "Questo AppleId è già collegato a un altro account Skyservice",
    "notGetDataFromApple": "Impossibile ricevere dati da Apple"
}

export default function() {
    return lang;
}