<template>
    <smallModal>
        <div>
            <div v-if="ccc != null">
                <div class="buttons-flex-end">
                    <button @click="captchaImg" class="btn">
                        <b-icon-arrow-repeat id="captcha-phone"></b-icon-arrow-repeat>
                    </button>
                </div>
                <div class="imgDiv" style="text-align: center;">
                    <img :src="ccc" class="capImg">
                </div>
                <label :for="captchaName">{{ lang['EnterCaptcha'] }}</label>
                <b-form-input v-model="form.captcha" :state="capState" :name="captchaName" />
            </div>

            <label for="password">{{ lang['Password'] }}</label>
            <b-form-input v-model="form.account_password" type="password" :state="stPass" />
            <hr>
            <div class="buttons">
                <button class="btn btn-secondary" @click="$emit('close')">{{ lang['Сancel'] }}</button>
                <button class="btn btn-success" @click="save">{{ lang['Save'] }}</button>
            </div>
        </div>
    </smallModal>
</template>

<script>
import { store } from '@/store.js';
import { keyevent } from '@/main.js';
import { errList } from '../js/CaptchaAndPassError';
import { rotate } from '../js/rotateBtn';
import { regAPi } from '@/API.js';
import { guid } from '../js/guid'
const apiList = new regAPi();
import smallModal from '../modals/smallModal'

export default {
    props: ['newphone'],
    components: {
        smallModal
    },
    data() {
        return {
            form: {
                account_password: '',
                captcha: ''
            },
            ccc: null,
            guidNow: null,
            captchaName:'captcha_'+this.getRandNum(999,99999)
        }
    },
    created() {
        this.captchaImg();
    },
    mounted(){
        keyevent.$off('enter');
        keyevent.$on('enter', () => this.save())
    },
    methods: {
        getRandNum(min, max) {
            return Math.random() * (max - min) + min;
        },
        captchaImg() {
            try {
                // создаем гуид (файл guid.js) который скидываем для капичи состарта, потом, если юзер нажал сохранить, то скидываем текущий гуид на сервак для сравнения
                const a = guid();
                const c = apiList.captchaUID(a);
                this.ccc = c;
                this.guidNow = a;
                // крутилка для кнопки
                rotate('#captcha-phone');
            } catch(err) {
                console.log(err)
            }
        },
        save() {
            try {
                if (this.stPass && this.capState) {
                    apiList.editPhone({ 
                        ...this.form, 
                        token: store.token, 
                        new_phone: this.newphone.new_phone, 
                        captcha_guid: this.guidNow 
                    }).then(resp => {
                        if (resp.data.status == 'done') {
                            this.$emit('close');
                            this.$emit('goSms', this.newphone.new_phone)
                        } else if(resp.data.error[0] === "Phone is already registered"){
                            window.say.messageError(this.lang['PhoneAlreadyRegistered'])
                        } else {
                            errList(resp.data.error[0])
                        }    
                    })
                } else {
                    window.say.messageError(this.lang['FillInAllTheFields'])
                }
            } catch(err) {
                window.say.messageError(this.lang['Error'])
            }
        }
    },
    computed: {
        lang() {
            return window.lang
        },
        stPass() {
            return this.form.account_password.length >= 4 ? true : false;
        },
        capState() {
            return this.form.captcha.length == 4 ? true : false;
        }
    }
}
</script>

<style scoped>
.buttons{
  display: flex;
  justify-content: space-between;
}
.btn-secondary, .btn-success{
  width: 49%;
}

#captcha-phone{
  height: 2rem; 
  width: 2rem;
}

.capImg{
  height: 100px;
}
.buttons-flex-end{
  display: flex; 
  justify-content: flex-end;
}

.imgDiv{
  text-align: center; /*div не видит почему-то этот стиль*/
  height: 100px;
}
</style>