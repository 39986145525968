<template>
    <smallModal @escape="$emit('close')">
        <div>
            <div v-if="startModal">
                <h5>{{ lang['Edit'] }}: {{ personPhone }}?</h5>
                <hr>
                <div>
                    <div v-if="personPassword">
                        <label for="phone_input_edit">{{ lang['Phone'] }}</label>
                        <b-form-input
                            type="text"
                            v-model="form.phone"
                            name="login"
                            id="phone_input_edit"
                            :state="phoneState"
                        />
                    </div>
                    <div v-else class="center-text">
                        <span class="grey-span">{{ lang['FirstCreateAnAccountPassword'] }}</span>
                    </div>
                </div>
                <hr>
                <div class="buttons">
                    <button class="btn btn-secondary" @click="$emit('close')">{{ lang['Сancel'] }}</button>
                    <button v-if="personPassword" class="btn btn-success" @click="validPhoneFormat">{{ lang['Proceed'] }}</button>
                    <button v-else class="btn btn-success" @click="goAddPass">{{ lang['Proceed'] }}</button>
                </div>
            </div>
            <div v-if="countryModal">
                <div class="confirm-country">
                    <span>{{ this.country + " - " + lang['areYourCountry'] }}</span>
                </div>
                <hr>
                <div class="buttons">
                    <button class="btn btn-secondary" @click="no">{{ lang['no'] }}</button>
                    <button class="btn btn-success" @click="sendPhone">{{ lang['Yes'] }}</button>
                </div>
            </div>
        </div>
    </smallModal>
</template>

<script>
import { keyevent } from '@/main.js';
import smallModal from '../modals/smallModal';
import { regAPi } from '@/API.js';
//import { store } from '@/store.js';
const apiList = new regAPi();
export default {
    props: ['personPhone', 'personPassword'],
    components: {
        smallModal,
    },
    data() {
        return {
            form: {
                phone: ''
            },
            startModal: true,
            countryModal: false,
            country: null,
        }
    },
    mounted(){
        this.$nextTick(() => {
            try {
                let input = document.getElementById('phone_input_edit');
                input.focus();
            } catch (err) {
                // console.log(err)
            }
        })
        keyevent.$off('enter');
        keyevent.$on('enter', () => {
            if(this.startModal && !this.countryModal){
                this.personPassword ? this.validPhoneFormat() : this.goAddPass()
            } else if (!this.startModal && this.countryModal) {
                this.sendPhone()
            }
        })
    },
    methods: {
        no(){
            this.countryModal = false;
            this.startModal = true;
            this.form.phone = '';
            window.say.messageError(this.lang['enterCorrectNumber']);
        },
        // По не известному мнне стечению обстоятельств, мы для изменения номера телефона используем ту же ссылку, что и при добавлении телефона)
        validPhoneFormat() {

            try{
                //Костыльчик для Украины что бы не вводить +38
                if(this.form.phone["0"] == "0"){
                    this.form.phone = "38"+this.form.phone
                }
            }catch(err){
                console.error(err)
            }

            if (this.phoneState) {
                apiList.checkPhone({ phone: this.form.phone}).then(resp =>{
                    if(resp.data.status == "done"){
                        if(resp.data.data.viewModal){
                            this.country = resp.data.data.country.englishname;
                            this.startModal = false;
                            this.countryModal = true;
                        } else if(!resp.data.data.viewModal && resp.data.data.login == 'not found'){
                            this.$emit('close');
                            this.$emit('gopassword', { new_phone: this.form.phone })
                        } else {
                            window.say.messageError(this.lang['PhoneAlreadyRegistered']);
                        }
                    }else if(resp.data.status == "error"){
                        this.$emit('close');
                        if(Array.isArray(resp.data.error)){
                            for(let key in resp.data.error){
                                window.say.messageError(resp.data.error[key]);
                            }
                        }else if(typeof resp.data.error == 'string'){
                            window.say.messageError(resp.data.error);
                        }
                    }else{
                        this.$emit('close');
                        window.say.messageError("unknown error:0177");
                    }
                })
            } else {
                window.say.messageError(this.lang['WrongFormat'])
            }
        },
        sendPhone(){
            this.$emit('close');
            this.$emit('gopassword', { new_phone: this.form.phone })
        },  
        // если юзер захотел поменять телефон, но он не додумался ранее создать пароль себе
        goAddPass() {
            this.$emit('close');
            this.$emit('goaddpass')
        }
    },
    computed: {
        phoneState(){
            if(this.form.phone && this.form.phone.length >= 4 && !isNaN(this.form.phone)) {
                return true;
            } else {
                return false;
            }
        },
        lang() {
            return window.lang;
        },
    }
}
</script>

<style scoped>
.btn-secondary, .btn-success{
  width: 49%;
}
.buttons{
  display: flex;
  justify-content: space-between;
}
.center-text{
    text-align: center;
}
.grey-span{
    color: grey;
}
/*не знаю почему но если этого нету то кнопка обновления капчи меняет разположение на левую сторону (смена телефона)*/
.buttons-flex-end{
  display: flex; 
  justify-content: flex-end;
}
</style>