<template>
    <div class="select-block">
        <b-form-select
        v-model="currentLang"
        :options="language"
        class="mb-3"
        value-field="value"
        text-field="description"
        @change="editLang()"
        ></b-form-select>
    </div>
</template>

<script>

export default {
    props: ['personLang'],
    components: {
       
    },
    data() {
        return {
           currentLang: null,
           language: [
                {
                    value: "uk",
                    text: "UK",
                    description:"Українська"
                },
                {
                    value: "en",
                    text: "EN",
                    description:"English"
                }, 
                {
                    value: "tg",
                    text: "TG",
                    description:"Забони тоҷикӣ"
                }, 
                {
                    value: "tr",
                    text: "TR",
                    description:"Türkçe"
                },
                { 
                    value: "ar", 
                    text: "AR",
                    description:"العربية"
                },
                { 
                    value: "cn", 
                    text: "CN",
                    description:"简体中文"
                },
                { 
                    value: "de", 
                    text: "DE",
                    description:"Deutsch"
                },
                {
                    value: "el",
                    text: "EL",
                    description:"Greek"
                },
                {
                    value: "es",
                    text: "ES",
                    description:"Español"
                }, 
                {
                    value: "et",
                    text: "ET",
                    description:"Eesti keel"
                }, 
                {
                    value: "fi",
                    text: "FI",
                    description:"Suomi"
                }, 
                {
                    value: "fr",
                    text: "FR",
                    description:"Français"
                }, 
                {
                    value: "he",
                    text: "HE",
                    description:"עִבְרִית"
                }, 
                {
                    value: "it",
                    text: "IT",
                    description:"Italiano"
                }, 
                {
                    value: "ka",
                    text: "KA",
                    description:"Georgian"
                }, 
                {
                    value: "km",
                    text: "KM",
                    description:"Khmer"
                }, 
                {
                    value: "lt",
                    text: "LT",
                    description:"Lietuvių kalba"
                }, 
                {
                    value: "lv",
                    text: "LV",
                    description:"Latvian"
                }, {
                    value: "pl",
                    text: "PL",
                    description:"Polski"
                }, {
                    value: "pt",
                    text: "PT",
                    description:"Português"
                }, {
                    value: "ro",
                    text: "RO",
                    description:"Romanian"
                }, 
                {
                    value: "ru",
                    text: "RU",
                    description:"Русский"
                },
                { 
                    value: "be", 
                    text: "BE",
                    description:"Беларуская мова"
                }

            ],
        }
    },
    mounted() {
        this.currentLang = this.personLang
    },
    methods: {
        editLang() {
            this.$emit('changeLang', this.currentLang)
        }
    },
}
</script>

<style scoped>

</style>