<template>
    <smallModal>
        <div>
            <h6>{{ lang['makeUrChoice'] + ':' }}</h6>
            <hr>
        </div>
        <div class="radio-div">
            <b-form-group>
                <b-form-radio v-model="role" class="radio1" value="worker">{{ lang['ImWorker'] }}</b-form-radio>
                <div class="description"><span>{{ lang['needInviteComp'] }}</span></div>
                <b-form-radio v-model="role" class="radio2" value="owner">{{ lang['ImOwner'] }}</b-form-radio>
                <div v-if="role == null || role == 'worker'" class="description"><span>{{ lang['needCreateComp'] }}</span></div>
                <b-form-checkbox v-if="role == 'owner'" v-model="createCompany" class="createCompanyCheckbox" size="sm">{{ lang['needCreateComp'] }}</b-form-checkbox>
            </b-form-group>
        </div>
        <div>
            <button class="btn btn-success" @click="$emit('selectRole', { accountType: role, createCompany:createCompany })" :disabled="role == null">{{ lang['Confirm'] }}</button>
        </div>
    </smallModal>
</template>
<script>

import { keyevent } from '@/main.js';
import smallModal from '../modals/smallModal';
export default {
    components: {
        smallModal
    },
    data() {
        return {
            createCompany: true,
            role: null,
        }
    },
    methods: {
    },
    mounted(){
        keyevent.$off('enter');
        keyevent.$on('enter', () =>
            this.role ? this.$emit('selectRole', { accountType: this.role, createCompany: this.createCompany }) : ''
        );
    },
    computed: {
        lang() {
            return window.lang;
        },
    }
}
</script>
<style scoped>
.btn-success{
    width: 100%;
}
.description{
    font-size: small;
    padding-left: 25px;
    color: grey;
}    

.createCompanyCheckbox{
    margin-left: 18px;
    margin-top: 10px;
    color: grey;
}
.radio1{
    font-weight: bold;
}
.radio2{
    margin-top: 2rem;
    font-weight: bold;
}
h6{
    font-weight: 600;
}
</style>