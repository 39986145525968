const lang = {
    "ToAccept": "Aktsepteerima",
    "Reject": "Keeldu",
    "Password": "Parool",
    "RepeatPassword": "Korda salasõna",
    "OldPassword": "vana parool",
    "MyCompany": "Minu ettevõtted",
    "Company": "Ettevõte",
    "CreateNewCompany": "Looge oma ettevõte",
    "Сancel": "Tühista",
    "Save": "Salvesta",
    "GeneralInformation": "Üldine informatsioon",
    "CompanyName": "Ettevõtte nimi",
    "LogOut": "Logi välja",
    "SignOutOfAccount": "Logi välja",
    "ChangePassword": "Muuda salasõna",
    "AddPassword": "Lisa parool",
    "Language": "Keel",
    "ConfirmThisMail": "Teie e-post on kinnitatud",
    "Invites": "kutsed",
    "AcceptInvitation": "Võta kutse vastu",
    "EMailOrPhone": "Telefon või post",
    "Saved": "Salvestatud",
    "Error": "Viga",
    "FillInAllTheFields": "Täitke kõik väljad",
    "FirstCreateAnAccountPassword": "Esiteks looge konto parool",
    "Settings": "Seaded",
    "Next": "Edasi",
    "MinPassLength": "Parool on liiga lühike",
    "action": "Tegevus",
    "NewOwner": "Uus omanik",
    "UserMustBeRegistered": "Kasutaja peab olema registreeritud",
    "LeaveTheCompany": "Lahkuge ettevõttest",
    "Confirm": "Kinnita",
    "WrongFormat": "Vale formaat",
    "DeleteAccount": "Kustuta konto",
    "WrongPassword": "Vale parool",
    "PhoneAlreadyRegistered": "Telefon juba registreeritud",
    "EmailAlreadyRegistered": "Post juba registreeritud",
    "Yes": "Jah",
    "CreateACompany": "Looge ettevõte",
    "MyReferrals": "Minu soovitused",
    "Edit": "Muuda",
    "NewEmail": "Uus kiri",
    "Proceed": "Jätka",
    "IncorrectCaptcha": "vale captcha",
    "CaptchaIsNotValid": "Captcha ei kehti enam",
    "EnterCaptcha": "Sisestage captcha",
    "EnterSMS": "Sisestage SMS",
    "SMSCodeIsNoLongerValid": "SMS-kood ei kehti enam",
    "SMSCodeIsIncorrect": "SMS-kood on vale",
    "MailNotVerifiedLinkTimedOut": "Post pole kinnitatud, link aegunud",
    "ReferralLink": "Viitamise link",
    "Date": "kuupäev",
    "EarnWithUs": "Teenige meiega",
    "RecommendSkyservicePOSToYourFriendsAndGetRealMoneyToYourAccount.": "Soovitage oma sõpradele Skyservice POS-i ja hankige oma kontole pärisraha.",
    "HowItWorks": "Kuidas see töötab?",
    "ItsVerySimpleYouBringCustomersToSkyservicePOSAndWeShareTheirPaymentsWithYou.": "See on väga lihtne: toote kliendid Skyservice POS-i ja meie jagame teiega nende makseid.",
    "Copied": "Kopeeritud",
    "TheMinimumWithdrawalAmountIs100USD": "*Minimaalne väljamakse summa 100 USD",
    "AddEmail": "Lisage kiri",
    "YouDontHaveCompanyYet": "Teil pole veel ettevõtet",
    "NoCompanyText": "Kassaaparaadiga töö alustamiseks tuleb luua ettevõte või võtta vastu tööandja kutse.",
    "ConfirmEmail": "Muudatuse kinnitamiseks on saadetud e-kiri.",
    "description": "Kirjeldus",
    "NoDescriptionSpecified": "Kirjeldust pole",
    "DateCreate": "Loomise kuupäev:",
    "DelComp": "Kustuta ettevõte",
    "Help": "Toetus",
    "Email": "Mail",
    "Phone": "Telefon",
    "SureAccDel": "Kas olete kindel, et soovite oma konto linkimise tühistada?",
    "no": "Mitte",
    "yes": "Jah",
    "confirm": "Kinnita",
    "cancel": "Tühista",
    "Search": "Otsing",
    "dateDelete": "Kustutamise kuupäev:",
    "active": "Aktiivne",
    "delete": "Eemaldatud",
    "services": "Teenused",
    "status": "Olek",
    "recover": "Taastada",
    "restored": "taastatud",
    "restore": "Taastumine",
    "DoYouWantRestore": "Kas soovite kampaania taastada?",
    "deletedCompamies": "KAUGETTEVÕTTED",
    "CompanyNotSearch": "Teie kriteeriumidele vastavaid ettevõtteid ei leitud",
    "writeCorrectlyName": "Sisesta kasutajanimi õigesti",
    "addlogo": "Lisa logo",
    "Logo": "Logo",
    "nipmg": "Klõpsake või lohistage faili sellesse aknasse",
    "ErrorDownload": "laadimisviga",
    "preview": "eelvaade",
    "onlyimg": "Ainult pildid",
    "imgisbig": "Pilt ületab suurusepiirangu",
    "minimgsize": "Minimaalne pildi suurus",
    "Zagruzka...": "Laadimine...",
    "Youbrowserisold": "Teie brauserit ei toetata, kasutage IE10+ või muid brausereid",
    "failZagruzhen": "Fail on edukalt üles laaditud!",
    "zakrit": "Sulge",
    "nazad": "tagasi",
    "save": "Salvesta",
    "zagruzit_foto": "Laadi Foto üles",
    "clear": "Selge",
    "logoIsAdd": "Logo lisatud",
    "logoIsDel": "Logo kustutatud",
    "editComp": "Muuda ettevõtet",
    "giveComp": "Transfer firma",
    "thisUserNorRegistered": "See kasutaja ei ole registreeritud",
    "cantTransferToYou": "Te ei saa oma ettevõtet endale üle anda",
    "youNotOwner": "Te ei ole ettevõtte omanik",
    "Ok": "Okei",
    "notYetCreateComp": "Hetkel uute ettevõtete loomine puudub",
    "EnterToCompany": "Logige ettevõttesse sisse",
    "startDescription": "Tere tulemast oma isiklikule kontole, alustamiseks logige ettevõttesse sisse",
    "inviteComp": "Kutse ettevõttesse",
    "visitDemo": "Külastage demofirmat",
    "makeUrChoice": "Otsust langetama",
    "ImWorker": "Olen töötaja",
    "ImOwner": "Olen tööandja, tööandja esindaja",
    "needInviteComp": "Peate vastu võtma olemasoleva ettevõtte kutse",
    "needCreateComp": "Alustamiseks peate looma ettevõtte",
    "daysLeft": "Päevad jäänud",
    "cannotDel": "Desinstallimine pole praegu saadaval",
    "NoCompanyCreateText": "Kassaaparaadiga töö alustamiseks tuleb vastu võtta tööandja kutse.",
    "withoutName": "Nimetu",
    "cropImageHint": "Klõpsake või lohistage üleslaadimiseks faili",
    "cropImageNoSupported": "Brauserit ei toetata, kasutage IE10+ või muid brausereid.",
    "cropImageSuccess": "Allalaadimine õnnestus",
    "cropImageFail": "Allalaadimine ebaõnnestus",
    "cropImageOnlyImgNeed": "Ainult pildid",
    "cropImageOutOfSize": "Pildi suurus ületab piirangu",
    "cropImageLowestPx": "Minimaalne pildi suurus",
    "uReachedLimitComp": "Olete saavutanud loodud ettevõtete arvu limiidi",
    "accAlreadyRegister": "See konto on juba lingitud teise kontoga",
    "accNotRegister": "Sellist kontot pole olemas",
    "areYourCountry": "kas see on sinu riik?",
    "enterCorrectNumber": "Sisestage number rahvusvahelises vormingus",
    "attach": "siduda",
    "UserEmailAlreadyExists": "Selle e-posti aadressiga kasutaja on juba olemas",
    "AccountAlreadyLinked": "Konto on juba lingitud",
    "facebookAccIsAlreadyRegistered": "See Facebooki konto on juba lingitud teise Skyservice'i kontoga",
    "googleAccIsAlreadyRegistered": "See Google'i konto on juba lingitud teise Skyservice'i kontoga",
    "telegramAccAlreadyRegister": "See Telegram on juba lingitud teise Skyservice'i kontoga",
    "companyBlocked": "See ettevõte on passiivne",
    "allSessions": "Kuva kõik seansid",
    "activeSessions": "Aktiivsed seansid",
    "exitAllDevices": "Logige välja kõigist teistest seadmetest",
    "currentSession": "Praegune seanss",
    "autoCloseSessions": "Seansi automaatne lõpetamine",
    "nonActivePeriod": "Lõpetage automaatselt, kui seanss pole aktiivne",
    "oneWeek": "1 nädal",
    "oneMonth": "1 kuu",
    "threeMonth": "3 kuud",
    "sixMonth": "6 kuud",
    "terminate": "Täielik",
    "sessionDesc1": "Siin on loetletud kõik seadmed, mis on teie Skyservice'i kontole sisse logitud. Saate oma kontolt välja logida igaühelt eraldi või kõigilt korraga.",
    "sessionDesc2": "Kui leiate loendist võõra seadme, logige sellest seadmest kohe välja ja muutke oma Skyservice'i konto parool.",
    "sureToTerminate": "Kas olete kindel, et soovite kõik aktiivsed seansid lõpetada?",
    "ip": "IP-aadress",
    "geoPos": "Geopositsioon",
    "info": "Teave",
    "sessionDesc3": "Seansid, millel sellel perioodil ei toimu tegevust, lõpevad automaatselt.",
    "terminatesSessions": "Seansside lõpetamine",
    "more3monthAgo": "Üle kolme kuu tagasi",
    "noData": "Andmed puuduvad",
    "goSettingsCompany": "Loome teie ettevõtet, andke meile hetk...",
    "preparingCompany1": "Loome teie ettevõtet, palun andke meile hetk...",
    "preparingCompany2": "Valmistame ette liidest kaupade ja teenuste haldamiseks...",
    "preparingCompany3": "Tegeleme mugava finantsarvestuse kallal...",
    "preparingCompany4": "Teeme lõplikke seadistusi, peaaegu valmis...",
    "appleAccIsAlreadyRegistered": "See AppleId on juba lingitud teise Skyservice'i kontoga",
    "notGetDataFromApple": "Apple'ilt andmete vastuvõtmine ebaõnnestus"
}

export default function() {
    return lang;
}