import axios from 'axios';
import { store } from '@/store.js';
import Cookies from "js-cookie";

// создаем апи куков с настройкой дэфолтного домена для продакшена
let apiCookies = Cookies;

if (process.env.NODE_ENV == 'production') {
   apiCookies = Cookies.withAttributes({domain: ".skyservice.online"})
}

function getUrlParams(search) {
    const hashes = search.slice(search.indexOf('?') + 1).split('&')
    const params = {}
    hashes.map(hash => {
        const [key, val] = hash.split('=')
        params[key] = decodeURIComponent(val)
    })
    return params
}

try{
    //ES2015
    let checkParams = new URLSearchParams(window.location.search)

    if (checkParams.has('token')) {
        apiCookies.set(`token`, checkParams.get('token'), { expires: 180 })
    }
}catch(err){
    //ES6
    try{
        let token = getUrlParams(window.location.search)["token"];
        if(token){
            apiCookies.set(`token`, token, { expires: 180 });
        }
    }catch(err){
        console.log("Не вдалось зберегти токен")
    }

}


// eslint-disable-next-line
// debugger

const url = '//api.auth.skyservice.online';
axios.defaults.baseURL = url;

// Это нужно раскоментить для роботы на локалке !!

if (process.env.NODE_ENV != 'production') {
    Cookies.set(`token`, 'fZFkA9hFy3QbtRBKiN52ndf7eEsyREfn');
}

axios.interceptors.request.use(function(config) {
    //Сделайте что-нибудь до отправки запроса
    try {
        if (config.params.concatenation != "true") {
            store.loader = true;
        }
        delete config.params.concatenation;
    } catch (err) {
        store.loader = true;
    }
    return config;
}, function(error) {
    store.loader = false;
    //Сделать что-то с ошибкой запроса
    return Promise.reject(error);
});
axios.interceptors.request.use(config => {
    try {
      store.loader = true;
      config.params = {
        token: Cookies.get("token"),
        timezone: (new Date().getTimezoneOffset())/60,
        ...config.params
      };
      // console.log(config)
    } catch (err) {
      console.log(err)
      store.loader = true;
    }
    return config;
  }, (error) => {
    store.loader = false;
    if (store.syncChecksInterval < 15000) {
      store.syncChecksInterval = store.syncChecksInterval + 1000;
    }
    return Promise.reject(error);
  })
axios.interceptors.response.use(function(response) {
    //Сделайте что-нибудь с ответными данными
    store.loader = false;
    return response;
}, 
function(error) {
    // Сделать что-то с ошибкой ответа
    store.loader = false;
    try {
        if (JSON.parse(JSON.stringify(error)).response.status == 401 && process.env.NODE_ENV == 'production') {
            window.location = '/';
        }
    } catch {
        //
    }
    // app.messageError(lang["ErrorConnection"]);
    return Promise.reject(error);
});
export class Start {
    //Получение списков для начала работы
getStart() {
        return axios.get(`/${store.lang}/api3/getstart/`).then(response => response.data);
    }
    //Найти изображение товара в гугл
getGoogleImg(q, page) {
    if (!page) {
        page = 1;
    }
    return axios.get(`/${store.lang}/api/getgoogleimg/`, { params: { "q": q, "start": page } })
}
}
export class MoreURL {
    goDemo(lang) {
        return `https://online.skyservice.pro/?section=pos&login=demo&lang=${ lang }`;
    }
}
export class regAPi {
    // добавление почты
    addEmail(obj) {
        return axios.post('?section=users&action=addEmail', obj)
    }
    // проверка аккаунта на регу
    checkAccount(login) {
        return axios.post('?section=users&action=checkLogin', login)
    }
    // проверка телефона на изменении
    checkPhone(phone) {
        return axios.post('?section=settings&action=phoneСheck', phone)
    }
    // Получаем данные старта
    getCompanies() {
        return axios.get(`?section=company&action=getCompanies`);
    }
    // Получаем данные настроек
    getPersonData() {
        return axios.get(`?section=users&action=getUser`);
    }
    // Добавить пароль 
    addPersonPassword(obj) {
        return axios.post('?section=users&action=addPassword', obj);
    }
    // Изменить пароль
    rePassword(obj) {
        return axios.post('?section=users&action=editPassword', obj);
    }
    // выход из системы
    logout(token) {
        return axios.post('?section=users&action=logout', token);
    }
    // владелец или сотрудник
    accountType(obj) {
        return axios.post('?section=users&action=saveUserType', obj);
    }
    // Смена имени
    editName(obj) {
        return axios.post('?section=settings&action=editUserName', obj);
    }
    // Смена языка
    language(lang) {
        return axios.post('?section=users&action=editLanguage', lang);
    }
    // Изменить почту
    editEmail(obj) {
        return axios.post('?section=users&action=editEmail', obj);
    }
    // Изменение телефона
    editPhone(obj) {
        return axios.post('?section=users&action=editPhone', obj)
    }
    addCompany(obj) {
        return axios.post('?section=users&action=addCompany', obj);
    }
    // Подтвердить приглашение
    acceptInv(obj) {
        return axios.post('?section=users&action=acceptInvite', obj);
    }
    // Отменить приглашение
    canselInv(obj) {
        return axios.post('?section=users&action=cancelInvite', obj);
    }
    // Удаление компании
    deleteCompany(obj) {
        return axios.post('?section=company&action=requestDeleteCompany', obj);
    }
    // Redirect person
    redirectPerson(obj) {
        return axios.post('?section=users&action=redirect', obj);
    }
    // удаление аккаунта пользователя
    delAccount(obj) {
        return axios.post('?section=settings&action=requestDeleteAccount', obj);
    }
    // передать компанию другому владельцу
    transferCompany(obj) {
        return axios.post('?section=users&action=transferCompany', obj);
    }
    // выйти из компании
    leaveCompany(obj) {
        return axios.post('?section=users&action=leaveCompany', obj);
    }
    // Рефералы
    myReferrals() {
        return axios.get(`?section=users&action=getReffs`);
    }
   // Captcha img
    captchaUID(uid) {
        return  `${ url }?section=users&action=captcha&captcha_guid=${ uid }`
    }
    // проверка СМС при изменении телефона
    phoneSMS(obj) {
        return axios.post('?section=users&action=checkPhone', obj);
    }
    // Данные по отдельному рефералу - его оплаты и тд
    refData(obj) {
        return axios.post('?section=users&action=refData', obj)
    }
    // восстановить компании
    recoverCompany(obj) {
        return axios.post('?section=company&action=recoverCompany', obj)
    }
    // Создание компании при самом первой авторизации
    companyStart(obj) {
        return axios.post('?section=users&action=companyStart', obj)
    }
    // Сохранение описания в настройках
    saveSettings(obj) {
        return axios.post(`?section=settings&action=editCompany`, obj);
    }
    addGoogle(obj){
        return axios.post(`?section=services&action=addGoogle`, obj);
    }
    removeGoogle(token){
        return axios.post(`?section=services&action=removeGoogle`, {token: token});
    }
    addTelegram(obj){
        return axios.post(`?section=services&action=addTelegram`, obj);
    }
    removeTelegram(token){
        return axios.post(`?section=services&action=removeTelegram`, {token: token});
    }
    addFacebook(obj){
        return axios.post(`?section=services&action=addFacebook`, obj);
    }
    removeFacebook(token){
        return axios.post(`?section=services&action=removeFacebook`, {token: token});
    }
    // прив'язуємо аккаунт apple
    addApple(obj){
        return axios.post('?section=services&action=addApple', obj)
    }
    // відв'язуємо аккаунт apple
    removeApple(token){
        return axios.post(`?section=services&action=removeApple`, {token: token});
    }
    // Сохранение сортировки компаний
    sortCompanies(sortedCompanies) {
        return axios.post(`/?section=company&action=sortCompanies`, { sortedCompanies: sortedCompanies })
    }
    // Получения списка сеансов
    getSessions(obj) {
        return axios.post(`?section=sessions&action=getSessions`, obj)
    }
    // Период неактивности сессий
    periodSessionsNonActive(period) {
        return axios.post(`?section=sessions&action=changeOldSessionTime`, {period: period})
    }
    // Закрытие всех сессий кроме текущей
    terminateAllSessions(obj) {
        return axios.post(`?section=sessions&action=terminateAllSessions`, obj)
    }
    // Закрытие выбранной сессии
    terminateChoosenSession(token) {
        return axios.post(`?section=sessions&action=terminateSession`, {session: token})
    }
    
}

export class RedirectApi {
    redirectCompany(obj) {
        return axios.post(`//staging.skyservice.pro/api/?section=workers&action=checkToken`, obj);//&token=${token}
    }
}
