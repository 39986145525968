import Vue from 'vue';

import ar from './lang/ar.js';
import be from './lang/be.js';
import cn from './lang/cn.js'
import de from './lang/de.js'
import el from './lang/el.js'
import en from './lang/en.js';
import es from './lang/fr.js'
import et from './lang/et.js'
import fi from './lang/fi.js'
import fr from './lang/fr.js'
import he from './lang/he.js'
import it from './lang/it.js'
import ka from './lang/ka.js'
import km from './lang/km.js'
import lt from './lang/lt.js'
import lv from './lang/lv.js'
import pl from './lang/pl.js'
import pt from './lang/pt.js'
import ro from './lang/ro.js'
import ru from './lang/ru.js';
import tg from './lang/tg.js'
import tr from './lang/tr.js'
import uk from './lang/uk.js'

export const store = Vue.observable({
    token: null, // записываем токен пользователя при гет старте
    disableButton: false, // для дизейблов кнопок
    firstTime: true, // первый вход в кабинет для предложения создать компанию
    person_id: null,
    langs: {
        ar,
        be,
        cn,
        de,
        el,
        en,
        es,
        et,
        fi,
        fr,
        he,
        it,
        ka,
        km,
        lt,
        lv,
        pl,
        pt,
        ro,
        ru,
        tg,
        tr,
        uk
    },
    country: '',
    IP: '',
    lang: '',
    window: {
        height: '',
        width: ''
    },
    loader: false,
    ref: {},
    dialogComponent: {
        opened: []
    },
    fb_app_id: '348596312928771',
    params: {
        client_id: "910811730153-q8m9pb2rkt372dlf3u3te7kggbhj3aiu.apps.googleusercontent.com"
    },
    telegram_bot: 'SkyserviceAuthbot',
    loaderText: '',
    appInfoData: {
        os: null,
        appVersion: null,
        device: null
    }
});