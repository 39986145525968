const lang = {
    "ToAccept": "Hyväksyä",
    "Reject": "Hylätä",
    "Password": "Salasana",
    "RepeatPassword": "Toista salasana",
    "OldPassword": "vanha salasana",
    "MyCompany": "Yritykseni",
    "Company": "Yhtiö",
    "CreateNewCompany": "Luo yrityksesi",
    "Сancel": "Peruuttaa",
    "Save": "Tallentaa",
    "GeneralInformation": "yleistä tietoa",
    "CompanyName": "Yrityksen nimi",
    "LogOut": "Kirjaudu ulos",
    "SignOutOfAccount": "Kirjaudu ulos",
    "ChangePassword": "Vaihda salasana",
    "AddPassword": "Lisää salasana",
    "Language": "Kieli",
    "ConfirmThisMail": "Sähköpostisi on vahvistettu",
    "Invites": "kutsuja",
    "AcceptInvitation": "Hyväksy kutsu",
    "EMailOrPhone": "Puhelin tai posti",
    "Saved": "Tallennettu",
    "Error": "Virhe",
    "FillInAllTheFields": "Täytä kaikki kentät",
    "FirstCreateAnAccountPassword": "Luo ensin tilin salasana",
    "Settings": "asetukset",
    "Next": "Edelleen",
    "MinPassLength": "Salasana on liian lyhyt",
    "action": "Toiminta",
    "NewOwner": "Uusi omistaja",
    "UserMustBeRegistered": "Käyttäjän tulee olla rekisteröitynyt",
    "LeaveTheCompany": "Jätä yritys",
    "Confirm": "Vahvistaa",
    "WrongFormat": "Väärä formaatti",
    "DeleteAccount": "Poista tili",
    "WrongPassword": "Väärä salasana",
    "PhoneAlreadyRegistered": "Puhelin on jo rekisteröity",
    "EmailAlreadyRegistered": "Posti on jo rekisteröity",
    "Yes": "Joo",
    "CreateACompany": "Luo yritys",
    "MyReferrals": "Minun viittaukseni",
    "Edit": "Muuttaa",
    "NewEmail": "Uusi posti",
    "Proceed": "Jatkaa",
    "IncorrectCaptcha": "Väärä captcha",
    "CaptchaIsNotValid": "Captcha ei ole enää voimassa",
    "EnterCaptcha": "Kirjoita captcha",
    "EnterSMS": "Kirjoita tekstiviesti",
    "SMSCodeIsNoLongerValid": "SMS-koodi ei ole enää voimassa",
    "SMSCodeIsIncorrect": "SMS-koodi on virheellinen",
    "MailNotVerifiedLinkTimedOut": "Postia ei vahvistettu, linkki vanhentunut",
    "ReferralLink": "Viittauslinkki",
    "Date": "päivämäärä",
    "EarnWithUs": "Ansaitse kanssamme",
    "RecommendSkyservicePOSToYourFriendsAndGetRealMoneyToYourAccount.": "Suosittele Skyservice POSia ystävillesi ja saat oikeaa rahaa tilillesi.",
    "HowItWorks": "Kuinka se toimii?",
    "ItsVerySimpleYouBringCustomersToSkyservicePOSAndWeShareTheirPaymentsWithYou.": "Se on hyvin yksinkertaista: tuot asiakkaat Skyservice POS:iin, ja me jaamme heidän maksunsa kanssasi.",
    "Copied": "Kopioitu",
    "TheMinimumWithdrawalAmountIs100USD": "*Miniminostosumma 100 USD",
    "AddEmail": "Lisää postia",
    "YouDontHaveCompanyYet": "Sinulla ei ole vielä yritystä",
    "NoCompanyText": "Jotta voit aloittaa työskentelyn kassakoneen parissa, sinun on perustettava yritys tai hyväksyttävä työnantajan kutsu.",
    "ConfirmEmail": "Muutoksen vahvistamiseksi on lähetetty sähköposti.",
    "description": "Kuvaus",
    "NoDescriptionSpecified": "Ei kuvausta",
    "DateCreate": "Luomispäivämäärä:",
    "DelComp": "Poista yritys",
    "Help": "Tuki",
    "Email": "Mail",
    "Phone": "Puhelin",
    "SureAccDel": "Haluatko varmasti poistaa tilisi linkityksen?",
    "no": "Ei",
    "yes": "Joo",
    "confirm": "Vahvistaa",
    "cancel": "Peruuttaa",
    "Search": "Hae",
    "dateDelete": "Poistopäivä:",
    "active": "Aktiivinen",
    "delete": "Poistettu",
    "services": "Palvelut",
    "status": "Tila",
    "recover": "Perustaa uudelleen",
    "restored": "palautettu",
    "restore": "Elpyminen",
    "DoYouWantRestore": "Haluatko palauttaa kampanjan",
    "deletedCompamies": "ETÄYRITYKSET",
    "CompanyNotSearch": "Ehtojasi vastaavia yrityksiä ei löytynyt",
    "writeCorrectlyName": "Kirjoita käyttäjänimi oikein",
    "addlogo": "Lisää logo",
    "Logo": "Logo",
    "nipmg": "Napsauta tai vedä tiedosto tähän ikkunaan",
    "ErrorDownload": "latausvirhe",
    "preview": "esikatselu",
    "onlyimg": "Vain kuvat",
    "imgisbig": "Kuva ylittää kokorajoituksen",
    "minimgsize": "Kuvan vähimmäiskoko",
    "Zagruzka...": "Ladataan...",
    "Youbrowserisold": "Selaintasi ei tueta, käytä IE10+ tai muuta selainta",
    "failZagruzhen": "Tiedosto ladattu onnistuneesti!",
    "zakrit": "kiinni",
    "nazad": "Takaisin",
    "save": "Tallentaa",
    "zagruzit_foto": "Lataa kuva",
    "clear": "Asia selvä",
    "logoIsAdd": "Logo lisätty",
    "logoIsDel": "Logo tyhjennetty",
    "editComp": "Muokkaa yritystä",
    "giveComp": "Siirtoyhtiö",
    "thisUserNorRegistered": "Tämä käyttäjä ei ole rekisteröitynyt",
    "cantTransferToYou": "Et voi siirtää yritystäsi itsellesi",
    "youNotOwner": "Et ole yrityksen omistaja",
    "Ok": "OK",
    "notYetCreateComp": "Tällä hetkellä uusien yritysten perustaminen ei ole mahdollista",
    "EnterToCompany": "Kirjaudu yritykseen",
    "startDescription": "Tervetuloa henkilökohtaiselle tilillesi, kirjaudu yritykseen aloittaaksesi",
    "inviteComp": "Kutsu yritykseen",
    "visitDemo": "Vieraile demoyrityksessä",
    "makeUrChoice": "Tee valinta",
    "ImWorker": "Olen työntekijä",
    "ImOwner": "Olen työnantaja, työnantajan edustaja",
    "needInviteComp": "Sinun on hyväksyttävä kutsu olemassa olevaan yritykseen",
    "needCreateComp": "Sinun on perustettava yritys aloittaaksesi",
    "daysLeft": "Päiviä jäljellä",
    "cannotDel": "Asennuksen poisto ei ole tällä hetkellä käytettävissä",
    "NoCompanyCreateText": "Kassalla työskentelyn aloittamiseksi sinun on hyväksyttävä työnantajan kutsu.",
    "withoutName": "Ei nimeä",
    "cropImageHint": "Napsauta tai vedä ladattavaa tiedostoa",
    "cropImageNoSupported": "Selainta ei tueta, käytä IE10+ tai muuta selainta.",
    "cropImageSuccess": "Lataus onnistui",
    "cropImageFail": "Lataus epäonnistui",
    "cropImageOnlyImgNeed": "Vain kuvat",
    "cropImageOutOfSize": "Kuvan koko ylittää rajan",
    "cropImageLowestPx": "Kuvan vähimmäiskoko",
    "uReachedLimitComp": "Olet saavuttanut luotujen yritysten enimmäismäärän",
    "accAlreadyRegister": "Tämä tili on jo linkitetty toiseen tiliin",
    "accNotRegister": "Sellaista tiliä ei ole olemassa",
    "areYourCountry": "onko tämä sinun maasi?",
    "enterCorrectNumber": "Syötä numero kansainvälisessä muodossa",
    "attach": "sitoa",
    "UserEmailAlreadyExists": "Käyttäjä, jolla on tämä sähköpostiosoite, on jo olemassa",
    "AccountAlreadyLinked": "Tili on jo linkitetty",
    "facebookAccIsAlreadyRegistered": "Tämä Facebook-tili on jo linkitetty toiseen Skyservice-tiliin",
    "googleAccIsAlreadyRegistered": "Tämä Google-tili on jo linkitetty toiseen Skyservice-tiliin",
    "telegramAccAlreadyRegister": "Tämä Telegram on jo linkitetty toiseen Skyservice-tiliin",
    "companyBlocked": "Tämä yritys ei ole aktiivinen",
    "allSessions": "Näytä kaikki istunnot",
    "activeSessions": "Aktiiviset istunnot",
    "exitAllDevices": "Kirjaudu ulos kaikista muista laitteista",
    "currentSession": "Nykyinen istunto",
    "autoCloseSessions": "Istunnon automaattinen täydennys",
    "nonActivePeriod": "Lopeta automaattisesti, jos istunto ei ole aktiivinen",
    "oneWeek": "1 viikko",
    "oneMonth": "1 kuukausi",
    "threeMonth": "3 kuukautta",
    "sixMonth": "6 kuukautta",
    "terminate": "Saattaa loppuun",
    "sessionDesc1": "Kaikki Skyservice-tilillesi kirjautuneet laitteet on lueteltu tässä. Voit kirjautua ulos tililtäsi jokaisesta niistä erikseen tai kaikista kerralla.",
    "sessionDesc2": "Jos löydät luettelosta tuntemattoman laitteen, kirjaudu välittömästi ulos tältä laitteelta ja vaihda Skyservice-tilisi salasana.",
    "sureToTerminate": "Haluatko varmasti lopettaa kaikki aktiiviset istunnot?",
    "ip": "IP-osoite",
    "geoPos": "Geoposition",
    "info": "Tiedot",
    "sessionDesc3": "Istunnot, joissa ei ole aktiivisuutta tänä aikana, päättyvät automaattisesti.",
    "terminatesSessions": "Istunnot päättyvät",
    "more3monthAgo": "Yli kolme kuukautta sitten",
    "noData": "Ei dataa",
    "goSettingsCompany": "Luomme yritystäsi, anna meille hetki...",
    "preparingCompany1": "Luomme yritystäsi, anna meille hetki...",
    "preparingCompany2": "Valmistelemme käyttöliittymää tavaroiden ja palveluiden hallintaan...",
    "preparingCompany3": "Työskentelemme kätevän taloushallinnon parissa...",
    "preparingCompany4": "Teemme lopullisia asetuksia, melkein valmiina...",
    "appleAccIsAlreadyRegistered": "Tämä AppleId on jo linkitetty toiseen Skyservice-tiliin",
    "notGetDataFromApple": "Tietojen vastaanottaminen Applelta epäonnistui"
}

export default function() {
    return lang;
}