<template>
    <smallModal @eccape="$emit('close')">
        <div>
            <label for="new-email">{{ lang['Email'] }}</label>
            <b-form-input v-model="addEmail" autofocus class="email-input-settings" :state="checkEmail" />
        </div>
        <hr>
        <div class="buttons">
            <button class="btn btn-secondary" @click="$emit('close')">{{ lang['Сancel'] }}</button>
            <button class="btn btn-success" @click="saveEmail">{{ lang['Save'] }}</button>
        </div>
    </smallModal>
</template>

<script>
import smallModal from '../modals/smallModal';
import { regAPi } from '@/API.js';
import { store } from "@/store.js";

const apiList = new regAPi();
export default {
    components: {
        smallModal
    },
    data() {
        return {
            addEmail: ''
        }
    },
    methods: {
        saveEmail() {
            try {
                if (this.checkEmail) {
                    apiList.addEmail({ token: store.token, email: this.addEmail }).then(resp => {
                        if (resp.data.status == 'done') {
                            this.$emit('close');
                            window.say.message(this.lang['Saved'])
                        } else if(resp.data.error[0] == 'Email is already registered'){
                            window.say.messageError(this.lang['EmailAlreadyRegistered']);
                        } else {
                            window.say.messageError(this.lang['Error']);
                        }
                    })
                } else {
                    window.say.messageError(this.lang['WrongFormat']);
                }
            } catch(err) {
                //
            }
        }
    },
    computed: {
        checkEmail() {
            const mailRegular  = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (mailRegular.test(this.addEmail) == false) return false;
            else return true;
        },
        lang() {
            return window.lang;
        }
    }
}
</script>

<style scoped>
.buttons{
    display: flex; 
    justify-content: space-between;
}
.btn-secondary, .btn-success{
  width: 49%;
}
</style>