<template>
    <smallModal>
        <label for="sms">{{ lang['EnterSMS'] }}</label>
        <b-form-input v-model="sms" name="sms" />
        <div class="buttons">
            <button class="btn btn-secondary" @click="$emit('close')">{{ lang['Сancel'] }}</button>
            <button class="btn btn-success" @click="checkSMS">{{ lang['Save'] }}</button>
        </div>
    </smallModal>
</template>

<script>
import { store } from '@/store.js';
import smallModal from '../modals/smallModal';
import { regAPi } from '@/API.js';

const apiList = new regAPi();
export default {
    props: ['smsPhone'],
    components: {
        smallModal
    },
    data() {
        return {
            sms: ''
        }
    },
    methods: {
        checkSMS() {
            try {
                apiList.phoneSMS({ sms_code: this.sms, token: store.token, new_phone: this.smsPhone }).then(resp => {
                    if (resp.data.status == 'done') {
                        this.$emit('close');
                        window.say.message(this.lang['Saved']);
                    } else {
                        if (resp.data.error == 'SMS code dont exist') {
                            window.say.messageError(this.lang['SMSCodeIsNoLongerValid']);
                        } else if (resp.data.error == 'SMS code is incorrect') {
                             window.say.messageError(this.lang['SMSCodeIsIncorrect']);
                        }
                        window.say.messageError(this.lang['Error']);
                    }
                })
            } catch(err) {
                //
            }
        }
    },
    computed: {
        lang() {
            return window.lang;
        }
    }
}
</script>

<style scoped>
    .btn-secondary, .btn-success{
        width: 49%;
    }
    .buttons{
        display: flex;
        justify-content: space-between;
    }
</style>