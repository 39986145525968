<template>
    <div class="head-person-page" id="headerContiner-white">
        <!-- <button onclick="document.location.reload(true); try{ Android.deinit(); }catch(err){}">test</button> -->
        <div class="logo-container">
   
            <svg fill="#FFFFFF" style="height: 50px" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 21" enable-background="new 0 0 500 100" xml:space="preserve">
                <filter id="dropshadow" height="130%">
                <feGaussianBlur in="SourceAlpha" stdDeviation="0.4"/> <!-- stdDeviation is how much to blur -->
                <feOffset dx="0" dy="0" result="offsetblur"/> <!-- how much to offset -->
                <feMerge>
                    <feMergeNode/> <!-- this contains the offset blurred image -->
                    <feMergeNode in="SourceGraphic"/> <!-- this contains the element that the filter is applied to -->
                </feMerge>
                </filter>
                <path style="filter:url(#dropshadow)" d="M0.377,13.485h8.466c1.374,0,1.439-1.092,1.439-1.596c0-0.586-0.152-1.257-1.483-1.655L2.776,8.41
                    c-0.239-0.063-1.199-0.377-1.81-1.091C0.268,6.521,0.181,5.369,0.181,4.55c0-0.881,0.109-1.95,0.981-2.727
                    c0.785-0.711,1.768-0.818,2.51-0.818h8.792v2.203H4.653c-0.392,0-0.851,0.021-1.199,0.336C3.083,3.879,3.062,4.403,3.062,4.676
                    c0,0.651,0.196,1.279,1.417,1.657l5.585,1.699c1.024,0.315,1.724,0.63,2.181,1.048c0.917,0.839,1.004,2.14,1.004,2.831
                    c0,0.923-0.131,1.952-0.763,2.729c-0.764,0.944-1.724,1.048-2.727,1.048H0.377V13.485z"/>
                <path style="filter:url(#dropshadow)" d="M14.777,1.005h2.552v14.682h-2.552V1.005z M22.872,15.688l-3.994-4.068
                    c-0.328-0.337-0.785-0.902-0.785-1.679c0-0.838,0.479-1.321,0.807-1.677l3.49-3.692h2.967L21.017,9.06
                    c-0.196,0.209-0.371,0.441-0.371,0.797c0,0.356,0.262,0.734,0.546,1.028l4.734,4.803H22.872z"/>
                <polygon style="filter:url(#dropshadow)" points="31.51,19.274 28.914,19.274 30.682,15.645 25.533,4.571 28.172,4.571 31.881,13.106 35.809,4.613
                    38.405,4.613 "/>
                <path style="filter:url(#dropshadow)" d="M38.732,14.702h6.633c0.676,0,1.135-0.231,1.354-0.525C46.915,13.904,47,13.275,47,12.899
                    c0-0.631-0.259-0.988-0.435-1.156c-0.371-0.333-1.048-0.502-2.727-0.88l-2.007-0.44c-1.768-0.397-2.401-0.942-2.663-1.237
                    c-0.502-0.546-0.633-1.238-0.633-1.846c0-0.734,0.174-1.3,0.589-1.803c0.72-0.902,1.703-0.965,2.792-0.965h5.543v0.986h-6
                    c-0.261,0-0.917,0.021-1.287,0.378c-0.393,0.378-0.415,1.027-0.415,1.321c0,0.378,0.044,1.028,0.48,1.447
                    c0.393,0.378,1.594,0.67,1.985,0.755l2.095,0.461c1.395,0.315,2.4,0.524,3.075,1.049c0.721,0.545,0.83,1.196,0.83,1.931
                    c0,0.773-0.153,1.803-1.004,2.367c-0.567,0.378-1.353,0.421-1.81,0.421h-6.677V14.702z"/>
                <path style="filter:url(#dropshadow)" d="M53.524,15.688c-0.938,0-2.159-0.231-3.054-1.363c-0.896-1.111-0.938-2.517-0.938-4.153
                    c0-1.552,0.086-3.439,1.548-4.615c1.046-0.838,2.465-0.986,3.619-0.986c0.81,0,2.009,0.106,2.948,0.63
                    c0.458,0.272,1.854,1.196,1.854,3.524c0,0.314-0.023,1.069-0.48,1.447c-0.349,0.293-0.895,0.335-1.223,0.335h-7.046
                    c-0.021,0.651,0.021,1.679,0.197,2.265c0.261,0.901,1.047,1.931,2.574,1.931h5.673v0.985H53.524z M57.8,9.521
                    c0.504,0,0.48-0.587,0.48-0.734c0-1.089-0.392-1.824-0.785-2.265c-0.829-0.944-1.963-0.965-2.988-0.965
                    c-1.156,0-2.401,0.042-3.209,1.343c-0.457,0.734-0.524,1.531-0.544,2.622H57.8z"/>
                <path style="filter:url(#dropshadow)" d="M61.247,8.389c0-1.132,0.153-2.119,1.025-2.915c0.48-0.44,1.289-0.903,2.837-0.903h0.786v0.987h-0.7
                    c-0.632,0-1.592,0.063-2.223,0.775c-0.481,0.566-0.592,1.405-0.592,2.307v7.048h-1.134V8.389z"/>
                <path style="filter:url(#dropshadow)" d="M70.345,14.261c0.065,0.147,0.219,0.461,0.328,0.461c0.107,0,0.263-0.313,0.326-0.461l3.73-9.69h1.158
                    l-4.016,10.278c-0.305,0.796-0.762,0.965-1.199,0.965s-0.895-0.169-1.201-0.965L65.458,4.571h1.156L70.345,14.261z"/>
                <path style="filter:url(#dropshadow)" d="M76.761,1.006h1.221v1.552h-1.221V1.006z M76.804,4.571h1.134v11.117h-1.134V4.571z"/>
                <path style="filter:url(#dropshadow)" d="M84.178,15.688c-1.635,0-2.727-0.631-3.404-1.405c-0.916-1.05-1.047-2.371-1.047-4.007
                    c0-1.509,0.087-3.271,1.311-4.466c0.675-0.65,1.788-1.238,3.6-1.238h3.903v0.987h-4.034c-1.855,0-2.99,0.944-3.338,2.265
                    c-0.153,0.608-0.219,2.055-0.219,2.6c0,0.735,0.108,1.993,0.393,2.622c0.675,1.468,2.357,1.658,3.402,1.658h4.059v0.985H84.178z"/>
                <path style="filter:url(#dropshadow)" d="M94.104,15.688c-0.938,0-2.159-0.231-3.054-1.363c-0.895-1.111-0.938-2.517-0.938-4.153
                    c0-1.552,0.087-3.439,1.55-4.615c1.047-0.838,2.466-0.986,3.62-0.986c0.807,0,2.008,0.106,2.946,0.63
                    c0.459,0.272,1.854,1.196,1.854,3.524c0,0.314-0.023,1.069-0.48,1.447c-0.349,0.293-0.894,0.335-1.222,0.335h-7.048
                    c-0.02,0.651,0.022,1.679,0.197,2.265c0.263,0.901,1.047,1.931,2.574,1.931h5.672v0.985H94.104z M98.381,9.521
                    c0.503,0,0.479-0.587,0.479-0.734c0-1.089-0.392-1.824-0.785-2.265c-0.83-0.944-1.964-0.965-2.989-0.965
                    c-1.156,0-2.398,0.042-3.206,1.343c-0.459,0.734-0.524,1.531-0.547,2.622H98.381z"/>
            </svg>



        </div>

        <b-dropdown :text="w >= 768 ?  ' ' + personData.user.name : ' '" id="dropdown-right" right>
            <!-- <b-dropdown-item-button class="dropdown-item-container" v-if="w <= 768" disabled>
                <div class="dropdown-div" style="color: grey;">
                    {{ personData.user.name }}
                </div> 
            </b-dropdown-item-button> -->
            <!-- <b-dropdown-divider v-if="w <= 768"></b-dropdown-divider> -->
            <b-dropdown-item-button class="dropdown-item-container" @click="settingsDropdown">
                <div class="dropdown-div">
                    <span style="padding-right: 5px">{{ lang['Settings'] }} </span>
                    <!-- <span class="icon-cogs"></span> -->
                </div> 
            </b-dropdown-item-button>
            <b-dropdown-item-button class="dropdown-item-container" @click="showСheckSessions = !showСheckSessions">
                <div class="dropdown-div">
                    <span style="padding-right: 5px">{{ lang['activeSessions'] }} </span>
                    <!-- <span class="icon-users"></span> -->
                </div>
            </b-dropdown-item-button>
            <b-dropdown-item-button class="dropdown-item-container" @click="showRefs = !showRefs">
                <div class="dropdown-div">
                    <span style="padding-right: 5px">{{ lang['MyReferrals'] }} </span>
                    <!-- <span class="icon-users"></span> -->
                </div>
            </b-dropdown-item-button>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item-button class="dropdown-item-container" @click="showEscapeModal = !showEscapeModal">
                <div class="dropdown-div">
                    <span style="padding-right: 5px">{{ lang['LogOut'] }} </span>
                    <!-- <span class="icon-exit"></span> -->
                </div>
            </b-dropdown-item-button> 
        </b-dropdown>

        <personsettings v-if="showSettings" @close="showSettings = !showSettings" @updateData="updateData" />
        <escape v-if="showEscapeModal" @close="showEscapeModal = false" />
        <!-- посмотреть сессии -->
        <checkSessions v-if="showСheckSessions" @close="showСheckSessions = false"></checkSessions>
        <referrals v-if="showRefs" @close="showRefs = false" @updateData="updateData" />
    </div>
</template>

<script>
import personsettings from '../settings/PersonSettings';
import escape from '../Escape';
import referrals from '../referrals/Referrals.vue';
import checkSessions from "../settings/checkSessions";
import { keyevent } from '@/main.js';
import { store } from '@/store.js';
export default {
    props:['personData', 'withoutName'],
    components: {
        personsettings, 
        escape,
        checkSessions,
        referrals
    },
    data() {
        return {
            showSettings: false,
            showEscapeModal: false,
            showRefs: false,
            showСheckSessions: false,
        }
    },
    created() {
        if(this.withoutName){
            this.personData.user.name = '  '
        }
        this.$nextTick(() => {
            this.personBTNStyle()
        })
    },
    mounted(){
        if(!this.showEscapeModal){
            keyevent.$off('esc');
            keyevent.$on('esc', () => {
            this.showEscapeModal = true
            });
        }
    },
    updated() {
        if(this.withoutName){
            this.personData.user.name = '  '
        }
        if(!this.showEscapeModal){
            keyevent.$off('esc');
            keyevent.$on('esc', () => {
            this.showEscapeModal = true
            });
        }
    },
    methods: {
        // Добавляем необходимые классы в в бутстраповский дропдаун
        // Делаем свой цвет кнопке и добавляем класс с иконкой,а так же кикаем дефолтный класс
        personBTNStyle() {
            const btn = document.querySelector('.dropdown-toggle');
            btn.classList.add('icon-user');
            btn.classList.add('user-btn');
            btn.classList.remove('btn-secondary');
        },
        // Убираем класс SHOW с дропдауна, если открыл пользователь Настройки
        settingsDropdown() {
            const s = document.querySelector('.dropdown-menu');
            s.classList.remove('show');
            this.showSettings = !this.showSettings;
        },
        // Апдейтим данные в родительском запросе
        updateData() {
            this.$emit('updateData');
        }
    },
    computed: {
        lang() {
            if (this.personData.lang != '' && this.personData.lang  != null) {
                window.lang = store.langs[this.personData.lang]()
            }
            return window.lang;
        },
        w() {
            return store.window.width;
        } 
    }
}
</script>

<style>
    .head-person-page {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        background: #3693cf;
        height: 70px;
        margin-bottom: 1rem;
        border-bottom: 1px solid #c3c3c3;
        position: relative;
    }
    .user-btn {
        background: #17a2b8;
        border-color: #17a2b8;
        color: #fff;
    }
    .user-btn:hover, .user-btn:active {
        background-color: #138496;
        border-color: #117a8b;
        color: #fff;
    }
    .dropdown-div {
        display: flex;
        justify-content: space-between;
    }
    #dropdown-right__BV_toggle_ {
        box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
    }
    @media screen and (max-width: 700px){
        .logo-container {
            width: 30vw;
        }
    }
</style>