<template>
    <smallModal @escape="$emit('close')">
        <div class="del-acc-div">
            <h5>{{ lang['DeleteAccount'] }}: {{ personData.user_name }}?</h5>
            <hr>
            <div v-if="personData.have_password">
                <div v-if="ccc != null">
                    <div class="buttons-flex-end">
                        <button @click="captchaImg" class="btn" >
                            <b-icon-arrow-repeat id="captcha-del"></b-icon-arrow-repeat>
                        </button>
                    </div>
                    <div class="imgDiv">
                        <img :src="ccc" class="capImg">
                    </div>
                    <label :for="captchaName">{{ lang['EnterCaptcha'] }}</label>
                    <b-form-input v-model="captcha" :state="capState" :name="captchaName" autofocus />
                </div>

                <label for="accountPassword">{{ lang['Password'] }}</label>
                <b-form-input v-model="accountPassword" type="password" :state="passState" name="accountPassword"  />
            </div>

            <div v-else class="center-text">
                <span class="grey-span">{{ lang['FirstCreateAnAccountPassword'] }}</span>
                <hr>
            </div>
            <div class="buttons">
                <button class="btn btn-secondary" @click="$emit('close')">{{ lang['Сancel'] }}</button>
                <button v-if="personData.have_password" class="btn btn-success longWord" @click="save">{{ lang['Confirm'] }}</button>
                <button v-else class="btn btn-success" @click="goAddPass">{{ lang['Proceed'] }}</button>
            </div>
            <!-- этот див для автофокуса, чтобы работал Enter -->
            <div ref="delAcc"
                tabindex="0">
            </div>
        </div>
    </smallModal>
</template>

<script>
import { store } from '@/store.js';
import smallModal from '../modals/smallModal';
import { keyevent } from '@/main.js';
import { rotate } from '../js/rotateBtn';
import { guid } from '../js/guid';
import { errList } from '../js/CaptchaAndPassError';
import { regAPi } from '@/API.js';
import Cookies from 'js-cookie';

// создаем апи куков с настройкой дэфолтного домена для продакшена
let apiCookies = Cookies;

if (process.env.NODE_ENV == 'production') {
   apiCookies = Cookies.withAttributes({domain: ".skyservice.online"})
}

const apiList = new regAPi();
export default {
    props: ['personData'],
    components: {
        smallModal
    },
    data() {
        return {
            accountPassword: '',
            ccc: null,
            captcha: '',
            guid_now: '',
            captchaName:'captcha_'+this.getRandNum(999,99999)
        }
    },
    created() {
        this.captchaImg();
    },
    mounted(){
        // автофокус для дива
        this.$nextTick(() => {
            this.$refs.delAcc.focus();
        });
        keyevent.$off('enter');
        keyevent.$on('enter', () => {
            this.personData.have_password ? this.save() : this.goAddPass()
        });
    },
    methods: {
        // ( костыль для модалки добавления пароля, обычный кейивэнт там не работает ) (можно удалить после теста)
        // onKeyDown(event) {
        //     if (event.key === "Enter") {
        //         this.personData.have_password ? this.save() : this.goAddPass()
        //     }
        // },
        getRandNum(min, max) {
            return Math.random() * (max - min) + min;
        },
        goAddPass() {
            this.$emit('close');
            this.$emit('goaddpass')
        },
        captchaImg() {
            try {
                // создаем гуид(файл guid.js) который скидываем для капичи состарта, потом, если юзер нажал сохранить, то скидываем текущий гуид на сервак для сравнения
                const a = guid();
                const c = apiList.captchaUID(a);
                this.ccc = c;
                this.guid_now = a;
                // крутилка для кнопки
                rotate('#captcha-del');
            } catch(err) {
                console.log(err)
            }
        },
        save() {
            try {
               if (this.passState && this.capState) {
                    // для удаления аккаунта пользователю необходимо ввести пароль, так же, передаем текущий токен юзера
                    apiList.delAccount({ token: store.token, password: this.accountPassword, captcha: this.captcha, captcha_guid: this.guid_now }).then(resp => {
                        if (resp.data.status == 'done') {
                            //удаляем токен
                            apiCookies.set("token", '', {expires: -1});
                            localStorage.removeItem('registration_phone');
                            store.token = '';
                            // this.$router.push('/');
                            document.location.href = 'https://auth.skyservice.online/';
                        } else {
                            errList(resp.data.error[0]);
                        }
                    })
                } else {
                    window.say.messageError(this.lang['Password']);
                }
            } catch(err) {
                window.say.messageError(this.lang['Error']);
            }
        }
    },
    computed: {
        passState() {
            return this.accountPassword.length >= 1 ? true : false;
        },
        lang() {
            return window.lang;
        },
        capState() {
            return this.captcha.length == 4 ? true : false;
        }
    }
}
</script>

<style scoped>
    .buttons-flex-end{
        display: flex; 
        justify-content: flex-end;
    }
    .longWord {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    #captcha-del{
        height: 2rem; 
        width: 2rem;
    }
    .btn-secondary, .btn-success{
        width: 49%;
    }
    .buttons{
        display: flex;
        justify-content: space-between;
    }
    .center-text{
    text-align: center;
    }
    .grey-span{
        color: grey;
    }
    .imgDiv{
        text-align: center; 
        height: 100px;
    }
    .capImg{
        height: 100px;
    }
</style>