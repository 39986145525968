<template>
    <smallModal @escape="$emit('close')">
        <div class="transfer-div">
            <h5>{{ companyData.company_name }}</h5>
            <hr>
            <div class="login-div">
                <label for="newMaster">{{ lang['NewOwner'] }} <span class="grey-tip">({{ lang['EMailOrPhone'] }})</span></label>
                <b-form-input class="login-input" v-model="form.newMaster" autofocus name="newMaster" />
                <span class="red-tip">{{ lang['UserMustBeRegistered'] }}</span>
            </div>
            <div v-if="ccc != null">
                <div class="buttons-flex-end">
                    <button @click="captchaImg" class="btn">
                        <b-icon-arrow-repeat id="captcha-transfer"></b-icon-arrow-repeat>
                    </button>
                </div>
                <div class="imgDiv">
                    <img :src="ccc" class="capImg">
                </div>
                <label :for="captchaName">{{ lang['EnterCaptcha'] }}</label>
                <b-form-input v-model="form.captcha" :state="capState" :name="captchaName" />
            </div>

            <label for="accountPassword">{{ lang['Password'] }}</label>
            <b-form-input v-model="form.accountPassword" type="password" :state="passState" name="accountPassword" />
            <hr>
            <div class="buttons">
                <button class="btn btn-secondary" @click="$emit('close')">{{ lang['Сancel'] }}</button>
                <button class="btn btn-success longWord" @click="save">{{ lang['Confirm'] }}</button>
            </div>
        </div>
    </smallModal>
</template>

<script>
import { store } from '@/store.js';
import { keyevent } from '@/main.js';
import { rotate } from '../js/rotateBtn'
import { guid } from '../js/guid.js';
import { errList } from '../js/CaptchaAndPassError';
import { regAPi } from '@/API.js';
import smallModal from '../modals/smallModal';

const apiList = new regAPi();
export default {
    props: ['companyData'],
    components: {
        smallModal
    },
    data() {
        return {
            form: {
                newMaster: '',
                accountPassword: '',
                captcha: '',
                captcha_guid: ''
            },
            ccc: null,
            captchaName:'captcha_'+this.getRandNum(999,99999)
        }
    },
    created() {
        this.captchaImg()
    },
    mounted() {
        keyevent.$off('enter');
        keyevent.$on('enter', () =>
            this.save()
        );
    },
    methods: {
        getRandNum(min, max) {
            return Math.random() * (max - min) + min;
        },
        captchaImg() {
            try {
                // создаем гуид (файл guid.js) который скидываем для капичи состарта, потом, если юзер нажал сохранить, то скидываем текущий гуид на сервак для сравнения
                const a = guid();
                const c = apiList.captchaUID(a);
                this.ccc = c;
                this.form.captcha_guid = a;
                // крутилка для кнопки
                rotate('#captcha-transfer');
            } catch(err) {
                console.log(err)
            }
        },
        save() {
            try {
                // для передачи компании новому владельцу передаем текущий токен, логин нового владельца(он должен быть зареган), ну и ИД компании которую передае
                if (this.passState && this.form.newMaster != '' && this.capState) {
                    apiList.transferCompany({ token: store.token, ...this.form, company_id: this.companyData.company_id }).then(resp => {
                        if (resp.data.status == 'done') {
                            window.say.message(this.lang['Saved']);
                            this.$emit('close');
                        } else if (resp.data.error == "New owner is not registered") {
                            window.say.messageError(this.lang['thisUserNorRegistered']);
                        } else if (resp.data.error == "Captcha is incorrect") {
                        window.say.messageError(this.lang['IncorrectCaptcha']);
                        } else if (resp.data.error == "Captcha dont exist") {
                        window.say.messageError(this.lang['CaptchaIsNotValid']);
                        } else if (resp.data.error == "Password is incorrect") {
                        window.say.messageError(this.lang['WrongPassword']);
                        } else if (resp.data.error == "User is not owner of company") {
                        window.say.messageError(this.lang['youNotOwner']);
                        } else if (resp.data.error == "Сannot be transferred to oneself") {
                        window.say.messageError(this.lang['cantTransferToYou']);
                        } else {
                            errList(resp.data.error[0]);
                        }
                    })
                } else {
                    window.say.messageError(this.lang['FillInAllTheFields']);
                }
            } catch(err) {
                //
            }
        }
    },
    computed: {
        lang() {
            return window.lang
        },
        passState() {
            return this.form.accountPassword.length >= 1 ? true : false;
        },
        capState() {
            return this.form.captcha.length == 4 ? true : false;
        }
    }
}
</script>

<style scoped>
.longWord {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.btn-secondary, .btn-success{
    width: 49%;
}
.buttons{
    display: flex;
    justify-content: space-between;
}
.imgDiv{
    text-align: center; 
    height: 100px;
}
.capImg{
    height: 100px;
}
#captcha-transfer{
    height: 2rem; 
    width: 2rem;
}
.buttons-flex-end{
    display: flex; 
    justify-content: flex-end;
}
.red-tip{
    color: red; 
    font-size: 0.8rem;
}
.login-input{
    margin-bottom: 0 !important;
}
.grey-tip{
    color: gray; 
    font-size: 0.8rem;
}
.login-div{
    margin-bottom: 1rem;
}
</style>