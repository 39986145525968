const lang = {
    "ToAccept": "Akzeptieren",
    "Reject": "Ablehnen",
    "Password": "Passwort",
    "RepeatPassword": "Wiederhole das Passwort",
    "OldPassword": "altes Kennwort",
    "MyCompany": "Meine Unternehmen",
    "Company": "Unternehmen",
    "CreateNewCompany": "Erstellen Sie Ihr Unternehmen",
    "Сancel": "Absagen",
    "Save": "Speichern",
    "GeneralInformation": "allgemeine Informationen",
    "CompanyName": "Name des Unternehmens",
    "LogOut": "Abmelden",
    "SignOutOfAccount": "Ausloggen",
    "ChangePassword": "Passwort ändern",
    "AddPassword": "Passwort hinzufügen",
    "Language": "Sprache",
    "ConfirmThisMail": "Deine Email wurde bestätigt",
    "Invites": "Einladungen",
    "AcceptInvitation": "Die Einladung annehmen",
    "EMailOrPhone": "Telefon oder Mail",
    "Saved": "Gerettet",
    "Error": "Fehler",
    "FillInAllTheFields": "Füllen Sie alle Felder aus",
    "FirstCreateAnAccountPassword": "Erstellen Sie zunächst ein Kontokennwort",
    "Settings": "Einstellungen",
    "Next": "Weiter",
    "MinPassLength": "Das Passwort ist zu kurz",
    "action": "Handlung",
    "NewOwner": "Neuer Besitzer",
    "UserMustBeRegistered": "Benutzer muss registriert sein",
    "LeaveTheCompany": "Das Unternehmen verlassen",
    "Confirm": "Bestätigen",
    "WrongFormat": "Falsches Format",
    "DeleteAccount": "Konto löschen",
    "WrongPassword": "Falsches Passwort",
    "PhoneAlreadyRegistered": "Telefon bereits registriert",
    "EmailAlreadyRegistered": "Post bereits registriert",
    "Yes": "Ja",
    "CreateACompany": "Erstellen Sie ein Unternehmen",
    "MyReferrals": "Meine Empfehlungen",
    "Edit": "Ändern",
    "NewEmail": "Neue Post",
    "Proceed": "Weitermachen",
    "IncorrectCaptcha": "Falsches Captcha",
    "CaptchaIsNotValid": "Captcha ist nicht mehr gültig",
    "EnterCaptcha": "Captcha eingeben",
    "EnterSMS": "SMS eingeben",
    "SMSCodeIsNoLongerValid": "SMS-Code ist nicht mehr gültig",
    "SMSCodeIsIncorrect": "SMS-Code ist falsch",
    "MailNotVerifiedLinkTimedOut": "Mail nicht bestätigt, Link abgelaufen",
    "ReferralLink": "Empfehlungslink",
    "Date": "Datum von",
    "EarnWithUs": "Verdienen Sie mit uns",
    "RecommendSkyservicePOSToYourFriendsAndGetRealMoneyToYourAccount.": "Empfehlen Sie Skyservice POS Ihren Freunden und erhalten Sie echtes Geld auf Ihr Konto.",
    "HowItWorks": "Wie es funktioniert?",
    "ItsVerySimpleYouBringCustomersToSkyservicePOSAndWeShareTheirPaymentsWithYou.": "Es ist ganz einfach: Sie bringen Kunden zu Skyservice POS und wir teilen ihre Zahlungen mit Ihnen.",
    "Copied": "Kopiert",
    "TheMinimumWithdrawalAmountIs100USD": "*Mindestauszahlungsbetrag 100 USD",
    "AddEmail": "E-Mail hinzufügen",
    "YouDontHaveCompanyYet": "Sie haben noch kein Unternehmen",
    "NoCompanyText": "Um mit der Kasse zu arbeiten, müssen Sie ein Unternehmen gründen oder eine Einladung eines Arbeitgebers annehmen.",
    "ConfirmEmail": "Eine E-Mail wurde gesendet, um die Änderung zu bestätigen.",
    "description": "Beschreibung",
    "NoDescriptionSpecified": "Keine Beschreibung",
    "DateCreate": "Erstelldatum:",
    "DelComp": "Firma löschen",
    "Help": "Die Unterstützung",
    "Email": "Post",
    "Phone": "Telefon",
    "SureAccDel": "Möchten Sie die Verknüpfung Ihres Kontos wirklich aufheben?",
    "no": "Nein",
    "yes": "Ja",
    "confirm": "Bestätigen",
    "cancel": "Absagen",
    "Search": "Suche",
    "dateDelete": "Löschdatum:",
    "active": "Aktiv",
    "delete": "ENTFERNT",
    "services": "Dienstleistungen",
    "status": "Status",
    "recover": "Wiederherstellen",
    "restored": "restauriert",
    "restore": "Erholung",
    "DoYouWantRestore": "Möchten Sie die Kampagne wiederherstellen?",
    "deletedCompamies": "FERNUNTERNEHMEN",
    "CompanyNotSearch": "Es wurden keine Unternehmen gefunden, die Ihren Kriterien entsprechen",
    "writeCorrectlyName": "Geben Sie den Benutzernamen korrekt ein",
    "addlogo": "Logo hinzufügen",
    "Logo": "Logo",
    "nipmg": "Klicken oder ziehen Sie eine Datei in dieses Fenster",
    "ErrorDownload": "Ladefehler",
    "preview": "Vorschau",
    "onlyimg": "Nur Bilder",
    "imgisbig": "Das Bild überschreitet die Größenbeschränkung",
    "minimgsize": "Minimale Bildgröße",
    "Zagruzka...": "Wird geladen...",
    "Youbrowserisold": "Ihr Browser wird nicht unterstützt, bitte verwenden Sie IE10+ oder andere Browser",
    "failZagruzhen": "Datei erfolgreich hochgeladen!",
    "zakrit": "nah dran",
    "nazad": "zurück",
    "save": "Speichern",
    "zagruzit_foto": "Lade ein Foto hoch",
    "clear": "Klar",
    "logoIsAdd": "Logo hinzugefügt",
    "logoIsDel": "Logo gelöscht",
    "editComp": "Unternehmen bearbeiten",
    "giveComp": "Transfergesellschaft",
    "thisUserNorRegistered": "Dieser Benutzer ist nicht registriert",
    "cantTransferToYou": "Sie können Ihr Unternehmen nicht auf sich selbst übertragen",
    "youNotOwner": "Sie sind nicht der Eigentümer des Unternehmens",
    "Ok": "OK",
    "notYetCreateComp": "Die Gründung neuer Unternehmen steht derzeit nicht zur Verfügung",
    "EnterToCompany": "Melden Sie sich beim Unternehmen an",
    "startDescription": "Willkommen in Ihrem persönlichen Konto. Melden Sie sich beim Unternehmen an, um loszulegen",
    "inviteComp": "Einladung ins Unternehmen",
    "visitDemo": "Demo-Unternehmen besuchen",
    "makeUrChoice": "Triff eine Entscheidung",
    "ImWorker": "Ich bin Angestellter",
    "ImOwner": "Ich bin Arbeitgeber, Arbeitgebervertreter",
    "needInviteComp": "Sie müssen eine Einladung zu einem bestehenden Unternehmen annehmen",
    "needCreateComp": "Sie müssen ein Unternehmen gründen, um loszulegen",
    "daysLeft": "Tage übrig",
    "cannotDel": "Die Deinstallation ist derzeit nicht verfügbar",
    "NoCompanyCreateText": "Um mit der Kasse arbeiten zu können, müssen Sie eine Einladung des Arbeitgebers annehmen.",
    "withoutName": "Namenlos",
    "cropImageHint": "Klicken oder ziehen Sie eine hochzuladende Datei",
    "cropImageNoSupported": "Browser wird nicht unterstützt, bitte verwenden Sie IE10+ oder andere Browser.",
    "cropImageSuccess": "Download erfolgreich",
    "cropImageFail": "Herunterladen fehlgeschlagen",
    "cropImageOnlyImgNeed": "Nur Bilder",
    "cropImageOutOfSize": "Die Bildgröße überschreitet das Limit",
    "cropImageLowestPx": "Minimale Bildgröße",
    "uReachedLimitComp": "Sie haben das Limit für die Anzahl der erstellten Unternehmen erreicht",
    "accAlreadyRegister": "Dieses Konto ist bereits mit einem anderen Konto verknüpft",
    "accNotRegister": "Ein solches Konto existiert nicht",
    "areYourCountry": "Ist das dein Land?",
    "enterCorrectNumber": "Nummer im internationalen Format eingeben",
    "attach": "binden",
    "UserEmailAlreadyExists": "Ein Benutzer existiert bereits mit dieser E-Mail-Adresse",
    "AccountAlreadyLinked": "Konto bereits verknüpft",
    "facebookAccIsAlreadyRegistered": "Dieses Facebook-Konto ist bereits mit einem anderen Skyservice-Konto verknüpft",
    "googleAccIsAlreadyRegistered": "Dieses Google-Konto ist bereits mit einem anderen Skyservice-Konto verknüpft",
    "telegramAccAlreadyRegister": "Dieses Telegramm ist bereits mit einem anderen Skyservice-Konto verknüpft",
    "companyBlocked": "Dieses Unternehmen ist inaktiv",
    "allSessions": "Alle Sitzungen anzeigen",
    "activeSessions": "Aktive Sitzungen",
    "exitAllDevices": "Melden Sie sich auf allen anderen Geräten ab",
    "currentSession": "Aktuelle Sitzung",
    "autoCloseSessions": "Automatische Vervollständigung der Sitzung",
    "nonActivePeriod": "Automatisch beenden, wenn die Sitzung nicht aktiv ist",
    "oneWeek": "1 Woche",
    "oneMonth": "1 Monat",
    "threeMonth": "3 Monate",
    "sixMonth": "6 Monate",
    "terminate": "Vollständig",
    "sessionDesc1": "Hier werden alle Geräte aufgelistet, die bei Ihrem Skyservice-Konto angemeldet sind. Sie können sich bei jedem einzeln oder bei allen gleichzeitig von Ihrem Konto abmelden.",
    "sessionDesc2": "Wenn Sie ein unbekanntes Gerät in der Liste finden, melden Sie sich sofort auf diesem Gerät ab und ändern Sie das Passwort für Ihr Skyservice-Konto.",
    "sureToTerminate": "Möchten Sie wirklich alle aktiven Sitzungen beenden?",
    "ip": "IP Adresse",
    "geoPos": "Geoposition",
    "info": "Information",
    "sessionDesc3": "Sitzungen ohne Aktivität während dieses Zeitraums werden automatisch beendet.",
    "terminatesSessions": "Sitzungen beenden",
    "more3monthAgo": "Vor über drei Monaten",
    "noData": "Keine Daten",
    "goSettingsCompany": "Wir gründen Ihr Unternehmen, geben Sie uns eine Minute...",
    "preparingCompany1": "Wir gründen Ihr Unternehmen, bitte geben Sie uns eine Minute ...",
    "preparingCompany2": "Wir bereiten eine Schnittstelle zur Verwaltung von Waren und Dienstleistungen vor...",
    "preparingCompany3": "Wir arbeiten an einer komfortablen Finanzbuchhaltung...",
    "preparingCompany4": "Wir nehmen die letzten Einstellungen vor, fast fertig...",
    "appleAccIsAlreadyRegistered": "Diese AppleId ist bereits mit einem anderen Skyservice-Konto verknüpft",
    "notGetDataFromApple": "Es konnten keine Daten von Apple empfangen werden"
}

export default function() {
    return lang;
}