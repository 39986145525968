const lang = {
    "ToAccept": "接受",
    "Reject": "拒绝",
    "Password": "密码",
    "RepeatPassword": "重复输入密码",
    "OldPassword": "旧密码",
    "MyCompany": "我的公司",
    "Company": "公司",
    "CreateNewCompany": "创建您的公司",
    "Сancel": "取消",
    "Save": "救",
    "GeneralInformation": "一般信息",
    "CompanyName": "公司名",
    "LogOut": "注销",
    "SignOutOfAccount": "登出",
    "ChangePassword": "更改密码",
    "AddPassword": "添加密码",
    "Language": "语言",
    "ConfirmThisMail": "您的邮箱已确认",
    "Invites": "邀请函",
    "AcceptInvitation": "接受邀请",
    "EMailOrPhone": "电话或邮件",
    "Saved": "已保存",
    "Error": "错误",
    "FillInAllTheFields": "填写所有字段",
    "FirstCreateAnAccountPassword": "一、创建账号密码",
    "Settings": "设置",
    "Next": "进一步",
    "MinPassLength": "密码太短",
    "action": "行动",
    "NewOwner": "新主人",
    "UserMustBeRegistered": "用户必须注册",
    "LeaveTheCompany": "离开公司",
    "Confirm": "确认",
    "WrongFormat": "格式错误",
    "DeleteAccount": "删除帐户",
    "WrongPassword": "密码错误",
    "PhoneAlreadyRegistered": "电话已注册",
    "EmailAlreadyRegistered": "邮件已经注册",
    "Yes": "是的",
    "CreateACompany": "创建公司",
    "MyReferrals": "我的推荐人",
    "Edit": "改变",
    "NewEmail": "新邮件",
    "Proceed": "继续",
    "IncorrectCaptcha": "错误验证码",
    "CaptchaIsNotValid": "验证码不再有效",
    "EnterCaptcha": "输入验证码",
    "EnterSMS": "输入短信",
    "SMSCodeIsNoLongerValid": "短信代码不再有效",
    "SMSCodeIsIncorrect": "短信验证码不正确",
    "MailNotVerifiedLinkTimedOut": "邮件未确认，链接已过期",
    "ReferralLink": "推荐链接",
    "Date": "日期",
    "EarnWithUs": "与我们一起赚钱",
    "RecommendSkyservicePOSToYourFriendsAndGetRealMoneyToYourAccount.": "向您的朋友推荐 Skyservice POS 并在您的帐户中获得真钱。",
    "HowItWorks": "怎么运行的？",
    "ItsVerySimpleYouBringCustomersToSkyservicePOSAndWeShareTheirPaymentsWithYou.": "非常简单：您将客户带到 Skyservice POS，我们与您分享他们的付款。",
    "Copied": "已复制",
    "TheMinimumWithdrawalAmountIs100USD": "*最低提款金额 100 美元",
    "AddEmail": "添加邮件",
    "YouDontHaveCompanyYet": "你还没有公司",
    "NoCompanyText": "要开始使用收银机，您需要创建公司或接受雇主的邀请。",
    "ConfirmEmail": "已发送电子邮件以确认更改。",
    "description": "描述",
    "NoDescriptionSpecified": "没有说明",
    "DateCreate": "创建日期：",
    "DelComp": "删除公司",
    "Help": "支持",
    "Email": "邮件",
    "Phone": "电话",
    "SureAccDel": "您确定要取消关联您的帐户吗？",
    "no": "不是",
    "yes": "是的",
    "confirm": "确认",
    "cancel": "取消",
    "Search": "搜索",
    "dateDelete": "删除日期：",
    "active": "积极的",
    "delete": "删除",
    "services": "服务",
    "status": "地位",
    "recover": "重建",
    "restored": "恢复",
    "restore": "恢复",
    "DoYouWantRestore": "是否要恢复活动",
    "deletedCompamies": "远程公司",
    "CompanyNotSearch": "找不到符合您条件的公司",
    "writeCorrectlyName": "正确输入用户名",
    "addlogo": "添加标志",
    "Logo": "商标",
    "nipmg": "单击或拖动文件到此窗口",
    "ErrorDownload": "加载错误",
    "preview": "预习",
    "onlyimg": "仅图像",
    "imgisbig": "图片超出大小限制",
    "minimgsize": "最小图像尺寸",
    "Zagruzka...": "加载中...",
    "Youbrowserisold": "不支持您的浏览器，请使用IE10+或其他浏览器",
    "failZagruzhen": "文件上传成功！",
    "zakrit": "关闭",
    "nazad": "后退",
    "save": "救",
    "zagruzit_foto": "上传一张照片",
    "clear": "清除",
    "logoIsAdd": "添加徽标",
    "logoIsDel": "徽标已清除",
    "editComp": "编辑公司",
    "giveComp": "中转公司",
    "thisUserNorRegistered": "该用户未注册",
    "cantTransferToYou": "您不能将公司转让给自己",
    "youNotOwner": "您不是公司的所有者",
    "Ok": "好的",
    "notYetCreateComp": "目前，无法创建新公司",
    "EnterToCompany": "登录公司",
    "startDescription": "欢迎使用您的个人账户，登录公司即可开始",
    "inviteComp": "公司邀请函",
    "visitDemo": "参观演示公司",
    "makeUrChoice": "取舍",
    "ImWorker": "我是员工",
    "ImOwner": "我是雇主，雇主代表",
    "needInviteComp": "您必须接受现有公司的邀请",
    "needCreateComp": "您需要创建一家公司才能开始",
    "daysLeft": "剩余天数",
    "cannotDel": "目前无法卸载",
    "NoCompanyCreateText": "要开始使用收银机，您必须接受雇主的邀请。",
    "withoutName": "无名",
    "cropImageHint": "点击或拖动文件上传",
    "cropImageNoSupported": "不支持浏览器，请使用IE10+或其他浏览器。",
    "cropImageSuccess": "下载成功",
    "cropImageFail": "下载失败",
    "cropImageOnlyImgNeed": "仅图像",
    "cropImageOutOfSize": "图片大小超出限制",
    "cropImageLowestPx": "最小图像尺寸",
    "uReachedLimitComp": "您已达到创建公司数量的限制",
    "accAlreadyRegister": "此帐户已链接到另一个帐户",
    "accNotRegister": "不存在这样的帐户",
    "areYourCountry": "这是你的国家吗？",
    "enterCorrectNumber": "以国际格式输入号码",
    "attach": "绑定",
    "UserEmailAlreadyExists": "使用此电子邮件的用户已存在",
    "AccountAlreadyLinked": "帐户已关联",
    "facebookAccIsAlreadyRegistered": "此 Facebook 帐户已链接到另一个 Skyservice 帐户",
    "googleAccIsAlreadyRegistered": "此 Google 帐户已链接到另一个 Skyservice 帐户",
    "telegramAccAlreadyRegister": "此 Telegram 已链接到另一个 Skyservice 帐户",
    "companyBlocked": "这家公司不活跃",
    "allSessions": "显示所有会话",
    "activeSessions": "活跃期",
    "exitAllDevices": "在所有其他设备上注销",
    "currentSession": "当前会话",
    "autoCloseSessions": "会话自动完成",
    "nonActivePeriod": "如果会话不活动则自动结束",
    "oneWeek": "1周",
    "oneMonth": "1个月",
    "threeMonth": "3个月",
    "sixMonth": "6个月",
    "terminate": "完全的",
    "sessionDesc1": "此处列出了登录到您的 Skyservice 帐户的所有设备。您可以单独或一次全部注销您的帐户。",
    "sessionDesc2": "如果您在列表中发现不熟悉的设备，请立即在此设备上注销并更改您的 Skyservice 帐户的密码。",
    "sureToTerminate": "您确定要结束所有活动会话吗？",
    "ip": "IP地址",
    "geoPos": "地理位置",
    "info": "信息",
    "sessionDesc3": "在此期间没有任何活动的会话将自动结束。",
    "terminatesSessions": "结束会议",
    "more3monthAgo": "三个多月前",
    "noData": "没有数据",
    "goSettingsCompany": "我们正在创建您的公司，请给我们一点时间......",
    "preparingCompany1": "我们正在创建您的公司，请给我们一点时间......",
    "preparingCompany2": "我们正在准备一个用于管理商品和服务的界面......",
    "preparingCompany3": "我们正在致力于便捷的财务会计...",
    "preparingCompany4": "我们正在进行最后的设置，几乎准备就绪......",
    "appleAccIsAlreadyRegistered": "此 AppleId 已链接到另一个 Skyservice 帐户",
    "notGetDataFromApple": "无法从 Apple 接收数据"
}

export default function() {
    return lang;
}