const lang = {
    "ToAccept": "Sutikti",
    "Reject": "Atmesti",
    "Password": "Slaptažodis",
    "RepeatPassword": "Pakartokite slaptažodį",
    "OldPassword": "senas slaptažodis",
    "MyCompany": "Mano įmonės",
    "Company": "Bendrovė",
    "CreateNewCompany": "Sukurkite savo įmonę",
    "Сancel": "Atšaukti",
    "Save": "Sutaupyti",
    "GeneralInformation": "Bendra informacija",
    "CompanyName": "Įmonės pavadinimas",
    "LogOut": "Atsijungti",
    "SignOutOfAccount": "Atsijungti",
    "ChangePassword": "Pakeisti slaptažodį",
    "AddPassword": "Pridėti slaptažodį",
    "Language": "Kalba",
    "ConfirmThisMail": "Jūsų el. paštas patvirtintas",
    "Invites": "kvietimus",
    "AcceptInvitation": "Priimti pakvietimą",
    "EMailOrPhone": "Telefonu arba paštu",
    "Saved": "Išsaugota",
    "Error": "Klaida",
    "FillInAllTheFields": "Užpildykite visus laukus",
    "FirstCreateAnAccountPassword": "Pirmiausia sukurkite paskyros slaptažodį",
    "Settings": "Nustatymai",
    "Next": "Toliau",
    "MinPassLength": "Slaptažodis per trumpas",
    "action": "Veiksmas",
    "NewOwner": "Naujas savininkas",
    "UserMustBeRegistered": "Vartotojas turi būti registruotas",
    "LeaveTheCompany": "Palikite įmonę",
    "Confirm": "Patvirtinti",
    "WrongFormat": "Blogas formatas",
    "DeleteAccount": "Ištrinti paskyrą",
    "WrongPassword": "Neteisingas slaptažodis",
    "PhoneAlreadyRegistered": "Telefonas jau registruotas",
    "EmailAlreadyRegistered": "Paštas jau užregistruotas",
    "Yes": "Taip",
    "CreateACompany": "Sukurti įmonę",
    "MyReferrals": "Mano siuntimai",
    "Edit": "Keisti",
    "NewEmail": "Naujas paštas",
    "Proceed": "Tęsti",
    "IncorrectCaptcha": "Neteisingas CAPTCHA kodas",
    "CaptchaIsNotValid": "„Captcha“ nebegalioja",
    "EnterCaptcha": "Įveskite captcha",
    "EnterSMS": "Įveskite SMS",
    "SMSCodeIsNoLongerValid": "SMS kodas nebegalioja",
    "SMSCodeIsIncorrect": "SMS kodas neteisingas",
    "MailNotVerifiedLinkTimedOut": "Paštas nepatvirtintas, nuoroda nebegalioja",
    "ReferralLink": "Siuntimo nuoroda",
    "Date": "data",
    "EarnWithUs": "Uždirbkite su mumis",
    "RecommendSkyservicePOSToYourFriendsAndGetRealMoneyToYourAccount.": "Rekomenduokite „Skyservice POS“ savo draugams ir gaukite tikrų pinigų į savo sąskaitą.",
    "HowItWorks": "Kaip tai veikia?",
    "ItsVerySimpleYouBringCustomersToSkyservicePOSAndWeShareTheirPaymentsWithYou.": "Tai labai paprasta: jūs pritraukiate klientus į „Skyservice POS“, o mes dalinamės su jumis jų mokėjimais.",
    "Copied": "Nukopijuota",
    "TheMinimumWithdrawalAmountIs100USD": "*Minimali išėmimo suma 100 USD",
    "AddEmail": "Pridėti laišką",
    "YouDontHaveCompanyYet": "Dar neturite įmonės",
    "NoCompanyText": "Norint pradėti dirbti su kasos aparatu, reikia sukurti įmonę arba priimti darbdavio kvietimą.",
    "ConfirmEmail": "Pakeitimui patvirtinti išsiųstas el. laiškas.",
    "description": "apibūdinimas",
    "NoDescriptionSpecified": "Nėra aprašymo",
    "DateCreate": "Sukūrimo data:",
    "DelComp": "Ištrinti įmonę",
    "Help": "Palaikymas",
    "Email": "Paštas",
    "Phone": "Telefonas",
    "SureAccDel": "Ar tikrai norite atsieti paskyrą?",
    "no": "Ne",
    "yes": "Taip",
    "confirm": "Patvirtinti",
    "cancel": "Atšaukti",
    "Search": "Paieška",
    "dateDelete": "Ištrynimo data:",
    "active": "Aktyvus",
    "delete": "Pašalinta",
    "services": "Paslaugos",
    "status": "Būsena",
    "recover": "Atkurti",
    "restored": "atkurta",
    "restore": "Atsigavimas",
    "DoYouWantRestore": "Ar norite atkurti kampaniją",
    "deletedCompamies": "NUOTOLINĖS ĮMONĖS",
    "CompanyNotSearch": "Nerasta įmonių, atitinkančių jūsų kriterijus",
    "writeCorrectlyName": "Teisingai įveskite vartotojo vardą",
    "addlogo": "Pridėti logotipą",
    "Logo": "Logotipas",
    "nipmg": "Spustelėkite arba vilkite failą į šį langą",
    "ErrorDownload": "įkėlimo klaida",
    "preview": "peržiūra",
    "onlyimg": "Tik vaizdai",
    "imgisbig": "Vaizdas viršija dydžio limitą",
    "minimgsize": "Minimalus vaizdo dydis",
    "Zagruzka...": "Įkeliama...",
    "Youbrowserisold": "Jūsų naršyklė nepalaikoma, naudokite IE10+ arba kitas naršykles",
    "failZagruzhen": "Failas sėkmingai įkeltas!",
    "zakrit": "Uždaryti",
    "nazad": "Atgal",
    "save": "Sutaupyti",
    "zagruzit_foto": "Įkelti nuotrauką",
    "clear": "Aišku",
    "logoIsAdd": "Pridėtas logotipas",
    "logoIsDel": "Logotipas išvalytas",
    "editComp": "Redaguoti įmonę",
    "giveComp": "Perkėlimo įmonė",
    "thisUserNorRegistered": "Šis vartotojas neregistruotas",
    "cantTransferToYou": "Negalite perleisti savo įmonės sau",
    "youNotOwner": "Jūs nesate įmonės savininkas",
    "Ok": "Gerai",
    "notYetCreateComp": "Šiuo metu naujų įmonių steigimas negalimas",
    "EnterToCompany": "Prisijunkite prie įmonės",
    "startDescription": "Sveiki atvykę į savo asmeninę paskyrą, prisijunkite prie įmonės, kad pradėtumėte",
    "inviteComp": "Kvietimas į įmonę",
    "visitDemo": "Apsilankykite demonstracinėje įmonėje",
    "makeUrChoice": "Pasirinkti",
    "ImWorker": "Aš esu darbuotojas",
    "ImOwner": "Esu darbdavys, darbdavio atstovas",
    "needInviteComp": "Turite priimti pakvietimą į esamą įmonę",
    "needCreateComp": "Norėdami pradėti, turite sukurti įmonę",
    "daysLeft": "Liko dienų",
    "cannotDel": "Pašalinti šiuo metu negalima",
    "NoCompanyCreateText": "Norėdami pradėti dirbti su kasos aparatu, turite priimti darbdavio kvietimą.",
    "withoutName": "Be vardo",
    "cropImageHint": "Spustelėkite arba vilkite failą, kurį norite įkelti",
    "cropImageNoSupported": "Naršyklė nepalaikoma, naudokite IE10+ arba kitas naršykles.",
    "cropImageSuccess": "Atsisiuntimas sėkmingas",
    "cropImageFail": "Atsisiuntimas nepavyko",
    "cropImageOnlyImgNeed": "Tik vaizdai",
    "cropImageOutOfSize": "Vaizdo dydis viršija limitą",
    "cropImageLowestPx": "Minimalus vaizdo dydis",
    "uReachedLimitComp": "Pasiekėte sukurtų įmonių skaičiaus limitą",
    "accAlreadyRegister": "Ši paskyra jau susieta su kita paskyra",
    "accNotRegister": "Tokios paskyros nėra",
    "areYourCountry": "ar čia tavo šalis?",
    "enterCorrectNumber": "Įveskite numerį tarptautiniu formatu",
    "attach": "įpareigoti",
    "UserEmailAlreadyExists": "Vartotojas su šiuo el. pašto adresu jau egzistuoja",
    "AccountAlreadyLinked": "Paskyra jau susieta",
    "facebookAccIsAlreadyRegistered": "Ši „Facebook“ paskyra jau susieta su kita „Skyservice“ paskyra",
    "googleAccIsAlreadyRegistered": "Ši „Google“ paskyra jau susieta su kita „Skyservice“ paskyra",
    "telegramAccAlreadyRegister": "Ši telegrama jau susieta su kita „Skyservice“ paskyra",
    "companyBlocked": "Ši įmonė neaktyvi",
    "allSessions": "Rodyti visas sesijas",
    "activeSessions": "Aktyvūs užsiėmimai",
    "exitAllDevices": "Atsijunkite visuose kituose įrenginiuose",
    "currentSession": "Dabartinė sesija",
    "autoCloseSessions": "Automatinis seanso užbaigimas",
    "nonActivePeriod": "Automatiškai baigti, jei sesija neaktyvi",
    "oneWeek": "1 savaitė",
    "oneMonth": "1 mėnuo",
    "threeMonth": "3 mėnesiai",
    "sixMonth": "6 mėnesiai",
    "terminate": "Užbaigti",
    "sessionDesc1": "Čia pateikiami visi įrenginiai, kurie yra prisijungę prie jūsų „Skyservice“ paskyros. Galite atsijungti nuo savo paskyros naudodami kiekvieną iš jų atskirai arba prie visų iš karto.",
    "sessionDesc2": "Jei sąraše radote nepažįstamą įrenginį, nedelsdami atsijunkite nuo šio įrenginio ir pakeiskite savo Skyservice paskyros slaptažodį.",
    "sureToTerminate": "Ar tikrai norite baigti visas aktyvias sesijas?",
    "ip": "IP adresas",
    "geoPos": "Geopozicija",
    "info": "Informacija",
    "sessionDesc3": "Seansai, kurie šiuo laikotarpiu nevykdo jokios veiklos, baigsis automatiškai.",
    "terminatesSessions": "Sesijų pabaiga",
    "more3monthAgo": "Daugiau nei prieš tris mėnesius",
    "noData": "Nėra duomenų",
    "goSettingsCompany": "Kuriame Jūsų įmonę, skirkite minutėlę...",
    "preparingCompany1": "Kuriame Jūsų įmonę, skirkite minutėlę...",
    "preparingCompany2": "Ruošiame prekių ir paslaugų valdymo sąsają...",
    "preparingCompany3": "Dirbame su patogia finansine apskaita...",
    "preparingCompany4": "Atliekame galutinius nustatymus, beveik pasiruošę...",
    "appleAccIsAlreadyRegistered": "Šis AppleId jau susietas su kita Skyservice paskyra",
    "notGetDataFromApple": "Nepavyko gauti duomenų iš „Apple“."
}

export default function() {
    return lang;
}