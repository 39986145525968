import Vue from 'vue'
import App from './App.vue'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVueIcons)

import "icomoon/icomoon.css";

import crisp from '@/crisp.js';
import VueCryptojs from 'vue-cryptojs'
import VueRouter from 'vue-router'
import Person from './components/PersonPage/Person.vue';
import WrongPage from './components/WrongPage.vue';

import { webviewCheck } from '@/components/js/webviewCheck.js';

import { store } from './store.js';

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

import Notifications from 'vue-notification';
Vue.use(Notifications);

//Шина событий
import "@/components/js/keyevent.js";

export const keyevent = new Vue();
export const handleResize = new Vue();
export const scroll = new Vue();

// if (process.env.NODE_ENV == 'production') {
//   // убираем  логи консоли на проде
//   console.log = function(){};
//   console.group = function(){};
//   console.warn = function(){};
// }

Vue.prototype.$store = store;

// Инициилизируем крисп чат с настройками аккаунта
if(webviewCheck() !== "android_webview"){ // не запускаем на андроидах, там свой СДК
  window.$crisp = crisp();
}
// отримуємо версію додатку ios
if(webviewCheck() == 'ios_webview'){
  window.webkit.messageHandlers.getAppVersion.postMessage('');
}
window.ioshttpsender = {
  // Отримання і встановлення версії додатку IOS
  setAppVersion(appData) {
    let data = JSON.parse(appData);
    const {osVersion, deviceModel, appVersion} = data;
    store.appInfoData = {
      os: osVersion,
      appVersion: appVersion,
      device: deviceModel
    }
  }
}


//Чат CRISP
window.CRISP_WEBSITE_ID = "69e2a69a-8103-446b-8e09-e9dfb294422c";

//Фиксим баг сдвигания экрана на ios после закрытия клавиатуры
window.app = {}
window.app.main = {}
window.app.main.closekeyboard = function() {
  window.scrollTo(0, 0);
}

window.say = {};
window.say.message = function(text) {
    Vue.notify({
        group: 'foo',
        text: `<b style="font-size: 1.2rem;"> ${ text } </b>`,
        type: 'success',
    });
}

window.say.messageError = function(text) {
    Vue.notify({
        group: 'foo',
        text: `<b style="font-size: 1.2rem;"> ${ text } </b>`,
        type: 'error'
    });
}

window.say.messageWarn = function(text) {
  Vue.notify({
      group: 'foo',
      text: `<b style="font-size: 1.2rem;"> ${ text } </b>`,
      type: 'warn'
  });
}

const routes = [
  { path: `/`, component: Person },//login
  { path: '/**', component: WrongPage }
]
const router = new VueRouter({
  routes,
  mode: 'history',
})

Vue.use(VueRouter)
Vue.use(VueCryptojs)
Vue.use(BootstrapVue)


Vue.config.productionTip = false;

// Получаем стартовые данные, язык, странгу и тогда отрисовываем приложение
try {
  let language = "en";
  let changedLang = localStorage.getItem('lang_user');
  
  if(changedLang){
      language = changedLang;
  }else{
      // берем язык из устройства
      language = window.navigator ? (window.navigator.language || window.navigator.systemLanguage || window.navigator.userLanguage) : "en";     
  }

  store.lang = language.length > 2 ? language.substr(0, 2) : language;
  window.lang = store.langs[store.lang]();
} catch(err) {
  window.lang = store.langs['en']();
  store.lang = "en"
}

window.CRISP_RUNTIME_CONFIG = {
  locale: store.lang
};

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

