const lang = {
    "ToAccept": "ដើម្បីទទួលយក",
    "Reject": "បដិសេធ",
    "Password": "ពាក្យសម្ងាត់",
    "RepeatPassword": "ពាក្យសម្ងាត់ម្តងទៀត",
    "OldPassword": "លេខសំងាត់​ចាស់",
    "MyCompany": "ក្រុមហ៊ុនរបស់ខ្ញុំ",
    "Company": "ក្រុមហ៊ុន",
    "CreateNewCompany": "បង្កើតក្រុមហ៊ុនរបស់អ្នក។",
    "Сancel": "បោះបង់",
    "Save": "រក្សាទុក",
    "GeneralInformation": "ព័ត៌មាន​ទូទៅ",
    "CompanyName": "ឈ្មោះ​ក្រុម​ហ៊ុន",
    "LogOut": "ចាកចេញ",
    "SignOutOfAccount": "ចាកចេញ",
    "ChangePassword": "ផ្លាស់ប្តូរពាក្យសម្ងាត់",
    "AddPassword": "បន្ថែមពាក្យសម្ងាត់",
    "Language": "ភាសា",
    "ConfirmThisMail": "អ៊ីមែលរបស់អ្នកត្រូវបានបញ្ជាក់",
    "Invites": "ការអញ្ជើញ",
    "AcceptInvitation": "ទទួលយកការអញ្ជើញ",
    "EMailOrPhone": "ទូរស័ព្ទឬសំបុត្រ",
    "Saved": "បានរក្សាទុក",
    "Error": "កំហុស",
    "FillInAllTheFields": "បំពេញវាលទាំងអស់។",
    "FirstCreateAnAccountPassword": "ដំបូងបង្កើតពាក្យសម្ងាត់គណនី",
    "Settings": "ការកំណត់",
    "Next": "បន្ថែមទៀត",
    "MinPassLength": "ពាក្យសម្ងាត់ខ្លីពេក",
    "action": "សកម្មភាព",
    "NewOwner": "ម្ចាស់ថ្មី។",
    "UserMustBeRegistered": "អ្នកប្រើប្រាស់ត្រូវតែចុះឈ្មោះ",
    "LeaveTheCompany": "ចាកចេញពីក្រុមហ៊ុន",
    "Confirm": "បញ្ជាក់",
    "WrongFormat": "ខុស​ទ្រង់ទ្រាយ",
    "DeleteAccount": "លុប​គណនី",
    "WrongPassword": "លេខសំងាត់​ខុស",
    "PhoneAlreadyRegistered": "ទូរស័ព្ទបានចុះឈ្មោះរួចហើយ",
    "EmailAlreadyRegistered": "សំបុត្របានចុះឈ្មោះរួចហើយ",
    "Yes": "បាទ",
    "CreateACompany": "បង្កើតក្រុមហ៊ុន",
    "MyReferrals": "ការណែនាំរបស់ខ្ញុំ",
    "Edit": "ផ្លាស់ប្តូរ",
    "NewEmail": "សំបុត្រថ្មី។",
    "Proceed": "បន្ត",
    "IncorrectCaptcha": "captcha ខុស",
    "CaptchaIsNotValid": "Captcha លែងមានសុពលភាពទៀតហើយ",
    "EnterCaptcha": "បញ្ចូល captcha",
    "EnterSMS": "បញ្ចូល SMS",
    "SMSCodeIsNoLongerValid": "លេខកូដ SMS លែងមានសុពលភាពទៀតហើយ",
    "SMSCodeIsIncorrect": "លេខកូដ SMS មិនត្រឹមត្រូវទេ។",
    "MailNotVerifiedLinkTimedOut": "សំបុត្រមិនត្រូវបានបញ្ជាក់ទេ តំណផុតកំណត់",
    "ReferralLink": "តំណយោង",
    "Date": "កាលបរិច្ឆេទនៃ",
    "EarnWithUs": "រកប្រាក់ជាមួយយើង",
    "RecommendSkyservicePOSToYourFriendsAndGetRealMoneyToYourAccount.": "សូមណែនាំ Skyservice POS ទៅកាន់មិត្តភ័ក្តិរបស់អ្នក ហើយទទួលបានប្រាក់ពិតប្រាកដនៅក្នុងគណនីរបស់អ្នក។",
    "HowItWorks": "តើវាដំណើរការយ៉ាងដូចម្តេច?",
    "ItsVerySimpleYouBringCustomersToSkyservicePOSAndWeShareTheirPaymentsWithYou.": "វាសាមញ្ញណាស់៖ អ្នកនាំអតិថិជនទៅកាន់ Skyservice POS ហើយយើងចែករំលែកការទូទាត់របស់ពួកគេជាមួយអ្នក។",
    "Copied": "ចម្លង",
    "TheMinimumWithdrawalAmountIs100USD": "* ចំនួនដកប្រាក់អប្បបរមា 100 USD",
    "AddEmail": "បន្ថែមសំបុត្រ",
    "YouDontHaveCompanyYet": "អ្នកមិនទាន់មានក្រុមហ៊ុនទេ។",
    "NoCompanyText": "ដើម្បីចាប់ផ្តើមធ្វើការជាមួយការចុះឈ្មោះសាច់ប្រាក់ អ្នកត្រូវបង្កើតក្រុមហ៊ុន ឬទទួលយកការអញ្ជើញពីនិយោជក។",
    "ConfirmEmail": "អ៊ីមែលត្រូវបានផ្ញើដើម្បីបញ្ជាក់ការផ្លាស់ប្តូរ។",
    "description": "ការពិពណ៌នា",
    "NoDescriptionSpecified": "គ្មានការពិពណ៌នាទេ។",
    "DateCreate": "កាលបរិច្ឆេទនៃការបង្កើត៖",
    "DelComp": "លុបក្រុមហ៊ុន",
    "Help": "គាំទ្រ",
    "Email": "សំបុត្រ",
    "Phone": "ទូរស័ព្ទ",
    "SureAccDel": "តើអ្នកប្រាកដថាចង់ផ្តាច់គណនីរបស់អ្នកមែនទេ?",
    "no": "ទេ។",
    "yes": "បាទ",
    "confirm": "បញ្ជាក់",
    "cancel": "បោះបង់",
    "Search": "ស្វែងរក",
    "dateDelete": "កាលបរិច្ឆេទ​លុប៖",
    "active": "សកម្ម",
    "delete": "បានដកចេញ",
    "services": "សេវាកម្ម",
    "status": "ស្ថានភាព",
    "recover": "បង្កើតឡើងវិញ",
    "restored": "បានស្ដារឡើងវិញ",
    "restore": "ការងើបឡើងវិញ",
    "DoYouWantRestore": "តើអ្នកចង់ស្ដារយុទ្ធនាការ",
    "deletedCompamies": "ក្រុមហ៊ុនពីចម្ងាយ",
    "CompanyNotSearch": "រកមិនឃើញក្រុមហ៊ុនដែលត្រូវនឹងលក្ខណៈវិនិច្ឆ័យរបស់អ្នកទេ។",
    "writeCorrectlyName": "បញ្ចូលឈ្មោះអ្នកប្រើប្រាស់ឱ្យបានត្រឹមត្រូវ",
    "addlogo": "បន្ថែមនិមិត្តសញ្ញា",
    "Logo": "និមិត្តសញ្ញា",
    "nipmg": "ចុច ឬអូសឯកសារទៅក្នុងបង្អួចនេះ។",
    "ErrorDownload": "កំហុសក្នុងការផ្ទុក",
    "preview": "ការមើលជាមុន",
    "onlyimg": "រូបភាពតែប៉ុណ្ណោះ",
    "imgisbig": "រូបភាពលើសពីទំហំកំណត់",
    "minimgsize": "ទំហំរូបភាពអប្បបរមា",
    "Zagruzka...": "កំពុង​ផ្ទុក...",
    "Youbrowserisold": "កម្មវិធីរុករកតាមអ៊ីនធឺណិតរបស់អ្នកមិនត្រូវបានគាំទ្រទេ សូមប្រើ IE10+ ឬកម្មវិធីរុករកផ្សេងទៀត។",
    "failZagruzhen": "ឯកសារបានបង្ហោះដោយជោគជ័យ!",
    "zakrit": "ជិត",
    "nazad": "ត្រឡប់មកវិញ",
    "save": "រក្សាទុក",
    "zagruzit_foto": "បង្ហោះរូបថត",
    "clear": "ច្បាស់",
    "logoIsAdd": "បានបន្ថែមនិមិត្តសញ្ញា",
    "logoIsDel": "ឡូហ្គោត្រូវបានសម្អាត",
    "editComp": "កែសម្រួលក្រុមហ៊ុន",
    "giveComp": "ក្រុមហ៊ុនផ្ទេរប្រាក់",
    "thisUserNorRegistered": "អ្នកប្រើប្រាស់នេះមិនត្រូវបានចុះឈ្មោះទេ។",
    "cantTransferToYou": "អ្នកមិនអាចផ្ទេរក្រុមហ៊ុនរបស់អ្នកទៅខ្លួនអ្នកបានទេ។",
    "youNotOwner": "អ្នកមិនមែនជាម្ចាស់ក្រុមហ៊ុនទេ។",
    "Ok": "យល់ព្រម",
    "notYetCreateComp": "នៅពេលនេះ ការបង្កើតក្រុមហ៊ុនថ្មីមិនមានទេ។",
    "EnterToCompany": "ចូលក្រុមហ៊ុន",
    "startDescription": "សូមស្វាគមន៍មកកាន់គណនីផ្ទាល់ខ្លួនរបស់អ្នក សូមចូលទៅកាន់ក្រុមហ៊ុនដើម្បីចាប់ផ្តើម",
    "inviteComp": "ការអញ្ជើញទៅក្រុមហ៊ុន",
    "visitDemo": "ទស្សនាក្រុមហ៊ុនសាកល្បង",
    "makeUrChoice": "ធ្វើការជ្រើសរើស",
    "ImWorker": "ខ្ញុំជាបុគ្គលិក",
    "ImOwner": "ខ្ញុំជានិយោជក តំណាងនិយោជក",
    "needInviteComp": "អ្នកត្រូវតែទទួលយកការអញ្ជើញទៅកាន់ក្រុមហ៊ុនដែលមានស្រាប់",
    "needCreateComp": "អ្នកត្រូវបង្កើតក្រុមហ៊ុនដើម្បីចាប់ផ្តើម",
    "daysLeft": "ថ្ងៃ​ដែល​នៅសល់",
    "cannotDel": "ការ​លុប​មិន​អាច​ប្រើ​បាន​នា​ពេល​បច្ចុប្បន្ន",
    "NoCompanyCreateText": "ដើម្បីចាប់ផ្តើមធ្វើការជាមួយការចុះឈ្មោះសាច់ប្រាក់ អ្នកត្រូវតែទទួលយកការអញ្ជើញពីនិយោជក។",
    "withoutName": "គ្មាន​ឈ្មោះ",
    "cropImageHint": "ចុច ឬអូសឯកសារដើម្បីផ្ទុកឡើង",
    "cropImageNoSupported": "កម្មវិធីរុករកមិនត្រូវបានគាំទ្រទេ សូមប្រើ IE10+ ឬកម្មវិធីរុករកផ្សេងទៀត។",
    "cropImageSuccess": "ការទាញយកជោគជ័យ",
    "cropImageFail": "ការទាញយកបរាជ័យ",
    "cropImageOnlyImgNeed": "រូបភាពតែប៉ុណ្ណោះ",
    "cropImageOutOfSize": "ទំហំរូបភាពលើសពីដែនកំណត់",
    "cropImageLowestPx": "ទំហំរូបភាពអប្បបរមា",
    "uReachedLimitComp": "អ្នកបានឈានដល់ដែនកំណត់នៃចំនួនក្រុមហ៊ុនដែលបានបង្កើត",
    "accAlreadyRegister": "គណនីនេះត្រូវបានភ្ជាប់ទៅគណនីផ្សេងទៀតរួចហើយ",
    "accNotRegister": "មិនមានគណនីបែបនេះទេ។",
    "areYourCountry": "តើនេះជាប្រទេសរបស់អ្នកទេ?",
    "enterCorrectNumber": "បញ្ចូលលេខជាទម្រង់អន្តរជាតិ",
    "attach": "ចង",
    "UserEmailAlreadyExists": "អ្នកប្រើប្រាស់ដែលមានអ៊ីមែលនេះមានរួចហើយ",
    "AccountAlreadyLinked": "គណនីត្រូវបានភ្ជាប់រួចហើយ",
    "facebookAccIsAlreadyRegistered": "គណនី Facebook នេះត្រូវបានភ្ជាប់ទៅគណនី Skyservice មួយផ្សេងទៀតរួចហើយ",
    "googleAccIsAlreadyRegistered": "គណនី Google នេះត្រូវបានភ្ជាប់ទៅគណនី Skyservice មួយផ្សេងទៀតរួចហើយ",
    "telegramAccAlreadyRegister": "Telegram នេះត្រូវបានភ្ជាប់ទៅគណនី Skyservice មួយផ្សេងទៀតរួចហើយ",
    "companyBlocked": "ក្រុមហ៊ុននេះអសកម្ម",
    "allSessions": "បង្ហាញវគ្គទាំងអស់។",
    "activeSessions": "វគ្គសកម្ម",
    "exitAllDevices": "ចេញនៅលើឧបករណ៍ផ្សេងទៀតទាំងអស់។",
    "currentSession": "សម័យបច្ចុប្បន្ន",
    "autoCloseSessions": "វគ្គបញ្ចប់ដោយស្វ័យប្រវត្តិ",
    "nonActivePeriod": "បញ្ចប់ដោយស្វ័យប្រវត្តិ ប្រសិនបើវគ្គមិនសកម្ម",
    "oneWeek": "1 សប្តាហ៍",
    "oneMonth": "1 ខែ",
    "threeMonth": "3 ខែ",
    "sixMonth": "6 ខែ",
    "terminate": "បញ្ចប់",
    "sessionDesc1": "ឧបករណ៍ទាំងអស់ដែលបានចូលគណនី Skyservice របស់អ្នកត្រូវបានរាយបញ្ជីនៅទីនេះ។ អ្នកអាចចេញពីគណនីរបស់អ្នកនៅលើពួកវានីមួយៗជាលក្ខណៈបុគ្គល ឬទាំងអស់ក្នុងពេលតែមួយ។",
    "sessionDesc2": "ប្រសិនបើអ្នករកឃើញឧបករណ៍ដែលមិនស្គាល់នៅក្នុងបញ្ជី សូមចេញពីឧបករណ៍នេះភ្លាមៗ ហើយប្តូរពាក្យសម្ងាត់សម្រាប់គណនី Skyservice របស់អ្នក។",
    "sureToTerminate": "តើអ្នកប្រាកដថាចង់បញ្ចប់វគ្គសកម្មទាំងអស់មែនទេ?",
    "ip": "អាសយដ្ឋាន IP",
    "geoPos": "ទីតាំងភូមិសាស្ត្រ",
    "info": "ព័ត៌មាន",
    "sessionDesc3": "វគ្គដែលមិនមានសកម្មភាពណាមួយក្នុងអំឡុងពេលនេះនឹងបញ្ចប់ដោយស្វ័យប្រវត្តិ។",
    "terminatesSessions": "វគ្គបញ្ចប់",
    "more3monthAgo": "ជាងបីខែមុន។",
    "noData": "គ្មាន​ទិន្នន័យ",
    "goSettingsCompany": "យើងកំពុងបង្កើតក្រុមហ៊ុនរបស់អ្នក ទុកពេលមួយនាទី...",
    "preparingCompany1": "យើងកំពុងបង្កើតក្រុមហ៊ុនរបស់អ្នក សូមទុកពេលបន្តិច...",
    "preparingCompany2": "យើងកំពុងរៀបចំចំណុចប្រទាក់សម្រាប់គ្រប់គ្រងទំនិញ និងសេវាកម្ម...",
    "preparingCompany3": "យើងកំពុងធ្វើការលើគណនេយ្យហិរញ្ញវត្ថុងាយស្រួល...",
    "preparingCompany4": "យើងកំពុងធ្វើការកំណត់ចុងក្រោយ ជិតរួចរាល់ហើយ...",
    "appleAccIsAlreadyRegistered": "AppleId នេះត្រូវបានភ្ជាប់ទៅគណនី Skyservice មួយផ្សេងទៀតរួចហើយ",
    "notGetDataFromApple": "បរាជ័យក្នុងការទទួលទិន្នន័យពី Apple"
}

export default function() {
    return lang;
}