<template>
    <smallModal @escape="$emit('close')">
        <div class="leave-container">
            <span class="notify">
            {{ lang['cannotDel'] }}.
            </span>
            <span class="notify">
              {{ lang['daysLeft'] }} - {{days}}.
            </span>
            <hr>
            <div class="buttons">
                <button class="btn btn-secondary" @click="$emit('close')">{{ lang['Сancel'] }}</button>
            </div>
        </div>
    </smallModal>
</template>

<script>
import smallModal from '../modals/smallModal';

export default {
    props: ['days'],
    components: {
        smallModal
    },
    data() {
        return {}
    },
    computed: {
        lang() {
            return window.lang;
        }
    }
}
</script>

<style scoped>
    .notify {
      font-size: 20px;
      display: block;
      text-align: center;
    }
    .buttons{
        display: flex; 
        justify-content: center;
    }
    .btn-secondary{
        width: 100%;
    }
</style>