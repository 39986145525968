/* eslint-disable */
import { store } from "@/store";
import {uuid} from 'vue-uuid'
import {wsMessageHandler} from "@/socket/wsMessageHandler";
let socket;

let unknown = '-';

// browser
let nVer = navigator.appVersion;
let nAgt = navigator.userAgent;
let browser = navigator.appName;
let version = '' + parseFloat(navigator.appVersion);
let majorVersion = parseInt(navigator.appVersion, 10);
let nameOffset, verOffset, ix;
let appVersion = null;

// Opera
if ((verOffset = nAgt.indexOf('Opera')) != -1) {
  browser = 'Opera';
  version = nAgt.substring(verOffset + 6);
  if ((verOffset = nAgt.indexOf('Version')) != -1) {
    version = nAgt.substring(verOffset + 8);
  }
}
// Opera Next
if ((verOffset = nAgt.indexOf('OPR')) != -1) {
  browser = 'Opera';
  version = nAgt.substring(verOffset + 4);
}
// Legacy Edge
else if ((verOffset = nAgt.indexOf('Edge')) != -1) {
  browser = 'Microsoft Legacy Edge';
  version = nAgt.substring(verOffset + 5);
}
// Edge (Chromium)
else if ((verOffset = nAgt.indexOf('Edg')) != -1) {
  browser = 'Microsoft Edge';
  version = nAgt.substring(verOffset + 4);
}
// MSIE
else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
  browser = 'Microsoft Internet Explorer';
  version = nAgt.substring(verOffset + 5);
}
// Chrome
else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
  browser = 'Chrome';
  version = nAgt.substring(verOffset + 7);
}
// Safari
else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
  browser = 'Safari';
  version = nAgt.substring(verOffset + 7);
  if ((verOffset = nAgt.indexOf('Version')) != -1) {
    version = nAgt.substring(verOffset + 8);
  }
}
// Firefox
else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
  browser = 'Firefox';
  version = nAgt.substring(verOffset + 8);
}
// MSIE 11+
else if (nAgt.indexOf('Trident/') != -1) {
  browser = 'Microsoft Internet Explorer';
  version = nAgt.substring(nAgt.indexOf('rv:') + 3);
}
// Other browsers
else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
  browser = nAgt.substring(nameOffset, verOffset);
  version = nAgt.substring(verOffset + 1);
  if (browser.toLowerCase() == browser.toUpperCase()) {
    browser = navigator.appName;
  }
}
// trim the version string
if ((ix = version.indexOf(';')) != -1) version = version.substring(0, ix);
if ((ix = version.indexOf(' ')) != -1) version = version.substring(0, ix);
if ((ix = version.indexOf(')')) != -1) version = version.substring(0, ix);

majorVersion = parseInt('' + version, 10);
if (isNaN(majorVersion)) {
  version = '' + parseFloat(navigator.appVersion);
  majorVersion = parseInt(navigator.appVersion, 10);
}



// system
let os = unknown;
let clientStrings = [
  { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
  { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
  { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
  { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
  { s: 'Windows Vista', r: /Windows NT 6.0/ },
  { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
  { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
  { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
  { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
  { s: 'Windows 98', r: /(Windows 98|Win98)/ },
  { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
  { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
  { s: 'Windows CE', r: /Windows CE/ },
  { s: 'Windows 3.11', r: /Win16/ },
  { s: 'Android', r: /Android/ },
  { s: 'Open BSD', r: /OpenBSD/ },
  { s: 'Sun OS', r: /SunOS/ },
  { s: 'Chrome OS', r: /CrOS/ },
  { s: 'Linux', r: /(Linux|X11(?!.*CrOS))/ },
  { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
  { s: 'Mac OS X', r: /Mac OS X/ },
  { s: 'Mac OS', r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
  { s: 'QNX', r: /QNX/ },
  { s: 'UNIX', r: /UNIX/ },
  { s: 'BeOS', r: /BeOS/ },
  { s: 'OS/2', r: /OS\/2/ },
  { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ }
];
for (let id in clientStrings) {
  let cs = clientStrings[id];
  if (cs.r.test(nAgt)) {
    os = cs.s;
    break;
  }
}

let osVersion = unknown;

if (/Windows/.test(os)) {
  osVersion = /Windows (.*)/.exec(os)[1];
  os = 'Windows';
}

switch (os) {
  case 'Mac OS':
  case 'Mac OS X':
  case 'Android':
    osVersion = /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([\.\_\d]+)/.exec(nAgt)[1];
    break;

  case 'iOS':
    osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
    osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
    break;
}

try {
  //Получить версию приложения для андроид
  appVersion = Android.getAppVersion();
} catch (err) {

}

try {
  //Получить версию приложения для Windows
  let request = { method: "getAppVersion" }
  window.cefQuery({
    request: JSON.stringify(request),
    persistent: false,
    onSuccess(response) {
      appVersion = response;
    },
  });
} catch (err) {

}

export const ws = () => {
  /*Подключаемся к Websockets*/
  let wsurl = "wss://ws.skyservice.online/" + store.accountId + "/" + store.accountId + "/" + store.token + "/";

  try {
    console.log("ws connect to", wsurl);

    //socket = new WebSocket(wsurl);
    try {
      socket = new ReconnectingWebSocket(wsurl);
      //socket = new WebSocket(wsurl);
    } catch (err) {
      //  console.log(err)
    }
    store.socket = socket;

    socket.onopen = (event) => {
      console.log("connected");
      store.wsConnection = true;

      let deviceInfo = {};
      let deviceData = {};
      let userInfo = {};

      if (!localStorage.device_uuid) {
        localStorage.device_uuid = uuid.v1();
      }

      userInfo.device_uuid = localStorage.device_uuid;
      userInfo.browser = `${browser} ${majorVersion} (${version})`;
      // userInfo.appName = webviewCheck();
      userInfo.appVersion = appVersion;
      userInfo.appTitle = "cabinet";
      userInfo.os = `${os} ${osVersion}`;
      userInfo.lang = store.lang;
      userInfo.country = store.country;

      // получение данных Android устройства
      try {
        deviceInfo = JSON.parse(Android.getDeviceInfo());
        deviceData = {
          brand: deviceInfo.brand || null,
          hardware: deviceInfo.hardware || null,
          manufacturer: deviceInfo.manufacturer || null,
          model: deviceInfo.model || null
        }
      } catch (err) {
        // console.log("Не андроид устройство");
      }

      userInfo.ip = store.IP;
      socket.send(JSON.stringify({
        send: "info",
        userInfo: {
          ...userInfo,
          ...deviceData
        }
      }));


    // let activeByAccountIdData = {
    //     send: "activeByAccountId",
    //     accountId: store.accountId
    // };
    // console.log("activeByAccountId",activeByAccountIdData);
    // socket.send(JSON.stringify(activeByAccountIdData));


    }

    socket.onclose = (event) => {
      // console.log("WebSocket was closed now");
      // console.log(event.code);
      // console.log(event.reason);
      store.wsConnection = false;
      // setTimeout(ws, 4000);
    };

    socket.onerror = (event) => {
      // console.log("WebSocket error");
      // console.log(event);
      // console.log(event.reason);
      store.wsConnection = false;
    }

    socket.onmessage = (event) => {
       console.log("ws event:", event);
      let message = JSON.parse(event.data);
      console.log("ws message", message)
      wsMessageHandler(message);
    };

    window.onbeforeunload = () => {
      socket.close();
    }

  } catch (err) {
    console.error(err);
  }
}


export default socket;