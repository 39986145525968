const lang = {
    "ToAccept": "Aceitar",
    "Reject": "Rejeitar",
    "Password": "Senha",
    "RepeatPassword": "Repita a senha",
    "OldPassword": "Senha Antiga",
    "MyCompany": "minhas empresas",
    "Company": "Companhia",
    "CreateNewCompany": "Crie sua empresa",
    "Сancel": "Cancelar",
    "Save": "Salve ",
    "GeneralInformation": "informações gerais",
    "CompanyName": "Nome da empresa",
    "LogOut": "Sair",
    "SignOutOfAccount": "Sair",
    "ChangePassword": "Mudar senha",
    "AddPassword": "Adicionar senha",
    "Language": "Linguagem",
    "ConfirmThisMail": "Seu email foi confirmado",
    "Invites": "convites",
    "AcceptInvitation": "Aceitar convite",
    "EMailOrPhone": "Telefone ou e-mail",
    "Saved": "Salvou",
    "Error": "Erro",
    "FillInAllTheFields": "Preencha todos os campos",
    "FirstCreateAnAccountPassword": "Primeiro, crie uma senha de conta",
    "Settings": "Configurações",
    "Next": "Mais longe",
    "MinPassLength": "A senha é muito curta",
    "action": "Ação",
    "NewOwner": "Novo dono",
    "UserMustBeRegistered": "O usuário deve ser registrado",
    "LeaveTheCompany": "Sair da empresa",
    "Confirm": "confirme",
    "WrongFormat": "Formato incorreto",
    "DeleteAccount": "Deletar conta",
    "WrongPassword": "Senha incorreta",
    "PhoneAlreadyRegistered": "Telefone já cadastrado",
    "EmailAlreadyRegistered": "Email já cadastrado",
    "Yes": "Sim",
    "CreateACompany": "Criar uma empresa",
    "MyReferrals": "Minhas recomendações",
    "Edit": "Mudar",
    "NewEmail": "novo e-mail",
    "Proceed": "Prosseguir",
    "IncorrectCaptcha": "CAPTCHA errado",
    "CaptchaIsNotValid": "Captcha não é mais válido",
    "EnterCaptcha": "Digite captcha",
    "EnterSMS": "Digite o SMS",
    "SMSCodeIsNoLongerValid": "O código SMS não é mais válido",
    "SMSCodeIsIncorrect": "O código SMS está incorreto",
    "MailNotVerifiedLinkTimedOut": "E-mail não confirmado, link expirado",
    "ReferralLink": "Link de referência",
    "Date": "data de",
    "EarnWithUs": "Ganhe conosco",
    "RecommendSkyservicePOSToYourFriendsAndGetRealMoneyToYourAccount.": "Recomende Skyservice POS para seus amigos e receba dinheiro real em sua conta.",
    "HowItWorks": "Como funciona?",
    "ItsVerySimpleYouBringCustomersToSkyservicePOSAndWeShareTheirPaymentsWithYou.": "É muito simples: você traz os clientes para o Skyservice POS e nós compartilhamos seus pagamentos com você.",
    "Copied": "copiado",
    "TheMinimumWithdrawalAmountIs100USD": "*Valor mínimo de retirada 100 USD",
    "AddEmail": "Adicionar e-mail",
    "YouDontHaveCompanyYet": "Você ainda não tem uma empresa",
    "NoCompanyText": "Para começar a trabalhar com caixa registradora, você precisa criar uma empresa ou aceitar um convite de um empregador.",
    "ConfirmEmail": "Um e-mail foi enviado para confirmar a alteração.",
    "description": "Descrição",
    "NoDescriptionSpecified": "sem descrição",
    "DateCreate": "Data de criação:",
    "DelComp": "Excluir empresa",
    "Help": "Apoio, suporte",
    "Email": "Correspondência",
    "Phone": "Telefone",
    "SureAccDel": "Tem certeza de que deseja desvincular sua conta?",
    "no": "Não",
    "yes": "Sim",
    "confirm": "confirme",
    "cancel": "Cancelar",
    "Search": "Procurar",
    "dateDelete": "Data de exclusão:",
    "active": "Ativo",
    "delete": "Removido",
    "services": "Serviços",
    "status": "Status",
    "recover": "Restabelecer",
    "restored": "restaurado",
    "restore": "Recuperação",
    "DoYouWantRestore": "Deseja restaurar a campanha",
    "deletedCompamies": "EMPRESAS REMOTAS",
    "CompanyNotSearch": "Nenhuma empresa encontrada que corresponda aos seus critérios",
    "writeCorrectlyName": "Digite o nome de usuário corretamente",
    "addlogo": "Adicionar logotipo",
    "Logo": "Logotipo",
    "nipmg": "Clique ou arraste um arquivo para esta janela",
    "ErrorDownload": "erro de carregamento",
    "preview": "visualizar",
    "onlyimg": "Somente imagens",
    "imgisbig": "A imagem excede o limite de tamanho",
    "minimgsize": "Tamanho mínimo da imagem",
    "Zagruzka...": "Carregando...",
    "Youbrowserisold": "Seu navegador não é compatível, use o IE10+ ou outros navegadores",
    "failZagruzhen": "Arquivo enviado com sucesso!",
    "zakrit": "perto",
    "nazad": "Voltar",
    "save": "Salve ",
    "zagruzit_foto": "Carregar uma foto",
    "clear": "Claro",
    "logoIsAdd": "Logo adicionado",
    "logoIsDel": "Logomarca limpa",
    "editComp": "Editar empresa",
    "giveComp": "empresa de transferência",
    "thisUserNorRegistered": "Este usuário não está registrado",
    "cantTransferToYou": "Você não pode transferir sua empresa para si mesmo",
    "youNotOwner": "Você não é o dono da empresa",
    "Ok": "OK",
    "notYetCreateComp": "No momento, a criação de novas empresas não está disponível",
    "EnterToCompany": "Entrar na empresa",
    "startDescription": "Bem-vindo à sua conta pessoal, faça login na empresa para começar",
    "inviteComp": "convite para a empresa",
    "visitDemo": "Visite a empresa de demonstração",
    "makeUrChoice": "Faça uma escolha",
    "ImWorker": "eu sou um empregado",
    "ImOwner": "Eu sou um empregador, representante do empregador",
    "needInviteComp": "Você deve aceitar um convite para uma empresa existente",
    "needCreateComp": "Você precisa criar uma empresa para começar",
    "daysLeft": "Dias restantes",
    "cannotDel": "A desinstalação está indisponível no momento",
    "NoCompanyCreateText": "Para começar a trabalhar com caixa registradora, você deve aceitar um convite do empregador.",
    "withoutName": "sem nome",
    "cropImageHint": "Clique ou arraste um arquivo para carregar",
    "cropImageNoSupported": "Navegador não suportado, use IE10+ ou outros navegadores.",
    "cropImageSuccess": "Download bem-sucedido",
    "cropImageFail": "Falha no Download",
    "cropImageOnlyImgNeed": "Somente imagens",
    "cropImageOutOfSize": "O tamanho da imagem excede o limite",
    "cropImageLowestPx": "Tamanho mínimo da imagem",
    "uReachedLimitComp": "Você atingiu o limite do número de empresas criadas",
    "accAlreadyRegister": "Esta conta já está vinculada a outra conta",
    "accNotRegister": "Essa conta não existe",
    "areYourCountry": "este é o seu país?",
    "enterCorrectNumber": "Digite o número no formato internacional",
    "attach": "ligar",
    "UserEmailAlreadyExists": "Já existe usuário com este endereço de email",
    "AccountAlreadyLinked": "Conta já vinculada",
    "facebookAccIsAlreadyRegistered": "Esta conta do Facebook já está vinculada a outra conta Skyservice",
    "googleAccIsAlreadyRegistered": "Esta conta do Google já está vinculada a outra conta Skyservice",
    "telegramAccAlreadyRegister": "Este Telegram já está vinculado a outra conta Skyservice",
    "companyBlocked": "Esta empresa está inativa",
    "allSessions": "Mostrar todas as sessões",
    "activeSessions": "Sessões ativas",
    "exitAllDevices": "Sair em todos os outros dispositivos",
    "currentSession": "Sessão atual",
    "autoCloseSessions": "Conclusão automática da sessão",
    "nonActivePeriod": "Terminar automaticamente se a sessão não estiver ativa",
    "oneWeek": "1 semana",
    "oneMonth": "1 mês",
    "threeMonth": "3 meses",
    "sixMonth": "6 meses",
    "terminate": "Completo",
    "sessionDesc1": "Todos os dispositivos conectados à sua conta Skyservice são listados aqui. Você pode sair da sua conta em cada um deles individualmente ou em todos de uma vez.",
    "sessionDesc2": "Se você encontrar um dispositivo desconhecido na lista, saia imediatamente deste dispositivo e altere a senha da sua conta Skyservice.",
    "sureToTerminate": "Tem certeza de que deseja encerrar todas as sessões ativas?",
    "ip": "endereço de IP",
    "geoPos": "geoposição",
    "info": "Informação",
    "sessionDesc3": "As sessões que não tiverem nenhuma atividade durante esse período serão encerradas automaticamente.",
    "terminatesSessions": "Terminando as sessões",
    "more3monthAgo": "Há mais de três meses",
    "noData": "sem dados",
    "goSettingsCompany": "Estamos criando sua empresa, aguarde um minuto...",
    "preparingCompany1": "Estamos criando sua empresa, aguarde um minuto...",
    "preparingCompany2": "Estamos preparando uma interface para gestão de bens e serviços...",
    "preparingCompany3": "Estamos trabalhando em uma contabilidade financeira conveniente...",
    "preparingCompany4": "Estamos fazendo as configurações finais, quase prontas...",
    "appleAccIsAlreadyRegistered": "Este AppleId já está vinculado a outra conta Skyservice",
    "notGetDataFromApple": "Falha ao receber dados da Apple"
}

export default function() {
    return lang;
}