<template>
    <smallModal @escape="$emit('close')">
        <h6>{{ lang['SignOutOfAccount'] }}?</h6>
        <hr>
        <div class="buttons">
            <button class="btn btn-secondary" @click="$emit('close')">{{ lang['Сancel'] }}</button>
            <button class="btn btn-danger" @click="escape">{{ lang['LogOut'] }}</button>
        </div>
    </smallModal>
</template>

<script>
import { store } from '@/store';
import { regAPi } from '../API.js';
import smallModal from './modals/smallModal'
import Cookies from "js-cookie";
import { webviewCheck } from "@/components/js/webviewCheck.js";

// создаем апи куков с настройкой дэфолтного домена для продакшена
let apiCookies = Cookies;

if (process.env.NODE_ENV == 'production') {
   apiCookies = Cookies.withAttributes({domain: ".skyservice.online"})
}

const apiList = new regAPi();
export default {
    components: {
        smallModal
    },
    data() {
        return {
            
        }
    },
    methods: {
    // метод для логаута винды
    windowsLogout(){
        const data = {
            command: "logout",
            authType: "all"
        }
 
        const request = {
            method: "logout",
            data: JSON.stringify(data)
        };

        window.cefQuery({request: JSON.stringify(request),
            persistent: true,
            onSuccess: function(responseJson) {
                console.log(responseJson)
            },
            onFailure: function(error_code, error_message) {
                console.log(error_code, error_message)
            } 
        });
    },
    // метод для логаута андроидов
    androidLogout(){
        const authData = {
            "command": 'logout',
            "authType": 'all',
        }

        try{
            // eslint-disable-next-line
            Android.auth(JSON.stringify(authData))
        
        } catch(err) {
            // console.log('err')
        }
    },
    escape() {
        if( webviewCheck() == "android_webview"){
            this.androidLogout()
        }
        try {
            // при выходе кикаем токен, закрываем модалку, ну и выкидываем на страницу логина
            apiList.logout({ token: store.token}).then(resp => {
                if (resp.data.status == 'done') {
                    // apiCookies.remove('token');
                    apiCookies.set("token", '', {expires: -1});
                    return
                } else {
                    window.say.messageError(this.lang['Error']);
                }
            }).then(() => {
                // this.$emit('logout');
                // this.$router.push('/');
                document.location.href = 'https://auth.skyservice.online/';
            });
        } catch(err) {
            console.log(err)
        }
    },
},
    computed: {
        country() {
			return 'UA';
        },
        lang() {
            return window.lang;
        }
    }
}
</script>

<style scoped>
.btn-secondary, .btn-danger{
    width: 49%;
}
.buttons{
    display: flex;
    justify-content: space-between;
}
</style>