const lang = {
    "ToAccept": "Прыняць",
    "Reject": "Адхіліць",
    "Password": "Пароль",
    "RepeatPassword": "Паўтарыць пароль",
    "OldPassword": "Стары пароль",
    "MyCompany": "Мае кампаніі",
    "Company": "Кампанія",
    "CreateNewCompany": "Стварыць сваю кампанію",
    "Сancel": "Адмена",
    "Save": "Захаваць",
    "GeneralInformation": "Агульная інфармацыя",
    "CompanyName": "Назва кампаніі",
    "LogOut": "Выйсці",
    "SignOutOfAccount": "Выйсці з уліковага запісу",
    "ChangePassword": "Змяніць пароль",
    "AddPassword": "Дадаць пароль",
    "Language": "Мова",
    "ConfirmThisMail": "Ваш e-mail пацверджаны",
    "Invites": "Запрашэнні",
    "AcceptInvitation": "Прыняць запрашэнне",
    "EMailOrPhone": "Тэлефон ці пошта",
    "Saved": "Захавана",
    "Error": "Памылка",
    "FillInAllTheFields": "Запоўніце ўсе палі",
    "FirstCreateAnAccountPassword": "Для пачатку, стварыце пароль акаўнта",
    "Settings": "Налады",
    "Next": "Далей",
    "MinPassLength": "Пароль занадта кароткі",
    "action": "Дзеянне",
    "NewOwner": "Новы ўладальнік",
    "UserMustBeRegistered": "Карыстальнік павінен быць зарэгістраваны",
    "LeaveTheCompany": "Пакінуць кампанію",
    "Confirm": "Пацвердзіць",
    "WrongFormat": "Няправільны фармат",
    "DeleteAccount": "Выдаліць акаўнт",
    "WrongPassword": "Няправільны пароль",
    "PhoneAlreadyRegistered": "Тэлефон ужо зарэгістраваны",
    "EmailAlreadyRegistered": "Пошта ўжо зарэгістраваная",
    "Yes": "Так",
    "CreateACompany": "Стварыць кампанію",
    "MyReferrals": "Мае Рэфералы",
    "Edit": "Змяніць",
    "NewEmail": "Новая пошта",
    "Proceed": "Працягнуць",
    "IncorrectCaptcha": "Няправільная капча",
    "CaptchaIsNotValid": "Капча больш несапраўдная",
    "EnterCaptcha": "Увядзіце капчу",
    "EnterSMS": "Увядзіце СМС",
    "SMSCodeIsNoLongerValid": "СМС код ужо не сапраўдны",
    "SMSCodeIsIncorrect": "СМС код няправільны",
    "MailNotVerifiedLinkTimedOut": "Пошта не пацверджана, скончыўся час спасылкі",
    "ReferralLink": "Рэферальная спасылка",
    "Date": "Дата",
    "EarnWithUs": "Зарабляйце з намі",
    "RecommendSkyservicePOSToYourFriendsAndGetRealMoneyToYourAccount.": "Рэкамендуйце Skyservice POS вашым сябрам і атрымлівайце рэальныя грошы на свой рахунак.",
    "HowItWorks": "Як гэта працуе?",
    "ItsVerySimpleYouBringCustomersToSkyservicePOSAndWeShareTheirPaymentsWithYou.": "Усё вельмі проста: Вы прыводзьце кліентаў у Skyservice POS, а мы дзелімся з Вамі іх плацяжамі.",
    "Copied": "Скапіравана",
    "TheMinimumWithdrawalAmountIs100USD": "*Мінімальная сума для вываду 100 USD",
    "AddEmail": "Дадаць пошту",
    "YouDontHaveCompanyYet": "У вас пакуль няма кампаніі",
    "NoCompanyText": "Для пачатку працы з касай неабходна стварыць кампанію ці прыняць запрашэнне ад працадаўцы.",
    "ConfirmEmail": "На пошту быў дасланы ліст для пацверджання змены",
    "description": "Апісанне",
    "NoDescriptionSpecified": "Няма апісання",
    "DateCreate": "Дата стварэння:",
    "DelComp": "Выдаліць кампанію",
    "Help": "Тэхпадтрымка",
    "Email": "Пошта",
    "Phone": "Тэлефон",
    "SureAccDel": "Вы сапраўды хочаце адвязаць ваш рахунак",
    "no": "Не",
    "yes": "Так",
    "confirm": "Пацвердзіць",
    "cancel": "Адмена",
    "Search": "Пошук",
    "dateDelete": "Дата выдалення:",
    "active": "Актыўны",
    "delete": "Выдалены",
    "services": "Сэрвісы",
    "status": "Статус",
    "recover": "Аднавіць",
    "restored": "Адноўлена",
    "restore": "Аднаўленне",
    "DoYouWantRestore": "Вы хочаце аднавіць кампанію",
    "deletedCompamies": "ВІДДАЛЕНЫЯ КАМПАНІІ",
    "CompanyNotSearch": "Не знойдзена кампаній па дадзеных крытэрыях",
    "writeCorrectlyName": "Увядзіце імя карыстальніка карэктна",
    "addlogo": "Дадаць лагатып",
    "Logo": "Лагатып",
    "nipmg": "Націсніце, або перацягнуць файл у гэтае акно",
    "ErrorDownload": "Памылка загрузкі",
    "preview": "Прадпрагляд",
    "onlyimg": "Толькі выявы",
    "imgisbig": "Выява перавышае гранічны памер",
    "minimgsize": "Мінімальны памер выявы",
    "Zagruzka...": "Загрузка...",
    "Youbrowserisold": "Ваш браўзэр не падтрымліваецца, калі ласка, выкарыстоўвайце IE10 + ці іншыя браўзэры",
    "failZagruzhen": "Файл паспяхова загружаны!",
    "zakrit": "Закрыць",
    "nazad": "Назад",
    "save": "Захаваць",
    "zagruzit_foto": "Загрузіць фота",
    "clear": "Ачысціць",
    "logoIsAdd": "Лагатып дададзены",
    "logoIsDel": "Лагатып ачышчаны",
    "editComp": "Рэдагаваць кампанію",
    "giveComp": "Перадаць кампанію",
    "thisUserNorRegistered": "Такі карыстальнік не зарэгістраваны",
    "cantTransferToYou": "Нельга перадаваць сваю кампанію сабе",
    "youNotOwner": "Вы не з'яўляецеся уладальнікам кампаніі",
    "Ok": "Ок",
    "notYetCreateComp": "На дадзены момант стварэнне новых кампаній недаступна",
    "EnterToCompany": "Увайсці ў кампанію",
    "startDescription": "Вітаем Вас у вашым персанальным кабінеце, увайдзіце ў кампанію для пачатку працы",
    "inviteComp": "Запрашэнне ў кампанію",
    "visitDemo": "Наведаць дэма кампанію",
    "makeUrChoice": "Зрабіце выбар",
    "ImWorker": "Я наёмны работнік",
    "ImOwner": "Я працадаўца, прадстаўнік працадаўцы",
    "needInviteComp": "Неабходна прыняць запрашэнне ў існуючую кампанію",
    "needCreateComp": "Неабходна стварыць кампанію для пачатку працы",
    "daysLeft": "Засталося дзён",
    "cannotDel": "Выдаленне зараз недаступна",
    "NoCompanyCreateText": "Для пачатку працы з касай неабходна прыняць запрашэнне ад працадаўцы.",
    "withoutName": "Без імя",
    "cropImageHint": "Націсніце або перацягнуць файл для загрузкі",
    "cropImageNoSupported": "Браўзэр не падтрымліваецца, выкарыстоўвайце IE10+ ці іншыя браўзэры.",
    "cropImageSuccess": "Загрузка прайшла паспяхова",
    "cropImageFail": "Загрузка не ўдалася",
    "cropImageOnlyImgNeed": "Толькі выявы",
    "cropImageOutOfSize": "Памер выявы перавышае ліміт",
    "cropImageLowestPx": "Мінімальны памер выявы",
    "uReachedLimitComp": "Вы дасягнулі ліміту па колькасці створаных кампаній",
    "accAlreadyRegister": "Гэты ўліковы запіс ужо прывязаны да іншага акаўнта",
    "accNotRegister": "Не існуе такога ўліковага запісу",
    "areYourCountry": "гэта ваша краіна?",
    "enterCorrectNumber": "Увядзіце нумар у міжнародным фармаце",
    "attach": "Прывязаць",
    "UserEmailAlreadyExists": "Карыстальнік з такой поштай ужо існуе",
    "AccountAlreadyLinked": "Акаўнт ужо прывязаны",
    "facebookAccIsAlreadyRegistered": "Гэты Facebook рахунак ужо прывязаны да іншага ўліковага запісу Skyservice",
    "googleAccIsAlreadyRegistered": "Гэты Google рахунак ужо прывязаны да іншага ўліковага запісу Skyservice",
    "telegramAccAlreadyRegister": "Гэты Telegram ужо прывязана да іншага ўліковага запісу Skyservice",
    "companyBlocked": "Гэтая кампанія неактыўная",
    "allSessions": "Паказаць усе сесіі",
    "activeSessions": "Актыўныя сесіі",
    "exitAllDevices": "Выйсці на ўсіх астатніх прыладах",
    "currentSession": "Бягучая сесія",
    "autoCloseSessions": "Аўтазавяршэнне сесій",
    "nonActivePeriod": "Аўтаматычна завяршаць калі сесія не актыўная",
    "oneWeek": "1 тыдзень",
    "oneMonth": "1 месяц",
    "threeMonth": "3 месяца",
    "sixMonth": "6 месяцаў",
    "terminate": "Завяршыць",
    "sessionDesc1": "Тут пералічаны ўсе прылады, на якіх выкананы ўваход у ваш уліковы запіс Skyservice. Вы можаце выйсці з уліковага запісу на кожным з іх па асобнасці або на ўсіх адразу.",
    "sessionDesc2": "Калі вы выявілі ў спісе незнаёмую прыладу, неадкладна выканайце выхад на гэтай прыладзе і зменіце пароль ад вашага ўліковага запісу Skyservice.",
    "sureToTerminate": "Вы сапраўды хочаце завяршыць усе актыўныя сесіі?",
    "ip": "IP-адрас",
    "geoPos": "Геапазіцыя",
    "info": "Інфармацыя",
    "sessionDesc3": "Сеансы, з якіх не будзе ніякай актыўнасці на працягу гэтага перыяду, будуць завяршацца аўтаматычна.",
    "terminatesSessions": "Завяршэнне сеансаў",
    "more3monthAgo": "Больш за тры месяцы таму",
    "noData": "Няма дадзеных",
    "goSettingsCompany": "Ствараем вашу кампанію, дайце нам хвілінку...",
    "preparingCompany1": "Ствараем вашу кампанію, калі ласка, дайце нам хвілінку...",
    "preparingCompany2": "Рыхтуем інтэрфейс для кіравання таварамі і паслугамі...",
    "preparingCompany3": "Працуем над зручным улікам фінансаў...",
    "preparingCompany4": "Выконваем фінальныя наладкі, амаль гатова...",
    "appleAccIsAlreadyRegistered": "Гэты AppleId ужо прывязаны да іншага акаўнта Skyservice",
    "notGetDataFromApple": "Не ўдалося атрымаць дадзеныя ад Apple"
}

export default function() {
    return lang;
}