<template>
    <smallModal>
        <div class="password-input-div">
            <div v-if="passwordText != null">
                <h5>{{ passwordText }}</h5>
                <hr>
            </div>
            <div class="firstPass">
                <label for="password">{{ lang['Password'] }}</label>
                <b-form-input id="first-input" type="password" autofocus v-model="password" :state="pass1" />
                <span @click="showPass" :class="showFirsrtPass ? 'icon-eye' : 'icon-eye-blocked'"></span>
            </div>
            <div class="secondPass">
                <label for="password">{{ lang['RepeatPassword'] }}</label>
                <b-form-input id="second-input" type="password" v-model="password2" :state="pass2" />
            </div>
        </div>
        <hr>
        <div>
            <button class="btn btn-success" @click="save">{{ lang['Save'] }}</button>
        </div>
    </smallModal>
</template>

<script>
import { keyevent } from '@/main.js';
import { regAPi } from '@/API.js';
import smallModal from '../modals/smallModal';
import { store } from '../../store';

const apiList = new regAPi();
export default {
    props: ['passwordText'],
    components: {
        smallModal
    },
    data() {
        return {
            password: '',
            password2: '',
            showFirsrtPass: false,
            showSecondPass: false,
        }
    },
    mounted() {
        keyevent.$off('enter');
        keyevent.$on('enter', () =>
            this.save()
        );
    },
    methods: {
        showPass(){
            this.showFirsrtPass = !this.showFirsrtPass
            const input1 = document.getElementById('first-input');
            const input2 = document.getElementById('second-input');
            if (input1.getAttribute('type') == 'password') {
                input1.setAttribute('type', 'text');
                input2.setAttribute('type', 'text');
            } else {
                input1.setAttribute('type', 'password');
                input2.setAttribute('type', 'password');
            }
        },
        cansel() {
            this.$emit('close')
        },
        save() {
            try {
                if (this.pass1 && this.pass2) {
                    apiList.addPersonPassword({ token: store.token, password: this.password }).then(resp => {
                        if (resp.data.status == 'done') {
                            window.say.message(this.lang['Saved']);
                            this.$emit('close');
                            this.$emit('passwordReady');
                        } else {
                            window.say.messageError(this.lang['Error']);
                        }
                    })
                } else {
                    window.say.messageError(this.lang['FillInAllTheFields']);
                }
            } catch(err) {
                window.say.messageError(this.lang['Error']);
            }
        }
    },
    computed: {
        lang() {
            return window.lang;
        },
        pass1() {
            return this.password.length >= 4 ? true : false;
        },
        pass2() {
            return this.password2.length > 0 && this.password2 == this.password ? true : false;
        },
    }
}
</script>

<style scoped>
.btn-success{
    width: 100%;
}
.icon-eye, .icon-eye-blocked {
    position: absolute;
	top: 59.5%;
	right: 9%;
}
.firstPass, .secondPass{
    position: relative;
}
@media screen and (max-width: 700px) {
    .icon-eye, .icon-eye-blocked  {
        right: 32px;
    }
}
</style>