<template>
    <smallModal @escape="$emit('close')">
        <h5>{{ lang['Edit'] }}: {{ personEmail }}?</h5>
        <hr>
        <div v-if="personPassword">
            <label for="new-email">{{ lang['NewEmail'] }}</label>
            <b-form-input v-model="new_email" autofocus id="email-input-settings" :state="checkEmail" />
        </div>
        <div v-else class="center-text">
            <span class="grey-span">{{ lang['FirstCreateAnAccountPassword'] }}</span>
        </div>
        <hr>
        <div class="buttons">
            <button class="btn btn-secondary" @click="$emit('close')">{{ lang['Сancel'] }}</button>
            <button v-if="personPassword" class="btn btn-success" @click="editEmail">{{ lang['Proceed'] }}</button>
            <button v-else class="btn btn-success" @click="goAddPass">{{ lang['Proceed'] }}</button>
        </div>
        <!-- этот див для автофокуса, чтобы работал Enter -->
        <div ref="editMail"
            tabindex="0">
        </div>
    </smallModal>
</template>

<script>
import { keyevent } from '@/main.js';
import smallModal from '../modals/smallModal';
import { regAPi } from '@/API.js';
const apiList = new regAPi();
export default {
    props: ['personEmail', 'personPassword'],
    components: {
        smallModal
    },
    data() {
        return {
            new_email: ''
        }
    },
    mounted() {
        // автофокус для дива
        this.$nextTick(() => {
            this.$refs.editMail.focus();
        });
        keyevent.$off('enter');
        keyevent.$on('enter', () =>
            this.personPassword ? this.editEmail() : this.goAddPass()
        );
    },
    methods: {
        // изменение почты
        // по апи сверяем, не т ли у нас такой почты в базе, если все ок, закрываем модалку и перекидываем введенную почту в родителя, а от туда в следующую модалку
        editEmail() {
            if (this.checkEmail) {
                apiList.checkAccount({ login: this.new_email }).then(resp =>{
                    if(resp.data.status == "done"){
                        if (resp.data.data == 'login not found') {
                            this.$emit('close');
                            this.$emit('gopassword', { new_email: this.new_email })
                        } else {
                            window.say.messageError(this.lang['EmailAlreadyRegistered']);
                        }
                    }else if(resp.data.status == "error"){
                        this.$emit('close');
                        if(Array.isArray(resp.data.error)){
                            for(let key in resp.data.error){
                                window.say.messageError(resp.data.error[key]);
                            }
                        }else if(typeof resp.data.error == 'string'){
                            window.say.messageError(resp.data.error);
                        }
                    }else{
                        this.$emit('close');
                        window.say.messageError("unknown error:0176");
                    }
                })
            } else {
                window.say.messageError(this.lang['WrongFormat'])
            }
        },
        // ( костыль для модалки добавления пароля, обычный кейивэнт там не работает )(можно удалить после теста)
        // onKeyDown(event) {
        //     if (event.key === "Enter") {
        //         this.personPassword ? this.editEmail() : this.goAddPass()
        //     }
        // },
        // если юзер захотел поменять мыло, но он не додумался ранее создать пароль себе
        goAddPass() {
            this.$emit('close');
            this.$emit('goaddpass')
        }
    },
    computed: {
        lang() {
            return window.lang;
        },
        checkEmail() {
            const mailRegular  = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (mailRegular.test(this.new_email) == false) return false;
            else return true;
        },
    }
    
}
</script>

<style scoped>
.btn-secondary, .btn-success{
  width: 49%;
}
.buttons{
  display: flex;
  justify-content: space-between;
}
.center-text{
    text-align: center;
}
.grey-span{
    color: grey;
}
</style>