const lang = {
    "ToAccept": "Akceptēt",
    "Reject": "Noraidīt",
    "Password": "Parole",
    "RepeatPassword": "atkārtojiet paroli",
    "OldPassword": "Vecā parole",
    "MyCompany": "Mani uzņēmumi",
    "Company": "Uzņēmums",
    "CreateNewCompany": "Izveidojiet savu uzņēmumu",
    "Сancel": "Atcelt",
    "Save": "Saglabāt",
    "GeneralInformation": "Galvenā informācija",
    "CompanyName": "Kompānijas nosaukums",
    "LogOut": "Izlogoties",
    "SignOutOfAccount": "Izrakstīties",
    "ChangePassword": "Mainiet paroli",
    "AddPassword": "Pievienojiet paroli",
    "Language": "Valoda",
    "ConfirmThisMail": "Jūsu e-pasts ir apstiprināts",
    "Invites": "ielūgumi",
    "AcceptInvitation": "Pieņemt ielūgumu",
    "EMailOrPhone": "Tālrunis vai pasts",
    "Saved": "Saglabāts",
    "Error": "Kļūda",
    "FillInAllTheFields": "Aizpildiet visus laukus",
    "FirstCreateAnAccountPassword": "Vispirms izveidojiet konta paroli",
    "Settings": "Iestatījumi",
    "Next": "Tālāk",
    "MinPassLength": "Parole ir pārāk īsa",
    "action": "Darbība",
    "NewOwner": "Jauns īpašnieks",
    "UserMustBeRegistered": "Lietotājam jābūt reģistrētam",
    "LeaveTheCompany": "Pamest uzņēmumu",
    "Confirm": "Apstiprināt",
    "WrongFormat": "Nepareizs formāts",
    "DeleteAccount": "Dzēst kontu",
    "WrongPassword": "Nepareiza parole",
    "PhoneAlreadyRegistered": "Tālrunis jau reģistrēts",
    "EmailAlreadyRegistered": "Pasts jau reģistrēts",
    "Yes": "Jā",
    "CreateACompany": "Izveidojiet uzņēmumu",
    "MyReferrals": "Mani ieteikumi",
    "Edit": "Mainīt",
    "NewEmail": "Jauns pasts",
    "Proceed": "Turpināt",
    "IncorrectCaptcha": "Nepareiza captcha",
    "CaptchaIsNotValid": "Captcha vairs nav derīga",
    "EnterCaptcha": "Ievadiet captcha",
    "EnterSMS": "Ievadiet SMS",
    "SMSCodeIsNoLongerValid": "SMS kods vairs nav derīgs",
    "SMSCodeIsIncorrect": "SMS kods nav pareizs",
    "MailNotVerifiedLinkTimedOut": "Pasts nav apstiprināts, saite beidzās",
    "ReferralLink": "Ieteikuma saite",
    "Date": "datums",
    "EarnWithUs": "Nopelniet ar mums",
    "RecommendSkyservicePOSToYourFriendsAndGetRealMoneyToYourAccount.": "Iesakiet Skyservice POS saviem draugiem un saņemiet reālu naudu savā kontā.",
    "HowItWorks": "Kā tas strādā?",
    "ItsVerySimpleYouBringCustomersToSkyservicePOSAndWeShareTheirPaymentsWithYou.": "Tas ir ļoti vienkārši: jūs piesaistāt klientus Skyservice POS, un mēs kopīgojam ar jums viņu maksājumus.",
    "Copied": "Kopēts",
    "TheMinimumWithdrawalAmountIs100USD": "*Minimālā izņemšanas summa 100 USD",
    "AddEmail": "Pievienojiet pastu",
    "YouDontHaveCompanyYet": "Jums vēl nav uzņēmuma",
    "NoCompanyText": "Lai sāktu strādāt ar kases aparātu, ir jāizveido uzņēmums vai jāpieņem darba devēja uzaicinājums.",
    "ConfirmEmail": "Ir nosūtīts e-pasts, lai apstiprinātu izmaiņas.",
    "description": "Apraksts",
    "NoDescriptionSpecified": "Nav apraksta",
    "DateCreate": "Izveidošanas datums:",
    "DelComp": "Dzēst uzņēmumu",
    "Help": "Atbalsts",
    "Email": "Pasts",
    "Phone": "Tālrunis",
    "SureAccDel": "Vai tiešām vēlaties atsaistīt savu kontu?",
    "no": "Nav",
    "yes": "Jā",
    "confirm": "Apstiprināt",
    "cancel": "Atcelt",
    "Search": "Meklēt",
    "dateDelete": "Dzēšanas datums:",
    "active": "Aktīvs",
    "delete": "Noņemts",
    "services": "Pakalpojumi",
    "status": "Statuss",
    "recover": "Atjaunot",
    "restored": "atjaunota",
    "restore": "Atveseļošanās",
    "DoYouWantRestore": "Vai vēlaties atjaunot kampaņu?",
    "deletedCompamies": "TĀLĀS UZŅĒMUMI",
    "CompanyNotSearch": "Nav atrasts neviens uzņēmums, kas atbilstu jūsu kritērijiem",
    "writeCorrectlyName": "Pareizi ievadiet lietotājvārdu",
    "addlogo": "Pievienojiet logotipu",
    "Logo": "Logotips",
    "nipmg": "Noklikšķiniet vai velciet failu šajā logā",
    "ErrorDownload": "ielādes kļūda",
    "preview": "priekšskatījums",
    "onlyimg": "Tikai attēli",
    "imgisbig": "Attēls pārsniedz lieluma ierobežojumu",
    "minimgsize": "Minimālais attēla izmērs",
    "Zagruzka...": "Notiek ielāde...",
    "Youbrowserisold": "Jūsu pārlūkprogramma netiek atbalstīta, lūdzu, izmantojiet IE10+ vai citas pārlūkprogrammas",
    "failZagruzhen": "Fails veiksmīgi augšupielādēts!",
    "zakrit": "aizveriet",
    "nazad": "Atpakaļ",
    "save": "Saglabāt",
    "zagruzit_foto": "Augšupielādējiet fotoattēlu",
    "clear": "Skaidrs",
    "logoIsAdd": "Logo pievienots",
    "logoIsDel": "Logotips notīrīts",
    "editComp": "Rediģēt uzņēmumu",
    "giveComp": "Pārsūtīšanas uzņēmums",
    "thisUserNorRegistered": "Šis lietotājs nav reģistrēts",
    "cantTransferToYou": "Jūs nevarat nodot savu uzņēmumu sev",
    "youNotOwner": "Jūs neesat uzņēmuma īpašnieks",
    "Ok": "labi",
    "notYetCreateComp": "Šobrīd jaunu uzņēmumu izveide nav pieejama",
    "EnterToCompany": "Piesakieties uzņēmumā",
    "startDescription": "Laipni lūdzam savā personīgajā kontā, piesakieties uzņēmumā, lai sāktu darbu",
    "inviteComp": "Uzaicinājums uz uzņēmumu",
    "visitDemo": "Apmeklējiet demonstrācijas uzņēmumu",
    "makeUrChoice": "Izdari izvēli",
    "ImWorker": "Esmu darbinieks",
    "ImOwner": "Esmu darba devējs, darba devēja pārstāvis",
    "needInviteComp": "Jums ir jāpieņem uzaicinājums uz esošu uzņēmumu",
    "needCreateComp": "Lai sāktu, jums ir jāizveido uzņēmums",
    "daysLeft": "Dienas palikušas",
    "cannotDel": "Atinstalēšana pašlaik nav pieejama",
    "NoCompanyCreateText": "Lai sāktu strādāt ar kases aparātu, jāpieņem darba devēja uzaicinājums.",
    "withoutName": "Nav vārda",
    "cropImageHint": "Noklikšķiniet uz augšupielādējamā faila vai velciet to",
    "cropImageNoSupported": "Pārlūkprogramma netiek atbalstīta, lūdzu, izmantojiet IE10+ vai citas pārlūkprogrammas.",
    "cropImageSuccess": "Lejupielāde veiksmīga",
    "cropImageFail": "Lejupielāde neizdevās",
    "cropImageOnlyImgNeed": "Tikai attēli",
    "cropImageOutOfSize": "Attēla izmērs pārsniedz ierobežojumu",
    "cropImageLowestPx": "Minimālais attēla izmērs",
    "uReachedLimitComp": "Jūs esat sasniedzis izveidoto uzņēmumu skaita ierobežojumu",
    "accAlreadyRegister": "Šis konts jau ir saistīts ar citu kontu",
    "accNotRegister": "Šāda konta nepastāv",
    "areYourCountry": "vai šī ir tava valsts?",
    "enterCorrectNumber": "Ievadiet numuru starptautiskā formātā",
    "attach": "saistīt",
    "UserEmailAlreadyExists": "Lietotājs ar šo e-pasta adresi jau pastāv",
    "AccountAlreadyLinked": "Konts jau ir saistīts",
    "facebookAccIsAlreadyRegistered": "Šis Facebook konts jau ir saistīts ar citu Skyservice kontu",
    "googleAccIsAlreadyRegistered": "Šis Google konts jau ir saistīts ar citu Skyservice kontu",
    "telegramAccAlreadyRegister": "Šī telegramma jau ir saistīta ar citu Skyservice kontu",
    "companyBlocked": "Šis uzņēmums ir neaktīvs",
    "allSessions": "Rādīt visas sesijas",
    "activeSessions": "Aktīvās sesijas",
    "exitAllDevices": "Izrakstieties visās pārējās ierīcēs",
    "currentSession": "Pašreizējā sesija",
    "autoCloseSessions": "Sesijas automātiskā pabeigšana",
    "nonActivePeriod": "Automātiski beigt, ja sesija nav aktīva",
    "oneWeek": "1 nedēļa",
    "oneMonth": "1 mēnesis",
    "threeMonth": "3 mēneši",
    "sixMonth": "6 mēneši",
    "terminate": "Pabeigts",
    "sessionDesc1": "Šeit ir norādītas visas ierīces, kas ir pierakstījušās jūsu Skyservice kontā. Varat izrakstīties no sava konta katrā atsevišķi vai visos vienlaikus.",
    "sessionDesc2": "Ja sarakstā atrodat nepazīstamu ierīci, nekavējoties izrakstieties no šīs ierīces un nomainiet sava Skyservice konta paroli.",
    "sureToTerminate": "Vai tiešām vēlaties beigt visas aktīvās sesijas?",
    "ip": "IP adrese",
    "geoPos": "Ģeopozīcija",
    "info": "Informācija",
    "sessionDesc3": "Sesijas, kurām šajā periodā nav aktivitāšu, tiks automātiski beigtas.",
    "terminatesSessions": "Sesiju beigas",
    "more3monthAgo": "Vairāk nekā pirms trim mēnešiem",
    "noData": "Nav datu",
    "goSettingsCompany": "Mēs veidojam jūsu uzņēmumu, veltiet mums minūti...",
    "preparingCompany1": "Mēs veidojam jūsu uzņēmumu, lūdzu, veltiet mums minūti...",
    "preparingCompany2": "Mēs gatavojam saskarni preču un pakalpojumu pārvaldībai...",
    "preparingCompany3": "Strādājam pie ērtas finanšu uzskaites...",
    "preparingCompany4": "Veicam pēdējos iestatījumus, gandrīz gatavi...",
    "appleAccIsAlreadyRegistered": "Šis AppleId jau ir saistīts ar citu Skyservice kontu",
    "notGetDataFromApple": "Neizdevās saņemt datus no Apple"
}

export default function() {
    return lang;
}