const lang = {
    "ToAccept": "Akceptować",
    "Reject": "Odrzucić",
    "Password": "Hasło",
    "RepeatPassword": "Powtórz hasło",
    "OldPassword": "stare hasło",
    "MyCompany": "Moje firmy",
    "Company": "Spółka",
    "CreateNewCompany": "Stwórz swoją firmę",
    "Сancel": "Anuluj",
    "Save": "Ratować",
    "GeneralInformation": "informacje ogólne",
    "CompanyName": "Nazwa firmy",
    "LogOut": "Wyloguj",
    "SignOutOfAccount": "Wyloguj się",
    "ChangePassword": "Zmień hasło",
    "AddPassword": "Dodaj hasło",
    "Language": "Język",
    "ConfirmThisMail": "Twój email został potwierdzony",
    "Invites": "zaproszenia",
    "AcceptInvitation": "Przyjąć zaproszenie",
    "EMailOrPhone": "Telefon lub poczta",
    "Saved": "Zapisane",
    "Error": "Błąd",
    "FillInAllTheFields": "Wypełnij wszystkie pola",
    "FirstCreateAnAccountPassword": "Najpierw utwórz hasło do konta",
    "Settings": "Ustawienia",
    "Next": "Dalej",
    "MinPassLength": "Hasło jest za krótkie",
    "action": "Akcja",
    "NewOwner": "Nowy właściciel",
    "UserMustBeRegistered": "Użytkownik musi być zarejestrowany",
    "LeaveTheCompany": "Opuść firmę",
    "Confirm": "Potwierdzać",
    "WrongFormat": "Zły format",
    "DeleteAccount": "Usuń konto",
    "WrongPassword": "Złe hasło",
    "PhoneAlreadyRegistered": "Telefon już zarejestrowany",
    "EmailAlreadyRegistered": "Poczta już zarejestrowana",
    "Yes": "tak",
    "CreateACompany": "Utwórz firmę",
    "MyReferrals": "Moje referencje",
    "Edit": "Zmiana",
    "NewEmail": "Nowa poczta",
    "Proceed": "Kontynuować",
    "IncorrectCaptcha": "błędny kod weryfikacyjny",
    "CaptchaIsNotValid": "Captcha nie jest już ważna",
    "EnterCaptcha": "Wpisz captcha",
    "EnterSMS": "Wpisz SMS-a",
    "SMSCodeIsNoLongerValid": "Kod SMS jest już nieważny",
    "SMSCodeIsIncorrect": "Kod SMS jest nieprawidłowy",
    "MailNotVerifiedLinkTimedOut": "Poczta niepotwierdzona, link wygasł",
    "ReferralLink": "Link referencyjny",
    "Date": "data",
    "EarnWithUs": "Zarabiaj z nami",
    "RecommendSkyservicePOSToYourFriendsAndGetRealMoneyToYourAccount.": "Polecaj Skyservice POS znajomym i otrzymuj prawdziwe pieniądze na swoje konto.",
    "HowItWorks": "Jak to działa?",
    "ItsVerySimpleYouBringCustomersToSkyservicePOSAndWeShareTheirPaymentsWithYou.": "To bardzo proste: przenosisz klientów do Skyservice POS, a my dzielimy się z Tobą ich płatnościami.",
    "Copied": "Skopiowane",
    "TheMinimumWithdrawalAmountIs100USD": "*Minimalna kwota wypłaty 100 USD",
    "AddEmail": "Dodaj pocztę",
    "YouDontHaveCompanyYet": "Nie masz jeszcze firmy",
    "NoCompanyText": "Aby rozpocząć pracę z kasą fiskalną należy założyć firmę lub przyjąć zaproszenie od pracodawcy.",
    "ConfirmEmail": "Wiadomość e-mail została wysłana w celu potwierdzenia zmiany.",
    "description": "Opis",
    "NoDescriptionSpecified": "Bez opisu",
    "DateCreate": "Data utworzenia:",
    "DelComp": "Usuń firmę",
    "Help": "Wsparcie",
    "Email": "Poczta",
    "Phone": "Telefon",
    "SureAccDel": "Czy na pewno chcesz odłączyć swoje konto?",
    "no": "Nie",
    "yes": "tak",
    "confirm": "Potwierdzać",
    "cancel": "Anuluj",
    "Search": "Szukaj",
    "dateDelete": "Data usunięcia:",
    "active": "Aktywny",
    "delete": "Usunięty",
    "services": "Usługi",
    "status": "Status",
    "recover": "Przywróć",
    "restored": "odrestaurowany",
    "restore": "Powrót do zdrowia",
    "DoYouWantRestore": "Czy chcesz przywrócić kampanię",
    "deletedCompamies": "FIRMY ZDALNE",
    "CompanyNotSearch": "Nie znaleziono firm spełniających podane kryteria",
    "writeCorrectlyName": "Wprowadź nazwę użytkownika poprawnie",
    "addlogo": "Dodaj logo",
    "Logo": "Logo",
    "nipmg": "Kliknij lub przeciągnij plik do tego okna",
    "ErrorDownload": "błąd ładowania",
    "preview": "zapowiedź",
    "onlyimg": "Tylko obrazy",
    "imgisbig": "Obraz przekracza limit rozmiaru",
    "minimgsize": "Minimalny rozmiar obrazu",
    "Zagruzka...": "Ładowanie...",
    "Youbrowserisold": "Twoja przeglądarka nie jest obsługiwana, użyj IE10+ lub innej przeglądarki",
    "failZagruzhen": "Plik przesłany pomyślnie!",
    "zakrit": "blisko",
    "nazad": "Z powrotem",
    "save": "Ratować",
    "zagruzit_foto": "Załaduj zdjęcie",
    "clear": "Jasne",
    "logoIsAdd": "Dodano logo",
    "logoIsDel": "Logo wyczyszczone",
    "editComp": "Edytuj firmę",
    "giveComp": "Firma transferowa",
    "thisUserNorRegistered": "Ten użytkownik nie jest zarejestrowany",
    "cantTransferToYou": "Nie możesz przenieść swojej firmy na siebie",
    "youNotOwner": "Nie jesteś właścicielem firmy",
    "Ok": "OK",
    "notYetCreateComp": "W tej chwili tworzenie nowych firm nie jest dostępne",
    "EnterToCompany": "Zaloguj się do firmy",
    "startDescription": "Witaj na swoim koncie osobistym, zaloguj się do firmy, aby rozpocząć",
    "inviteComp": "Zaproszenie do firmy",
    "visitDemo": "Odwiedź firmę demonstracyjną",
    "makeUrChoice": "Dokonać wyboru",
    "ImWorker": "jestem pracownikiem",
    "ImOwner": "Jestem pracodawcą, przedstawicielem pracodawcy",
    "needInviteComp": "Musisz zaakceptować zaproszenie do istniejącej firmy",
    "needCreateComp": "Aby rozpocząć, musisz utworzyć firmę",
    "daysLeft": "Pozostałe dni",
    "cannotDel": "Odinstalowanie jest obecnie niedostępne",
    "NoCompanyCreateText": "Aby rozpocząć pracę z kasą fiskalną należy przyjąć zaproszenie od pracodawcy.",
    "withoutName": "Bez nazwy",
    "cropImageHint": "Kliknij lub przeciągnij plik, aby go przesłać",
    "cropImageNoSupported": "Przeglądarka nie jest obsługiwana, użyj IE10+ lub innych przeglądarek.",
    "cropImageSuccess": "Pobieranie pomyślne",
    "cropImageFail": "Pobieranie nie udane",
    "cropImageOnlyImgNeed": "Tylko obrazy",
    "cropImageOutOfSize": "Rozmiar obrazu przekracza limit",
    "cropImageLowestPx": "Minimalny rozmiar obrazu",
    "uReachedLimitComp": "Osiągnąłeś limit liczby utworzonych firm",
    "accAlreadyRegister": "To konto jest już połączone z innym kontem",
    "accNotRegister": "Nie ma takiego konta",
    "areYourCountry": "czy to jest twój kraj?",
    "enterCorrectNumber": "Wprowadź numer w formacie międzynarodowym",
    "attach": "wiązać",
    "UserEmailAlreadyExists": "Użytkownik z tym adresem email już istnieje",
    "AccountAlreadyLinked": "Konto już połączone",
    "facebookAccIsAlreadyRegistered": "To konto na Facebooku jest już połączone z innym kontem Skyservice",
    "googleAccIsAlreadyRegistered": "To konto Google jest już połączone z innym kontem Skyservice",
    "telegramAccAlreadyRegister": "Ten Telegram jest już połączony z innym kontem Skyservice",
    "companyBlocked": "Ta firma jest nieaktywna",
    "allSessions": "Pokaż wszystkie sesje",
    "activeSessions": "Aktywne Sesje",
    "exitAllDevices": "Wyloguj się na wszystkich innych urządzeniach",
    "currentSession": "Obecna sesja",
    "autoCloseSessions": "Automatyczne uzupełnianie sesji",
    "nonActivePeriod": "Automatycznie zakończ, jeśli sesja nie jest aktywna",
    "oneWeek": "1 tydzień",
    "oneMonth": "1 miesiąc",
    "threeMonth": "3 miesiące",
    "sixMonth": "6 miesięcy",
    "terminate": "Kompletny",
    "sessionDesc1": "Tutaj są wymienione wszystkie urządzenia, które są zalogowane do Twojego konta Skyservice. Możesz wylogować się z konta na każdym z nich z osobna lub ze wszystkich jednocześnie.",
    "sessionDesc2": "Jeśli znajdziesz na liście nieznane urządzenie, natychmiast wyloguj się z tego urządzenia i zmień hasło do swojego konta Skyservice.",
    "sureToTerminate": "Czy na pewno chcesz zakończyć wszystkie aktywne sesje?",
    "ip": "adres IP",
    "geoPos": "Geopozycja",
    "info": "Informacja",
    "sessionDesc3": "Sesje, które nie mają żadnej aktywności w tym okresie, zakończą się automatycznie.",
    "terminatesSessions": "Sesje kończące",
    "more3monthAgo": "Ponad trzy miesiące temu",
    "noData": "Brak danych",
    "goSettingsCompany": "Tworzymy Twoją firmę, daj nam chwilę...",
    "preparingCompany1": "Tworzymy Twoją firmę, poświęć nam chwilę...",
    "preparingCompany2": "Przygotowujemy interfejs do zarządzania towarami i usługami...",
    "preparingCompany3": "Pracujemy nad wygodną księgowością finansową...",
    "preparingCompany4": "Dokonujemy ostatecznych ustawień, prawie gotowe...",
    "appleAccIsAlreadyRegistered": "Ten AppleId jest już połączony z innym kontem Skyservice",
    "notGetDataFromApple": "Nie udało się odebrać danych od Apple"
}

export default function() {
    return lang;
}