const lang = {
    "ToAccept": "Να αποδεχθεί",
    "Reject": "Απορρίπτω",
    "Password": "Κωδικός πρόσβασης",
    "RepeatPassword": "επανάληψη κωδικού πρόσβασης",
    "OldPassword": "ΠΑΛΙΟΣ ΚΩΔΙΚΟΣ",
    "MyCompany": "Οι εταιρείες μου",
    "Company": "Εταιρία",
    "CreateNewCompany": "Δημιουργήστε την εταιρεία σας",
    "Сancel": "Ματαίωση",
    "Save": "Σώσει",
    "GeneralInformation": "γενικές πληροφορίες",
    "CompanyName": "Ονομα εταιρείας",
    "LogOut": "Αποσύνδεση",
    "SignOutOfAccount": "Αποσύνδεση",
    "ChangePassword": "Αλλαξε κωδικό",
    "AddPassword": "Προσθήκη κωδικού πρόσβασης",
    "Language": "Γλώσσα",
    "ConfirmThisMail": "Το email σας επιβεβαιώθηκε",
    "Invites": "προσκλήσεις",
    "AcceptInvitation": "Αποδεχτείτε την πρόσκληση",
    "EMailOrPhone": "Τηλέφωνο ή αλληλογραφία",
    "Saved": "Αποθηκεύτηκε",
    "Error": "Λάθος",
    "FillInAllTheFields": "Συμπληρώστε όλα τα πεδία",
    "FirstCreateAnAccountPassword": "Αρχικά, δημιουργήστε έναν κωδικό πρόσβασης λογαριασμού",
    "Settings": "Ρυθμίσεις",
    "Next": "Περαιτέρω",
    "MinPassLength": "Ο κωδικός πρόσβασης είναι πολύ μικρός",
    "action": "Δράση",
    "NewOwner": "Νέος ιδιοκτήτης",
    "UserMustBeRegistered": "Ο χρήστης πρέπει να είναι εγγεγραμμένος",
    "LeaveTheCompany": "Αφήστε την εταιρεία",
    "Confirm": "Επιβεβαιώνω",
    "WrongFormat": "Λάθος μορφή",
    "DeleteAccount": "Διαγραφή λογαριασμού",
    "WrongPassword": "Λάθος κωδικός",
    "PhoneAlreadyRegistered": "Το τηλέφωνο είναι ήδη καταχωρημένο",
    "EmailAlreadyRegistered": "Η αλληλογραφία έχει ήδη εγγραφεί",
    "Yes": "Ναί",
    "CreateACompany": "Δημιουργήστε μια εταιρεία",
    "MyReferrals": "Οι παραπομπές μου",
    "Edit": "Αλλαγή",
    "NewEmail": "Νέα αλληλογραφία",
    "Proceed": "Να συνεχίσει",
    "IncorrectCaptcha": "Λάθος captcha",
    "CaptchaIsNotValid": "Το Captcha δεν είναι πλέον έγκυρο",
    "EnterCaptcha": "Εισαγάγετε captcha",
    "EnterSMS": "Εισαγάγετε SMS",
    "SMSCodeIsNoLongerValid": "Ο κωδικός SMS δεν είναι πλέον έγκυρος",
    "SMSCodeIsIncorrect": "Ο κωδικός SMS είναι λανθασμένος",
    "MailNotVerifiedLinkTimedOut": "Η αλληλογραφία δεν επιβεβαιώθηκε, ο σύνδεσμος έληξε",
    "ReferralLink": "Παραπομπή Σύνδεσμος",
    "Date": "ημερομηνία της",
    "EarnWithUs": "Κερδίστε μαζί μας",
    "RecommendSkyservicePOSToYourFriendsAndGetRealMoneyToYourAccount.": "Προτείνετε το Skyservice POS στους φίλους σας και αποκτήστε πραγματικά χρήματα στον λογαριασμό σας.",
    "HowItWorks": "Πως δουλεύει?",
    "ItsVerySimpleYouBringCustomersToSkyservicePOSAndWeShareTheirPaymentsWithYou.": "Είναι πολύ απλό: φέρνετε πελάτες στο Skyservice POS και μοιραζόμαστε τις πληρωμές τους μαζί σας.",
    "Copied": "Αντιγράφηκε",
    "TheMinimumWithdrawalAmountIs100USD": "*Ελάχιστο ποσό ανάληψης 100 USD",
    "AddEmail": "Προσθήκη αλληλογραφίας",
    "YouDontHaveCompanyYet": "Δεν έχεις εταιρεία ακόμα",
    "NoCompanyText": "Για να ξεκινήσετε να εργάζεστε με την ταμειακή μηχανή, πρέπει να δημιουργήσετε μια εταιρεία ή να αποδεχτείτε μια πρόσκληση από έναν εργοδότη.",
    "ConfirmEmail": "Έχει σταλεί ένα email για επιβεβαίωση της αλλαγής.",
    "description": "Περιγραφή",
    "NoDescriptionSpecified": "Χωρίς περιγραφή",
    "DateCreate": "Ημερομηνία δημιουργίας:",
    "DelComp": "Διαγραφή εταιρείας",
    "Help": "Υποστήριξη",
    "Email": "Ταχυδρομείο",
    "Phone": "Τηλέφωνο",
    "SureAccDel": "Είστε βέβαιοι ότι θέλετε να αποσυνδέσετε τον λογαριασμό σας;",
    "no": "Δεν",
    "yes": "Ναί",
    "confirm": "Επιβεβαιώνω",
    "cancel": "Ματαίωση",
    "Search": "Αναζήτηση",
    "dateDelete": "Ημερομηνία διαγραφής:",
    "active": "Ενεργός",
    "delete": "Καταργήθηκε",
    "services": "Υπηρεσίες",
    "status": "Κατάσταση",
    "recover": "Ιδρύω πάλι",
    "restored": "ανακαινισμένο",
    "restore": "Ανάκτηση",
    "DoYouWantRestore": "Θέλετε να επαναφέρετε την καμπάνια",
    "deletedCompamies": "ΕΤΑΙΡΙΕΣ ΑΠΟΣΤΑΣΕΙΣ",
    "CompanyNotSearch": "Δεν βρέθηκαν εταιρείες που να ανταποκρίνονται στα κριτήριά σας",
    "writeCorrectlyName": "Εισαγάγετε σωστά το όνομα χρήστη",
    "addlogo": "Προσθήκη λογότυπου",
    "Logo": "Λογότυπο",
    "nipmg": "Κάντε κλικ ή σύρετε ένα αρχείο σε αυτό το παράθυρο",
    "ErrorDownload": "σφάλμα φόρτωσης",
    "preview": "προεπισκόπηση",
    "onlyimg": "Μόνο εικόνες",
    "imgisbig": "Η εικόνα υπερβαίνει το όριο μεγέθους",
    "minimgsize": "Ελάχιστο μέγεθος εικόνας",
    "Zagruzka...": "Φόρτωση...",
    "Youbrowserisold": "Το πρόγραμμα περιήγησής σας δεν υποστηρίζεται, χρησιμοποιήστε IE10+ ή άλλα προγράμματα περιήγησης",
    "failZagruzhen": "Το αρχείο ανέβηκε επιτυχώς!",
    "zakrit": "Κλείσε",
    "nazad": "Πίσω",
    "save": "Σώσει",
    "zagruzit_foto": "Ανέβασε μια φωτογραφία",
    "clear": "Σαφή",
    "logoIsAdd": "Προστέθηκε λογότυπο",
    "logoIsDel": "Το λογότυπο διαγράφηκε",
    "editComp": "Επεξεργασία εταιρείας",
    "giveComp": "Μεταφορική εταιρεία",
    "thisUserNorRegistered": "Αυτός ο χρήστης δεν είναι εγγεγραμμένος",
    "cantTransferToYou": "Δεν μπορείτε να μεταφέρετε την εταιρεία σας στον εαυτό σας",
    "youNotOwner": "Δεν είστε ο ιδιοκτήτης της εταιρείας",
    "Ok": "Εντάξει",
    "notYetCreateComp": "Αυτή τη στιγμή δεν είναι διαθέσιμη η δημιουργία νέων εταιρειών",
    "EnterToCompany": "Είσοδος στην εταιρεία",
    "startDescription": "Καλώς ήρθατε στον προσωπικό σας λογαριασμό, συνδεθείτε στην εταιρεία για να ξεκινήσετε",
    "inviteComp": "Πρόσκληση στην εταιρεία",
    "visitDemo": "Επισκεφτείτε την εταιρεία επίδειξης",
    "makeUrChoice": "Κανε μια επιλογή",
    "ImWorker": "Είμαι υπάλληλος",
    "ImOwner": "Είμαι εργοδότης, εκπρόσωπος του εργοδότη",
    "needInviteComp": "Πρέπει να αποδεχτείτε μια πρόσκληση σε μια υπάρχουσα εταιρεία",
    "needCreateComp": "Πρέπει να δημιουργήσετε μια εταιρεία για να ξεκινήσετε",
    "daysLeft": "Μέρες που απομένουν",
    "cannotDel": "Η απεγκατάσταση δεν είναι διαθέσιμη αυτήν τη στιγμή",
    "NoCompanyCreateText": "Για να ξεκινήσετε να εργάζεστε με την ταμειακή μηχανή, πρέπει να αποδεχτείτε μια πρόσκληση από τον εργοδότη.",
    "withoutName": "ΧΩΡΙΣ ΟΝΟΜΑ",
    "cropImageHint": "Κάντε κλικ ή σύρετε ένα αρχείο για μεταφόρτωση",
    "cropImageNoSupported": "Το πρόγραμμα περιήγησης δεν υποστηρίζεται, χρησιμοποιήστε IE10+ ή άλλα προγράμματα περιήγησης.",
    "cropImageSuccess": "Επιτυχής λήψη",
    "cropImageFail": "Η λήψη απέτυχε",
    "cropImageOnlyImgNeed": "Μόνο εικόνες",
    "cropImageOutOfSize": "Το μέγεθος της εικόνας υπερβαίνει το όριο",
    "cropImageLowestPx": "Ελάχιστο μέγεθος εικόνας",
    "uReachedLimitComp": "Έχετε φτάσει το όριο του αριθμού των εταιρειών που δημιουργήθηκαν",
    "accAlreadyRegister": "Αυτός ο λογαριασμός είναι ήδη συνδεδεμένος με άλλον λογαριασμό",
    "accNotRegister": "Δεν υπάρχει τέτοιος λογαριασμός",
    "areYourCountry": "αυτή είναι η χώρα σας;",
    "enterCorrectNumber": "Εισαγάγετε τον αριθμό σε διεθνή μορφή",
    "attach": "δένω",
    "UserEmailAlreadyExists": "Ο χρήστης με αυτό το email υπάρχει ήδη",
    "AccountAlreadyLinked": "Ο λογαριασμός είναι ήδη συνδεδεμένος",
    "facebookAccIsAlreadyRegistered": "Αυτός ο λογαριασμός Facebook είναι ήδη συνδεδεμένος με άλλον λογαριασμό Skyservice",
    "googleAccIsAlreadyRegistered": "Αυτός ο λογαριασμός Google είναι ήδη συνδεδεμένος με άλλον λογαριασμό Skyservice",
    "telegramAccAlreadyRegister": "Αυτό το Telegram είναι ήδη συνδεδεμένο με άλλο λογαριασμό Skyservice",
    "companyBlocked": "Αυτή η εταιρεία είναι ανενεργή",
    "allSessions": "Εμφάνιση όλων των συνεδριών",
    "activeSessions": "Ενεργές Συνεδρίες",
    "exitAllDevices": "Αποσυνδεθείτε σε όλες τις άλλες συσκευές",
    "currentSession": "Τρέχουσα συνεδρία",
    "autoCloseSessions": "Αυτόματη συμπλήρωση συνεδρίας",
    "nonActivePeriod": "Αυτόματη λήξη εάν η περίοδος λειτουργίας δεν είναι ενεργή",
    "oneWeek": "1 εβδομάδα",
    "oneMonth": "1 μήνα",
    "threeMonth": "3 μήνες",
    "sixMonth": "6 μήνες",
    "terminate": "Πλήρης",
    "sessionDesc1": "Όλες οι συσκευές που είναι συνδεδεμένες στον λογαριασμό σας Skyservice παρατίθενται εδώ. Μπορείτε να αποσυνδεθείτε από τον λογαριασμό σας σε καθένα από αυτά μεμονωμένα ή ταυτόχρονα.",
    "sessionDesc2": "Εάν βρείτε μια άγνωστη συσκευή στη λίστα, αποσυνδεθείτε αμέσως από αυτήν τη συσκευή και αλλάξτε τον κωδικό πρόσβασης για τον λογαριασμό σας Skyservice.",
    "sureToTerminate": "Είστε βέβαιοι ότι θέλετε να τερματίσετε όλες τις ενεργές περιόδους σύνδεσης;",
    "ip": "διεύθυνση IP",
    "geoPos": "Γεωτοποθεσία",
    "info": "Πληροφορίες",
    "sessionDesc3": "Οι περίοδοι σύνδεσης που δεν έχουν καμία δραστηριότητα κατά τη διάρκεια αυτής της περιόδου θα λήξουν αυτόματα.",
    "terminatesSessions": "Τελειώσεις συνεδριών",
    "more3monthAgo": "Πάνω από τρεις μήνες πριν",
    "noData": "Χωρίς δεδομένα",
    "goSettingsCompany": "Δημιουργούμε την εταιρεία σας, δώστε μας ένα λεπτό...",
    "preparingCompany1": "Δημιουργούμε την εταιρεία σας, αφήστε μας ένα λεπτό...",
    "preparingCompany2": "Ετοιμάζουμε μια διεπαφή για τη διαχείριση αγαθών και υπηρεσιών...",
    "preparingCompany3": "Εργαζόμαστε για βολική χρηματοοικονομική λογιστική...",
    "preparingCompany4": "Κάνουμε τις τελικές ρυθμίσεις, σχεδόν έτοιμες...",
    "appleAccIsAlreadyRegistered": "Αυτό το AppleId είναι ήδη συνδεδεμένο με άλλον λογαριασμό Skyservice",
    "notGetDataFromApple": "Αποτυχία λήψης δεδομένων από την Apple"
}

export default function() {
    return lang;
}