const lang = {
    "ToAccept": "To accept",
    "Reject": "Reject",
    "Password": "Password",
    "RepeatPassword": "repeat password",
    "OldPassword": "Old password",
    "MyCompany": "My companies",
    "Company": "Company",
    "CreateNewCompany": "Create a new company",
    "Сancel": "Cancel",
    "Save": "Save",
    "GeneralInformation": "general information",
    "CompanyName": "Company name",
    "LogOut": "Log off",
    "SignOutOfAccount": "Sign out",
    "ChangePassword": "Change password",
    "AddPassword": "Add password",
    "Language": "Language",
    "ConfirmThisMail": "Your email has been confirmed",
    "Invites": "invitations",
    "AcceptInvitation": "Accept invitation",
    "EMailOrPhone": "Phone or mail",
    "Saved": "Saved",
    "Error": "Error",
    "FillInAllTheFields": "Fill in all the fields",
    "FirstCreateAnAccountPassword": "First, create an account password",
    "Settings": "Settings",
    "Next": "Further",
    "MinPassLength": "Password is too short",
    "action": "Action",
    "NewOwner": "New owner",
    "UserMustBeRegistered": "User must be registered",
    "LeaveTheCompany": "Leave the company",
    "Confirm": "Confirm",
    "WrongFormat": "Wrong format",
    "DeleteAccount": "Delete account",
    "WrongPassword": "Wrong password",
    "PhoneAlreadyRegistered": "Phone already registered",
    "EmailAlreadyRegistered": "Mail already registered",
    "Yes": "Yes",
    "CreateACompany": "Create a company",
    "MyReferrals": "My referrals",
    "Edit": "Change",
    "NewEmail": "New mail",
    "Proceed": "Continue",
    "IncorrectCaptcha": "Wrong captcha",
    "CaptchaIsNotValid": "Captcha is no longer valid",
    "EnterCaptcha": "Enter captcha",
    "EnterSMS": "Enter SMS",
    "SMSCodeIsNoLongerValid": "SMS code is no longer valid",
    "SMSCodeIsIncorrect": "SMS code is incorrect",
    "MailNotVerifiedLinkTimedOut": "Mail not confirmed, link expired",
    "ReferralLink": "Referral link",
    "Date": "date of",
    "EarnWithUs": "Earn with us",
    "RecommendSkyservicePOSToYourFriendsAndGetRealMoneyToYourAccount.": "Recommend Skyservice POS to your friends and get real money in your account.",
    "HowItWorks": "How it works?",
    "ItsVerySimpleYouBringCustomersToSkyservicePOSAndWeShareTheirPaymentsWithYou.": "It's very simple: you bring customers to Skyservice POS, and we share their payments with you.",
    "Copied": "Copied",
    "TheMinimumWithdrawalAmountIs100USD": "*Minimum withdrawal amount 100 USD",
    "AddEmail": "Add mail",
    "YouDontHaveCompanyYet": "You don't have a company yet",
    "NoCompanyText": "To start working with the cash register, you need to create a company or accept an invitation from an employer.",
    "ConfirmEmail": "An email has been sent to confirm the change.",
    "description": "Description",
    "NoDescriptionSpecified": "No description",
    "DateCreate": "Date of creation:",
    "DelComp": "Delete company",
    "Help": "Support",
    "Email": "Mail",
    "Phone": "Telephone",
    "SureAccDel": "Are you sure you want to unlink your account?",
    "no": "Not",
    "yes": "Yes",
    "confirm": "Confirm",
    "cancel": "Cancel",
    "Search": "Search",
    "dateDelete": "Deletion date:",
    "active": "Active",
    "delete": "Removed",
    "services": "Services",
    "status": "Status",
    "recover": "Reestablish",
    "restored": "restored",
    "restore": "Recovery",
    "DoYouWantRestore": "Do you want to restore the campaign",
    "deletedCompamies": "REMOTE COMPANIES",
    "CompanyNotSearch": "No companies found matching your criteria",
    "writeCorrectlyName": "Enter username correctly",
    "addlogo": "Add logo",
    "Logo": "Logo",
    "nipmg": "Click or drag a file into this window",
    "ErrorDownload": "loading error",
    "preview": "preview",
    "onlyimg": "Images only",
    "imgisbig": "Image exceeds size limit",
    "minimgsize": "Minimum image size",
    "Zagruzka...": "Loading...",
    "Youbrowserisold": "Your browser is not supported, please use IE10+ or other browsers",
    "failZagruzhen": "File uploaded successfully!",
    "zakrit": "close",
    "nazad": "Back",
    "save": "Save",
    "zagruzit_foto": "Upload a photo",
    "clear": "Clear",
    "logoIsAdd": "Logo added",
    "logoIsDel": "Logo cleared",
    "editComp": "Edit company",
    "giveComp": "Transfer company",
    "thisUserNorRegistered": "This user is not registered",
    "cantTransferToYou": "You cannot transfer your company to yourself",
    "youNotOwner": "You are not the owner of the company",
    "Ok": "OK",
    "notYetCreateComp": "At the moment, the creation of new companies is not available",
    "EnterToCompany": "Login to the company",
    "startDescription": "Welcome to your personal account, login to the company to get started",
    "inviteComp": "Invitation to the company",
    "visitDemo": "Visit demo company",
    "makeUrChoice": "Make a choice",
    "ImWorker": "I am an employee",
    "ImOwner": "I am an employer, employer's representative",
    "needInviteComp": "You must accept an invitation to an existing company",
    "needCreateComp": "You need to create a company to get started",
    "daysLeft": "Days left",
    "cannotDel": "Deletion is currently unavailable",
    "NoCompanyCreateText": "To start working with the cash register, you must accept an invitation from the employer.",
    "withoutName": "No name",
    "cropImageHint": "Click or drag a file to upload",
    "cropImageNoSupported": "Browser not supported, please use IE10+ or other browsers.",
    "cropImageSuccess": "Upload was successful",
    "cropImageFail": "Upload failed",
    "cropImageOnlyImgNeed": "Images only",
    "cropImageOutOfSize": "Image size exceeds the limit",
    "cropImageLowestPx": "Minimum image size",
    "uReachedLimitComp": "You have reached the limit on the number of created companies",
    "accAlreadyRegister": "This account is already linked to another account",
    "accNotRegister": "No such account exists",
    "areYourCountry": "is this your country?",
    "enterCorrectNumber": "Enter number in international format",
    "attach": "Link",
    "UserEmailAlreadyExists": "User with this email already exists",
    "AccountAlreadyLinked": "Account already linked",
    "facebookAccIsAlreadyRegistered": "This Facebook account is already linked to another Skyservice account",
    "googleAccIsAlreadyRegistered": "This Google account is already linked to another Skyservice account",
    "telegramAccAlreadyRegister": "This Telegram is already linked to another Skyservice account",
    "companyBlocked": "This company is inactive",
    "allSessions": "Show all sessions",
    "activeSessions": "Active sessions",
    "exitAllDevices": "Sign out on all other devices",
    "currentSession": "Current session",
    "autoCloseSessions": "Session auto-completion",
    "nonActivePeriod": "Automatically end if the session is not active",
    "oneWeek": "1 Week",
    "oneMonth": "1 month",
    "threeMonth": "3 months",
    "sixMonth": "6 months",
    "terminate": "Complete",
    "sessionDesc1": "All devices that are signed in to your Skyservice account are listed here. You can log out of your account on each of them individually or on all at once.",
    "sessionDesc2": "If you find an unfamiliar device on the list, immediately log out on this device and change the password for your Skyservice account.",
    "sureToTerminate": "Are you sure you want to end all active sessions?",
    "ip": "IP address",
    "geoPos": "Geoposition",
    "info": "Information",
    "sessionDesc3": "Sessions that do not have any activity during this period will end automatically.",
    "terminatesSessions": "Ending sessions",
    "more3monthAgo": "Over three months ago",
    "noData": "No data",
    "goSettingsCompany": "We are creating your company, give us a minute...",
    "preparingCompany1": "We are creating your company, please give us a minute...",
    "preparingCompany2": "We are preparing an interface for managing goods and services...",
    "preparingCompany3": "We are working on convenient financial accounting...",
    "preparingCompany4": "We are making the final settings, almost ready...",
    "appleAccIsAlreadyRegistered": "This AppleId is already linked to another Skyservice account",
    "notGetDataFromApple": "Failed to receive data from Apple"
}

export default function() {
    return lang;
}