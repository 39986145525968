<template>
    <div>
    <smallModal>
        <div>
            <div v-if="ccc != null">
                <div class="buttons-flex-end">
                    <button @click="captchaImg" class="btn">
                        <b-icon-arrow-repeat id="captcha-mail"></b-icon-arrow-repeat>
                    </button>
                </div>
                <div class="imgDiv">
                    <img :src="ccc" class="capImg">
                </div>
                <label :for="captchaName">{{ lang['EnterCaptcha'] }}</label>
                <b-form-input autofocus v-model="form.captcha" :state="capState" :name="captchaName" />
            </div>

            <label for="password">{{ lang['Password'] }}</label>
            <b-form-input id="email-pass-and-capcha-submit" v-model="form.account_password" type="password" :state="stPass" />
            <hr>
            <div class="buttons">
                <button class="btn btn-secondary" @click="$emit('close')">{{ lang['Сancel'] }}</button>
                <button class="btn btn-success save-button" @click="save">{{ lang['Save'] }}</button>
            </div>
        </div>
        </smallModal>
        <smallModal v-if="showSmallModal" @escape="$emit('close')">
                    <div class="center-text">
                        <p>{{ lang['ConfirmEmail'] }}</p>
                    </div>
                    <hr>
                    <div>
                        <button class="btn btn-success ok-button" @click="$emit('close')">{{ lang['Ok'] }}</button>
                    </div>
        </smallModal>
    </div>
</template>

<script>
import { store } from '@/store.js';
import { keyevent } from '@/main.js';
import { errList } from '../js/CaptchaAndPassError';
import { regAPi } from '@/API.js';
import { rotate } from '../js/rotateBtn'
import { guid } from '../js/guid'
const apiList = new regAPi();
import smallModal from '../modals/smallModal'

export default {
    props: ['neweMail'],
    components: {
        smallModal
    },
    data() {
        return {
            showSmallModal: false,
            form: {
                account_password: '',
                captcha: ''
            },
            captchaName:'captcha_'+this.getRandNum(999,99999),
            ccc: null,
            guidNow: null
        }
    },
    created() {
        this.captchaImg();
    },
    mounted() {
        keyevent.$off('enter');
        keyevent.$on('enter', () => this.save());
    },
    methods: {
        getRandNum(min, max) {
            return Math.random() * (max - min) + min;
        },
        captchaImg() {
            try {
                // создаем гуид(файл guid.js) который скидываем для капичи состарта, потом, если юзер нажал сохранить, то скидываем текущий гуид на сервак для сравнения
                const a = guid();
                const c = apiList.captchaUID(a);
                this.ccc = c;
                this.guidNow = a;
                // крутилка для кнопки
                rotate('#captcha-mail')
            } catch(err) {
                console.log(err)
            }
        },
        save() {
            try {
                if (this.stPass && this.capState) {
                    apiList.editEmail({ token: store.token, new_email: this.neweMail.new_email, ...this.form, captcha_guid: this.guidNow }).then(resp => {
                        if (resp.data.status == 'done') {
                            // этот таймаут нужен, что бы юзер увидел надпись "На почту было отправлено письмо для подтверждения изменения" на выбранном им языке 
                            this.showSmallModal = true
                        } else {
                            errList(resp.data.error[0])
                        }
                    })
                } else {
                    window.say.messageError(this.lang['FillInAllTheFields'])
                }
            } catch(err) {
                window.say.messageError(this.lang['Error'])
            }
        }
    },
    computed: {
        lang() {
            return window.lang
        },
        stPass() {
            return this.form.account_password.length >= 1 ? true : false;
        },
        capState() {
            return this.form.captcha.length == 4 ? true : false;
        }
    }
}
</script>

<style scoped>
.btn-secondary, .save-button{
  width: 49%;
}
.buttons{
  display: flex;
  justify-content: space-between;
}
.center-text{
    text-align: center;
}
#captcha-mail{
  height: 2rem; 
  width: 2rem;
}
.buttons-flex-end{
  display: flex; 
  justify-content: flex-end;
}
.imgDiv{
  text-align: center; 
  height: 100px;
}
.capImg{
  height: 100px;
}
.ok-button{
    width: 99%;
}
</style>