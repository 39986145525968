const lang = {
    "ToAccept": "לקבל",
    "Reject": "לִדחוֹת",
    "Password": "סיסמה",
    "RepeatPassword": "חזור על הסיסמה",
    "OldPassword": "סיסמה ישנה",
    "MyCompany": "החברות שלי",
    "Company": "חֶברָה",
    "CreateNewCompany": "צור את החברה שלך",
    "Сancel": "לְבַטֵל",
    "Save": "להציל",
    "GeneralInformation": "מידע כללי",
    "CompanyName": "שם החברה",
    "LogOut": "להתנתק",
    "SignOutOfAccount": "התנתק",
    "ChangePassword": "שנה סיסמא",
    "AddPassword": "הוסף סיסמה",
    "Language": "שפה",
    "ConfirmThisMail": "האימייל שלך אושר",
    "Invites": "הזמנות",
    "AcceptInvitation": "קבל הזמנה",
    "EMailOrPhone": "טלפון או דואר",
    "Saved": "שמור",
    "Error": "שְׁגִיאָה",
    "FillInAllTheFields": "מלא את כל השדות",
    "FirstCreateAnAccountPassword": "ראשית, צור סיסמת חשבון",
    "Settings": "הגדרות",
    "Next": "נוסף",
    "MinPassLength": "הסיסמה קצרה מדי",
    "action": "פעולה",
    "NewOwner": "בעלים חדשים",
    "UserMustBeRegistered": "המשתמש חייב להיות רשום",
    "LeaveTheCompany": "עזוב את החברה",
    "Confirm": "לְאַשֵׁר",
    "WrongFormat": "פורמט שגוי",
    "DeleteAccount": "מחק חשבון",
    "WrongPassword": "סיסמה שגויה",
    "PhoneAlreadyRegistered": "הטלפון כבר רשום",
    "EmailAlreadyRegistered": "דואר כבר רשום",
    "Yes": "כן",
    "CreateACompany": "צור חברה",
    "MyReferrals": "הפניות שלי",
    "Edit": "שינוי",
    "NewEmail": "דואר חדש",
    "Proceed": "לְהַמשִׁיך",
    "IncorrectCaptcha": "טעות בקאפצ'ה",
    "CaptchaIsNotValid": "Captcha כבר לא תקף",
    "EnterCaptcha": "הזן captcha",
    "EnterSMS": "הזן SMS",
    "SMSCodeIsNoLongerValid": "קוד ה-SMS אינו תקף יותר",
    "SMSCodeIsIncorrect": "קוד SMS שגוי",
    "MailNotVerifiedLinkTimedOut": "הדואר לא אושר, הקישור פג",
    "ReferralLink": "קישור להפניה",
    "Date": "תאריך של",
    "EarnWithUs": "תרוויחו איתנו",
    "RecommendSkyservicePOSToYourFriendsAndGetRealMoneyToYourAccount.": "המלץ לחבריך על Skyservice POS וקבל כסף אמיתי בחשבונך.",
    "HowItWorks": "איך זה עובד?",
    "ItsVerySimpleYouBringCustomersToSkyservicePOSAndWeShareTheirPaymentsWithYou.": "זה מאוד פשוט: אתה מביא לקוחות ל-Skyservice POS, ואנחנו חולקים איתך את התשלומים שלהם.",
    "Copied": "מוּעֲתָק",
    "TheMinimumWithdrawalAmountIs100USD": "*סכום משיכה מינימלי 100 דולר",
    "AddEmail": "הוסף דואר",
    "YouDontHaveCompanyYet": "עדיין אין לך חברה",
    "NoCompanyText": "כדי להתחיל לעבוד עם הקופה, צריך ליצור חברה או לקבל הזמנה ממעסיק.",
    "ConfirmEmail": "נשלח אימייל לאישור השינוי.",
    "description": "תיאור",
    "NoDescriptionSpecified": "אין תיאור",
    "DateCreate": "תאריך יצירה:",
    "DelComp": "מחק חברה",
    "Help": "תמיכה",
    "Email": "דוֹאַר",
    "Phone": "טֵלֵפוֹן",
    "SureAccDel": "האם אתה בטוח שברצונך לבטל את קישור החשבון שלך?",
    "no": "לֹא",
    "yes": "כן",
    "confirm": "לְאַשֵׁר",
    "cancel": "לְבַטֵל",
    "Search": "לחפש",
    "dateDelete": "תאריך מחיקה:",
    "active": "פָּעִיל",
    "delete": "הוסר",
    "services": "שירותים",
    "status": "סטָטוּס",
    "recover": "לְהַקִים מִחָדָשׁ",
    "restored": "מְשׁוּחזָר",
    "restore": "התאוששות",
    "DoYouWantRestore": "האם ברצונך לשחזר את הקמפיין",
    "deletedCompamies": "חברות מרוחקות",
    "CompanyNotSearch": "לא נמצאו חברות התואמות לקריטריונים שלך",
    "writeCorrectlyName": "הזן את שם המשתמש בצורה נכונה",
    "addlogo": "הוסף לוגו",
    "Logo": "סֵמֶל",
    "nipmg": "לחץ או גרור קובץ לחלון זה",
    "ErrorDownload": "שגיאת טעינה",
    "preview": "תצוגה מקדימה",
    "onlyimg": "תמונות בלבד",
    "imgisbig": "התמונה חורגת ממגבלת הגודל",
    "minimgsize": "גודל תמונה מינימלי",
    "Zagruzka...": "טעינה...",
    "Youbrowserisold": "הדפדפן שלך אינו נתמך, אנא השתמש ב-IE10+ או בדפדפנים אחרים",
    "failZagruzhen": "הקובץ הועלה בהצלחה!",
    "zakrit": "סגור",
    "nazad": "חזור",
    "save": "להציל",
    "zagruzit_foto": "להעלות תמונה",
    "clear": "ברור",
    "logoIsAdd": "לוגו נוסף",
    "logoIsDel": "הלוגו נוקה",
    "editComp": "ערוך חברה",
    "giveComp": "חברת העברות",
    "thisUserNorRegistered": "משתמש זה אינו רשום",
    "cantTransferToYou": "אתה לא יכול להעביר את החברה שלך לעצמך",
    "youNotOwner": "אתה לא הבעלים של החברה",
    "Ok": "בסדר",
    "notYetCreateComp": "כרגע לא ניתן להקים חברות חדשות",
    "EnterToCompany": "התחבר לחברה",
    "startDescription": "ברוכים הבאים לחשבון האישי שלך, היכנס לחברה כדי להתחיל",
    "inviteComp": "הזמנה לחברה",
    "visitDemo": "בקר בחברת הדגמה",
    "makeUrChoice": "תבחר",
    "ImWorker": "אני שכיר",
    "ImOwner": "אני מעסיק, נציג המעסיק",
    "needInviteComp": "עליך לקבל הזמנה לחברה קיימת",
    "needCreateComp": "אתה צריך להקים חברה כדי להתחיל",
    "daysLeft": "ימים שנותרו",
    "cannotDel": "הסרת ההתקנה אינה זמינה כעת",
    "NoCompanyCreateText": "כדי להתחיל לעבוד עם הקופה יש לקבל הזמנה מהמעסיק.",
    "withoutName": "ללא שם",
    "cropImageHint": "לחץ או גרור קובץ להעלאה",
    "cropImageNoSupported": "הדפדפן אינו נתמך, אנא השתמש ב-IE10+ או בדפדפנים אחרים.",
    "cropImageSuccess": "ההורדה הצליחה",
    "cropImageFail": "הורדה נכשלה",
    "cropImageOnlyImgNeed": "תמונות בלבד",
    "cropImageOutOfSize": "גודל התמונה חורג מהמגבלה",
    "cropImageLowestPx": "גודל תמונה מינימלי",
    "uReachedLimitComp": "הגעת למגבלה של מספר החברות שנוצרו",
    "accAlreadyRegister": "חשבון זה כבר מקושר לחשבון אחר",
    "accNotRegister": "לא קיים חשבון כזה",
    "areYourCountry": "זו המדינה שלך?",
    "enterCorrectNumber": "הזן מספר בפורמט בינלאומי",
    "attach": "לִקְשׁוֹר",
    "UserEmailAlreadyExists": "המשתמש עם האימייל הזה כבר קיים",
    "AccountAlreadyLinked": "החשבון כבר מקושר",
    "facebookAccIsAlreadyRegistered": "חשבון פייסבוק זה כבר מקושר לחשבון Skyservice אחר",
    "googleAccIsAlreadyRegistered": "חשבון Google זה כבר מקושר לחשבון Skyservice אחר",
    "telegramAccAlreadyRegister": "טלגרם זה כבר מקושר לחשבון Skyservice אחר",
    "companyBlocked": "חברה זו אינה פעילה",
    "allSessions": "הצג את כל ההפעלות",
    "activeSessions": "מפגשים אקטיבים",
    "exitAllDevices": "צא בכל שאר המכשירים",
    "currentSession": "מושב נוכחי",
    "autoCloseSessions": "השלמה אוטומטית של הפעלה",
    "nonActivePeriod": "סיום אוטומטית אם ההפעלה לא פעילה",
    "oneWeek": "שבוע 1",
    "oneMonth": "1 חודש",
    "threeMonth": "3 חודשים",
    "sixMonth": "6 חודשים",
    "terminate": "לְהַשְׁלִים",
    "sessionDesc1": "כל המכשירים המחוברים לחשבון Skyservice שלך מפורטים כאן. אתה יכול לצאת מחשבונך על כל אחד מהם בנפרד או על כולם בבת אחת.",
    "sessionDesc2": "אם אתה מוצא מכשיר לא מוכר ברשימה, צא מיד מהמכשיר הזה ושנה את הסיסמה לחשבון Skyservice שלך.",
    "sureToTerminate": "האם אתה בטוח שברצונך לסיים את כל ההפעלות הפעילות?",
    "ip": "כתובת ה - IP",
    "geoPos": "מיקום גיאוגרפי",
    "info": "מֵידָע",
    "sessionDesc3": "מפגשים שאין בהם פעילות במהלך תקופה זו יסתיימו אוטומטית.",
    "terminatesSessions": "סיום הפעלות",
    "more3monthAgo": "לפני יותר משלושה חודשים",
    "noData": "אין מידע",
    "goSettingsCompany": "אנחנו יוצרים את החברה שלך, תן לנו דקה...",
    "preparingCompany1": "אנחנו יוצרים את החברה שלך, בבקשה תן לנו דקה...",
    "preparingCompany2": "אנו מכינים ממשק לניהול סחורות ושירותים...",
    "preparingCompany3": "אנו עובדים על חשבונאות פיננסית נוחה...",
    "preparingCompany4": "אנחנו עושים את ההגדרות הסופיות, כמעט מוכנות...",
    "appleAccIsAlreadyRegistered": "AppleId זה כבר מקושר לחשבון Skyservice אחר",
    "notGetDataFromApple": "נכשל קבלת נתונים מאפל"
}

export default function() {
    return lang;
}