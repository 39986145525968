<template>
    <bigmodal @close="$emit('close')" :title="lang['MyReferrals']">
        {{ form }}
    </bigmodal>
</template>

<script>
import { store } from '@/store.js';
import bigmodal from '../modals/bigModal'
import { regAPi } from '@/API.js';

const apiList = new regAPi();
export default {
    props: ['accId'],
    components: {
        bigmodal
    },
    data() {
        return {
            form: null
        }
    },
    created() {
        this.getData()
    },
    methods: {
        getData() {
            try {
                apiList.refData({ token: store.token, account_id: this.accId }).then(resp => {
                    this.form = resp.data.data;
                })
            } catch(err) {
                console.log(err)
            }
        }
    },
    computed: {
        lang() {
            return window.lang;
        }
    }
}
</script>