<template>
    <smallModal @escape="$emit('close')">
      <div class="inputs-div">
        <div>
          <label :for="oldPassword">{{ lang['OldPassword'] }}</label>
          <b-form-input v-model="oldPassword" type="password" :state="oldPass" autofocus />
        </div>
        <hr />
        <div>
          <label :for="password">{{ lang['Password'] }}</label>
          <b-form-input v-model="password" type="password" :state="pass1" />
          <label :for="password2">{{ lang['RepeatPassword'] }}</label>
          <b-form-input v-model="password2" type="password" :state="pass2" />
        </div>
      </div>
      <hr />
      <div class="buttons">
        <button class="btn btn-secondary" @click="cancel">{{ lang['Сancel'] }}</button>
        <button class="btn btn-success" @click="save" :disabled="!checkInputs">{{ lang['Save'] }}</button>
      </div>
    </smallModal>
  </template>
  <script>
  import { store } from '@/store.js';
  import { keyevent } from '@/main.js';
  import smallModal from '../modals/smallModal';
  import { regAPi } from '@/API.js';
  
  const apiList = new regAPi();
  
  export default {
    components: {
      smallModal
    },
    data() {
      return {
        oldPassword: '',
        password: '',
        password2: ''
      }
    },
    mounted() {
      keyevent.$off('enter');
      keyevent.$on('enter', () => this.save());
    },
    methods: {
      cancel() {
        this.$emit('close')
      },
      save() {
        if (this.checkInputs) {
          try {
            const obj = {
              token: store.token,
              old_password: this.oldPassword,
              new_password: this.password
            }
            apiList.rePassword(obj).then(resp => {
              if (resp.data.status === 'done') {
                window.say.message(this.lang['Saved']);
                this.$emit('close')
              } else if (resp.data.error[0] === 'pass is incorrect') {
                window.say.messageError(this.lang['WrongPassword']);
              } else {
                window.say.messageError(this.lang['Error']);
              }
            })
          } catch (err) {
            window.say.messageError(this.lang['Error']);
          }
  
        } else {
          window.say.messageError(this.lang['FillInAllTheFields']);
        }
      }
    },
    computed: {
      country() {
        return 'UA';
      },
      lang() {
        return window.lang
      },
      oldPass() {
        return this.oldPassword.length >= 4;
      },
      pass1() {
        return this.password.length >= 4;
      },
      pass2() {
        return this.password2.length > 0 && this.password2 === this.password;
      },
      checkInputs() {
        return this.oldPass && this.pass1 && this.pass2;
      }
    }
  }
</script>

<style scoped>
    .btn-secondary, .btn-success{
        width: 49%;
    }
    .buttons{
        display: flex;
        justify-content: space-between;
    }
    .modal-lair {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .phone-container {
        width: 50%;
        background: #fff;
        padding: 15px;
        border-radius: 0.25rem;
    }
    @media screen and (max-width: 700px) {
        .phone-container {
            width: 90%;
        }
    }
</style>