const lang = {
    "ToAccept": "A accepta",
    "Reject": "Respinge",
    "Password": "Parola",
    "RepeatPassword": "repeta parola",
    "OldPassword": "Parola veche",
    "MyCompany": "Companiile mele",
    "Company": "Companie",
    "CreateNewCompany": "Creați-vă compania",
    "Сancel": "Anulare",
    "Save": "salva",
    "GeneralInformation": "informatii generale",
    "CompanyName": "Numele Companiei",
    "LogOut": "Ieşi din cont",
    "SignOutOfAccount": "Sign out",
    "ChangePassword": "Schimbaţi parola",
    "AddPassword": "Adăugați parola",
    "Language": "Limba",
    "ConfirmThisMail": "E-mailul dvs. a fost confirmat",
    "Invites": "invitatii",
    "AcceptInvitation": "Accepta invitatia",
    "EMailOrPhone": "Telefon sau poștă",
    "Saved": "Salvat",
    "Error": "Eroare",
    "FillInAllTheFields": "Completați toate câmpurile",
    "FirstCreateAnAccountPassword": "Mai întâi, creați o parolă de cont",
    "Settings": "Setări",
    "Next": "Mai departe",
    "MinPassLength": "Parola este prea scurtă",
    "action": "Acțiune",
    "NewOwner": "Noul proprietar",
    "UserMustBeRegistered": "Utilizatorul trebuie să fie înregistrat",
    "LeaveTheCompany": "Părăsiți compania",
    "Confirm": "A confirma",
    "WrongFormat": "Format gresit",
    "DeleteAccount": "Șterge cont",
    "WrongPassword": "Parola gresita",
    "PhoneAlreadyRegistered": "Telefon deja înregistrat",
    "EmailAlreadyRegistered": "E-mail deja înregistrat",
    "Yes": "da",
    "CreateACompany": "Creați o companie",
    "MyReferrals": "Recomandările mele",
    "Edit": "Schimbare",
    "NewEmail": "E-mail nou",
    "Proceed": "Continua",
    "IncorrectCaptcha": "cod captcha gresit",
    "CaptchaIsNotValid": "Captcha nu mai este valabil",
    "EnterCaptcha": "Introduceți captcha",
    "EnterSMS": "Introdu SMS",
    "SMSCodeIsNoLongerValid": "Codul SMS nu mai este valid",
    "SMSCodeIsIncorrect": "Codul SMS este incorect",
    "MailNotVerifiedLinkTimedOut": "Mail nu a fost confirmat, linkul a expirat",
    "ReferralLink": "Link de referința",
    "Date": "data de",
    "EarnWithUs": "Câștigă cu noi",
    "RecommendSkyservicePOSToYourFriendsAndGetRealMoneyToYourAccount.": "Recomandați Skyservice POS prietenilor dvs. și obțineți bani reali în contul dvs.",
    "HowItWorks": "Cum functioneaza?",
    "ItsVerySimpleYouBringCustomersToSkyservicePOSAndWeShareTheirPaymentsWithYou.": "Este foarte simplu: aduci clienți la Skyservice POS, iar plățile lor le împărtășim cu tine.",
    "Copied": "Copiat",
    "TheMinimumWithdrawalAmountIs100USD": "*Suma minimă de retragere 100 USD",
    "AddEmail": "Adăugați e-mail",
    "YouDontHaveCompanyYet": "Nu ai încă o companie",
    "NoCompanyText": "Pentru a începe să lucrați cu casa de marcat, trebuie să creați o companie sau să acceptați o invitație de la un angajator.",
    "ConfirmEmail": "A fost trimis un e-mail pentru a confirma modificarea.",
    "description": "Descriere",
    "NoDescriptionSpecified": "fără descriere",
    "DateCreate": "Data creării:",
    "DelComp": "Ștergeți compania",
    "Help": "A sustine",
    "Email": "Poștă",
    "Phone": "Telefon",
    "SureAccDel": "Sigur doriți să vă deconectați contul?",
    "no": "Nu",
    "yes": "da",
    "confirm": "A confirma",
    "cancel": "Anulare",
    "Search": "Căutare",
    "dateDelete": "Data ștergerii:",
    "active": "Activ",
    "delete": "Îndepărtat",
    "services": "Servicii",
    "status": "stare",
    "recover": "Restabili",
    "restored": "restaurat",
    "restore": "Recuperare",
    "DoYouWantRestore": "Doriți să restabiliți campania",
    "deletedCompamies": "COMPANII DE LA DISTANTA",
    "CompanyNotSearch": "Nu s-au găsit companii care să corespundă criteriilor dvs",
    "writeCorrectlyName": "Introduceți corect numele de utilizator",
    "addlogo": "Adăugați sigla",
    "Logo": "Siglă",
    "nipmg": "Faceți clic sau trageți un fișier în această fereastră",
    "ErrorDownload": "eroare de încărcare",
    "preview": "previzualizare",
    "onlyimg": "Numai imagini",
    "imgisbig": "Imaginea depășește limita de dimensiune",
    "minimgsize": "Dimensiunea minimă a imaginii",
    "Zagruzka...": "Se încarcă...",
    "Youbrowserisold": "Browserul dvs. nu este acceptat, vă rugăm să utilizați IE10+ sau alte browsere",
    "failZagruzhen": "Fisier uploadat cu succes!",
    "zakrit": "închide",
    "nazad": "Înapoi",
    "save": "salva",
    "zagruzit_foto": "Încarcă o poză",
    "clear": "clar",
    "logoIsAdd": "Logo-ul a fost adăugat",
    "logoIsDel": "Logo-ul a fost șters",
    "editComp": "Editați compania",
    "giveComp": "Companie de transfer",
    "thisUserNorRegistered": "Acest utilizator nu este înregistrat",
    "cantTransferToYou": "Nu vă puteți transfera compania către dvs",
    "youNotOwner": "Nu sunteți proprietarul companiei",
    "Ok": "Bine",
    "notYetCreateComp": "Momentan, crearea de noi companii nu este disponibilă",
    "EnterToCompany": "Conectați-vă la companie",
    "startDescription": "Bine ați venit la contul dvs. personal, conectați-vă la companie pentru a începe",
    "inviteComp": "Invitație la companie",
    "visitDemo": "Vizitați compania demo",
    "makeUrChoice": "Fa o alegere",
    "ImWorker": "Sunt angajat",
    "ImOwner": "Sunt angajator, reprezentant al angajatorului",
    "needInviteComp": "Trebuie să acceptați o invitație la o companie existentă",
    "needCreateComp": "Trebuie să creați o companie pentru a începe",
    "daysLeft": "Zile rămase",
    "cannotDel": "Dezinstalarea este momentan indisponibilă",
    "NoCompanyCreateText": "Pentru a începe să lucrați cu casa de marcat, trebuie să acceptați o invitație din partea angajatorului.",
    "withoutName": "Fara nume",
    "cropImageHint": "Faceți clic sau trageți un fișier pentru a încărca",
    "cropImageNoSupported": "Browserul nu este acceptat, vă rugăm să utilizați IE10+ sau alte browsere.",
    "cropImageSuccess": "Descărcarea cu succes",
    "cropImageFail": "Descarcare esuata",
    "cropImageOnlyImgNeed": "Numai imagini",
    "cropImageOutOfSize": "Dimensiunea imaginii depășește limita",
    "cropImageLowestPx": "Dimensiunea minimă a imaginii",
    "uReachedLimitComp": "Ați atins limita numărului de companii create",
    "accAlreadyRegister": "Acest cont este deja conectat la un alt cont",
    "accNotRegister": "Nu există un astfel de cont",
    "areYourCountry": "aceasta este tara ta?",
    "enterCorrectNumber": "Introduceți numărul în format internațional",
    "attach": "lega",
    "UserEmailAlreadyExists": "Utilizatorul cu acest e-mail există deja",
    "AccountAlreadyLinked": "Cont deja conectat",
    "facebookAccIsAlreadyRegistered": "Acest cont Facebook este deja conectat la un alt cont Skyservice",
    "googleAccIsAlreadyRegistered": "Acest cont Google este deja conectat la un alt cont Skyservice",
    "telegramAccAlreadyRegister": "Această Telegramă este deja conectată la un alt cont Skyservice",
    "companyBlocked": "Această companie este inactivă",
    "allSessions": "Afișați toate sesiunile",
    "activeSessions": "Sesiuni active",
    "exitAllDevices": "Deconectați-vă de pe toate celelalte dispozitive",
    "currentSession": "Sesiunea curenta",
    "autoCloseSessions": "Completarea automată a sesiunii",
    "nonActivePeriod": "Încheiați automat dacă sesiunea nu este activă",
    "oneWeek": "1 săptămână",
    "oneMonth": "1 lună",
    "threeMonth": "3 luni",
    "sixMonth": "6 luni",
    "terminate": "Complet",
    "sessionDesc1": "Toate dispozitivele care sunt conectate la contul dvs. Skyservice sunt listate aici. Vă puteți deconecta de la contul dvs. pentru fiecare dintre ele individual sau pe toate simultan.",
    "sessionDesc2": "Dacă găsiți un dispozitiv necunoscut pe listă, deconectați-vă imediat de pe acest dispozitiv și schimbați parola pentru contul dvs. Skyservice.",
    "sureToTerminate": "Sigur doriți să încheiați toate sesiunile active?",
    "ip": "adresa IP",
    "geoPos": "Geopoziție",
    "info": "informație",
    "sessionDesc3": "Sesiunile care nu au activitate în această perioadă se vor încheia automat.",
    "terminatesSessions": "Încheierea sesiunilor",
    "more3monthAgo": "Acum peste trei luni",
    "noData": "Nu există date",
    "goSettingsCompany": "Îți creăm compania, acordă-ne un minut...",
    "preparingCompany1": "Vă creăm compania, vă rugăm să ne acordați un minut...",
    "preparingCompany2": "Pregătim o interfață pentru gestionarea bunurilor și serviciilor...",
    "preparingCompany3": "Lucrăm la o contabilitate financiară convenabilă...",
    "preparingCompany4": "Facem setările finale, aproape gata...",
    "appleAccIsAlreadyRegistered": "Acest AppleId este deja conectat la un alt cont Skyservice",
    "notGetDataFromApple": "Nu s-a putut primi date de la Apple"
}

export default function() {
    return lang;
}