//import { store } from "@/store";

export const wsMessageHandler = (message) => {
  console.log("message",message)
  //store.settings.serverSync = "onlyPush"; //"off", "full", "onlyPush"
  try {
      if (message.data) {
        // switch (message.data.action) {
          
        // }
      }
  } catch (err) {
    console.log(err)
  }
}
