const lang = {
    "ToAccept": "لقبول",
    "Reject": "يرفض",
    "Password": "كلمة المرور",
    "RepeatPassword": "اعد كلمة السر",
    "OldPassword": "كلمة المرور القديمة",
    "MyCompany": "شركاتي",
    "Company": "شركة",
    "CreateNewCompany": "أنشئ شركتك",
    "Сancel": "يلغي",
    "Save": "يحفظ",
    "GeneralInformation": "معلومات عامة",
    "CompanyName": "اسم الشركة",
    "LogOut": "إطفاء",
    "SignOutOfAccount": "خروج",
    "ChangePassword": "غير كلمة السر",
    "AddPassword": "أضف كلمة المرور",
    "Language": "لغة",
    "ConfirmThisMail": "تم تأكيد بريدك الإلكتروني",
    "Invites": "الدعوات",
    "AcceptInvitation": "قبول دعوة",
    "EMailOrPhone": "الهاتف أو البريد",
    "Saved": "أنقذ",
    "Error": "خطأ",
    "FillInAllTheFields": "املأ جميع الحقول",
    "FirstCreateAnAccountPassword": "أولاً ، قم بإنشاء كلمة مرور للحساب",
    "Settings": "إعدادات",
    "Next": "إضافي",
    "MinPassLength": "كلمة المرور قصيرة جدا",
    "action": "فعل",
    "NewOwner": "مالك جديد",
    "UserMustBeRegistered": "يجب أن يكون المستخدم مسجلا",
    "LeaveTheCompany": "اترك الشركة",
    "Confirm": "يتأكد",
    "WrongFormat": "صيغة خاطئة",
    "DeleteAccount": "حذف الحساب",
    "WrongPassword": "كلمة مرور خاطئة",
    "PhoneAlreadyRegistered": "الهاتف مسجل بالفعل",
    "EmailAlreadyRegistered": "البريد مسجل بالفعل",
    "Yes": "نعم",
    "CreateACompany": "أنشئ شركة",
    "MyReferrals": "إحالاتي",
    "Edit": "يتغيرون",
    "NewEmail": "بريد جديد",
    "Proceed": "يكمل",
    "IncorrectCaptcha": "كلمة التحقق خاطئة",
    "CaptchaIsNotValid": "لم تعد كلمة التحقق صالحة",
    "EnterCaptcha": "أدخل كلمة التحقق",
    "EnterSMS": "أدخل الرسائل القصيرة",
    "SMSCodeIsNoLongerValid": "لم يعد رمز الرسائل القصيرة صالحًا",
    "SMSCodeIsIncorrect": "رمز SMS غير صحيح",
    "MailNotVerifiedLinkTimedOut": "لم يتم تأكيد البريد ، انتهت صلاحية الرابط",
    "ReferralLink": "رابط الإحالة",
    "Date": "تاريخ",
    "EarnWithUs": "اكسب معنا",
    "RecommendSkyservicePOSToYourFriendsAndGetRealMoneyToYourAccount.": "أوصي بـ Skyservice POS لأصدقائك واحصل على أموال حقيقية في حسابك.",
    "HowItWorks": "كيف تعمل؟",
    "ItsVerySimpleYouBringCustomersToSkyservicePOSAndWeShareTheirPaymentsWithYou.": "الأمر بسيط للغاية: أنت تجلب العملاء إلى Skyservice POS ، ونحن نشارك مدفوعاتهم معك.",
    "Copied": "نسخ",
    "TheMinimumWithdrawalAmountIs100USD": "* الحد الأدنى للسحب 100 دولار أمريكي",
    "AddEmail": "أضف بريدًا",
    "YouDontHaveCompanyYet": "ليس لديك شركة بعد",
    "NoCompanyText": "لبدء العمل مع ماكينة تسجيل المدفوعات النقدية ، تحتاج إلى إنشاء شركة أو قبول دعوة من صاحب العمل.",
    "ConfirmEmail": "تم إرسال بريد إلكتروني لتأكيد التغيير.",
    "description": "وصف",
    "NoDescriptionSpecified": "بدون وصف",
    "DateCreate": "تاريخ الخلق:",
    "DelComp": "حذف الشركة",
    "Help": "الدعم",
    "Email": "بريد",
    "Phone": "هاتف",
    "SureAccDel": "هل أنت متأكد أنك تريد إلغاء ربط حسابك؟",
    "no": "لا",
    "yes": "نعم",
    "confirm": "يتأكد",
    "cancel": "يلغي",
    "Search": "يبحث",
    "dateDelete": "تاريخ الحذف:",
    "active": "نشيط",
    "delete": "إزالة",
    "services": "خدمات",
    "status": "حالة",
    "recover": "إعادة",
    "restored": "رمم",
    "restore": "استعادة",
    "DoYouWantRestore": "هل تريد استعادة الحملة",
    "deletedCompamies": "الشركات البعيدة",
    "CompanyNotSearch": "لا توجد شركات مطابقة للمعايير الخاصة بك",
    "writeCorrectlyName": "أدخل اسم المستخدم بشكل صحيح",
    "addlogo": "أضف الشعار",
    "Logo": "شعار",
    "nipmg": "انقر أو اسحب ملف إلى هذه النافذة",
    "ErrorDownload": "خطأ في التحميل",
    "preview": "معاينة",
    "onlyimg": "الصور فقط",
    "imgisbig": "الصورة تتجاوز الحد الأقصى للحجم",
    "minimgsize": "الحد الأدنى لحجم الصورة",
    "Zagruzka...": "جار التحميل...",
    "Youbrowserisold": "المستعرض الخاص بك غير مدعوم ، الرجاء استخدام IE10 + أو متصفحات أخرى",
    "failZagruzhen": "تم رفع الملف بنجاح!",
    "zakrit": "أغلق",
    "nazad": "خلف",
    "save": "يحفظ",
    "zagruzit_foto": "حمل الصورة",
    "clear": "صافي",
    "logoIsAdd": "تمت إضافة الشعار",
    "logoIsDel": "تم مسح الشعار",
    "editComp": "تحرير الشركة",
    "giveComp": "شركة نقل",
    "thisUserNorRegistered": "هذا المستخدم غير مسجل",
    "cantTransferToYou": "لا يمكنك نقل شركتك إلى نفسك",
    "youNotOwner": "أنت لست صاحب الشركة",
    "Ok": "نعم",
    "notYetCreateComp": "في الوقت الحالي ، إنشاء شركات جديدة غير متاح",
    "EnterToCompany": "تسجيل الدخول إلى الشركة",
    "startDescription": "مرحبًا بك في حسابك الشخصي ، قم بتسجيل الدخول إلى الشركة للبدء",
    "inviteComp": "دعوة الى الشركة",
    "visitDemo": "قم بزيارة الشركة التجريبية",
    "makeUrChoice": "أختار",
    "ImWorker": "انا موظف",
    "ImOwner": "أنا صاحب عمل ، ممثل صاحب العمل",
    "needInviteComp": "يجب عليك قبول دعوة لشركة موجودة",
    "needCreateComp": "تحتاج إلى إنشاء شركة لتبدأ",
    "daysLeft": "ايام متبقية",
    "cannotDel": "إلغاء التثبيت غير متوفر حاليا",
    "NoCompanyCreateText": "لبدء العمل مع ماكينة تسجيل المدفوعات النقدية ، يجب عليك قبول دعوة من صاحب العمل.",
    "withoutName": "بدون اسم",
    "cropImageHint": "انقر أو اسحب ملف للتحميل",
    "cropImageNoSupported": "المتصفح غير مدعوم ، يرجى استخدام IE10 + أو متصفحات أخرى.",
    "cropImageSuccess": "تم التنزيل بنجاح",
    "cropImageFail": "التحميل فشل",
    "cropImageOnlyImgNeed": "الصور فقط",
    "cropImageOutOfSize": "حجم الصورة يتجاوز الحد",
    "cropImageLowestPx": "الحد الأدنى لحجم الصورة",
    "uReachedLimitComp": "لقد وصلت إلى الحد الأقصى لعدد الشركات التي تم إنشاؤها",
    "accAlreadyRegister": "هذا الحساب مرتبط بالفعل بحساب آخر",
    "accNotRegister": "لا يوجد مثل هذا الحساب",
    "areYourCountry": "هل هذا بلدك",
    "enterCorrectNumber": "أدخل الرقم بالصيغة الدولية",
    "attach": "ربط",
    "UserEmailAlreadyExists": "المستخدم مع هذا البريد الإلكتروني موجود بالفعل",
    "AccountAlreadyLinked": "الحساب مرتبط بالفعل",
    "facebookAccIsAlreadyRegistered": "حساب Facebook هذا مرتبط بالفعل بحساب Skyservice آخر",
    "googleAccIsAlreadyRegistered": "حساب Google هذا مرتبط بالفعل بحساب Skyservice آخر",
    "telegramAccAlreadyRegister": "هذا Telegram مرتبط بالفعل بحساب Skyservice آخر",
    "companyBlocked": "هذه الشركة غير نشطة",
    "allSessions": "عرض كل الجلسات",
    "activeSessions": "جلسات نشطة",
    "exitAllDevices": "تسجيل الخروج على جميع الأجهزة الأخرى",
    "currentSession": "الجلسة الحالية",
    "autoCloseSessions": "الإكمال التلقائي للجلسة",
    "nonActivePeriod": "إنهاء تلقائيًا إذا كانت الجلسة غير نشطة",
    "oneWeek": "أسبوع 1",
    "oneMonth": "شهر واحد",
    "threeMonth": "3 اشهر",
    "sixMonth": "6 اشهر",
    "terminate": "مكتمل",
    "sessionDesc1": "جميع الأجهزة التي تم تسجيل دخولها إلى حساب Skyservice الخاص بك مذكورة هنا. يمكنك تسجيل الخروج من حسابك على كل منهم على حدة أو على كل منهم مرة واحدة.",
    "sessionDesc2": "إذا وجدت جهازًا غير مألوف في القائمة ، فقم بتسجيل الخروج فورًا من هذا الجهاز وتغيير كلمة المرور لحساب Skyservice الخاص بك.",
    "sureToTerminate": "هل أنت متأكد أنك تريد إنهاء جميع الجلسات النشطة؟",
    "ip": "عنوان IP",
    "geoPos": "الموقع الجغرافي",
    "info": "معلومة",
    "sessionDesc3": "ستنتهي الجلسات التي ليس لها أي نشاط خلال هذه الفترة تلقائيًا.",
    "terminatesSessions": "الجلسات الختامية",
    "more3monthAgo": "منذ أكثر من ثلاثة أشهر",
    "noData": "لايوجد بيانات",
    "goSettingsCompany": "نحن ننشئ شركتك، امنحنا دقيقة...",
    "preparingCompany1": "نحن نقوم بإنشاء شركتك، من فضلك أعطنا دقيقة...",
    "preparingCompany2": "نقوم بإعداد واجهة لإدارة السلع والخدمات...",
    "preparingCompany3": "نحن نعمل على المحاسبة المالية المريحة...",
    "preparingCompany4": "نحن نجري الإعدادات النهائية، وهي جاهزة تقريبًا...",
    "appleAccIsAlreadyRegistered": "تم ربط AppleId هذا بالفعل بحساب Skyservice آخر",
    "notGetDataFromApple": "فشل في تلقي البيانات من أبل"
}

export default function() {
    return lang;
}