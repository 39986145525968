const lang = {
    "ToAccept": "Kabul etmek",
    "Reject": "Reddetmek",
    "Password": "Parola",
    "RepeatPassword": "Şifreyi tekrar girin",
    "OldPassword": "eski şifre",
    "MyCompany": "şirketlerim",
    "Company": "Şirket",
    "CreateNewCompany": "Şirketinizi oluşturun",
    "Сancel": "İptal",
    "Save": "Kayıt etmek",
    "GeneralInformation": "Genel bilgi",
    "CompanyName": "Şirket Adı",
    "LogOut": "Oturumu Kapat",
    "SignOutOfAccount": "oturumu Kapat",
    "ChangePassword": "Şifre değiştir",
    "AddPassword": "şifre ekle",
    "Language": "Dil",
    "ConfirmThisMail": "E-posta teyit edilmiştir",
    "Invites": "davetiyeler",
    "AcceptInvitation": "Daveti kabul etmek",
    "EMailOrPhone": "Telefon veya posta",
    "Saved": "kaydedildi",
    "Error": "Hata",
    "FillInAllTheFields": "tüm alanları doldurun",
    "FirstCreateAnAccountPassword": "İlk olarak, bir hesap şifresi oluşturun",
    "Settings": "Ayarlar",
    "Next": "Daha ileri",
    "MinPassLength": "Şifre çok kısa",
    "action": "Eylem",
    "NewOwner": "Yeni sahibi",
    "UserMustBeRegistered": "Kullanıcı kayıtlı olmalıdır",
    "LeaveTheCompany": "Şirketten ayrılmak",
    "Confirm": "Teyit etmek",
    "WrongFormat": "Yanlış format",
    "DeleteAccount": "Hesabı sil",
    "WrongPassword": "Yanlış şifre",
    "PhoneAlreadyRegistered": "Telefon zaten kayıtlı",
    "EmailAlreadyRegistered": "Posta zaten kayıtlı",
    "Yes": "Evet",
    "CreateACompany": "şirket oluştur",
    "MyReferrals": "Benim referansım",
    "Edit": "Değiştirmek",
    "NewEmail": "Yeni posta",
    "Proceed": "Devam et",
    "IncorrectCaptcha": "yanlış CAPTCHA",
    "CaptchaIsNotValid": "Captcha artık geçerli değil",
    "EnterCaptcha": "captcha'yı girin",
    "EnterSMS": "SMS girin",
    "SMSCodeIsNoLongerValid": "SMS kodu artık geçerli değil",
    "SMSCodeIsIncorrect": "SMS kodu yanlış",
    "MailNotVerifiedLinkTimedOut": "Posta onaylanmadı, bağlantının süresi doldu",
    "ReferralLink": "Yönlendirme bağlantısı",
    "Date": "Tarihi",
    "EarnWithUs": "bizimle kazanın",
    "RecommendSkyservicePOSToYourFriendsAndGetRealMoneyToYourAccount.": "Skyservice POS'u arkadaşlarınıza önerin ve hesabınızda gerçek para kazanın.",
    "HowItWorks": "Nasıl çalışır?",
    "ItsVerySimpleYouBringCustomersToSkyservicePOSAndWeShareTheirPaymentsWithYou.": "Çok basit: siz müşterileri Skyservice POS'a getirin, biz de onların ödemelerini sizinle paylaşalım.",
    "Copied": "kopyalandı",
    "TheMinimumWithdrawalAmountIs100USD": "*Minimum para çekme tutarı 100 USD",
    "AddEmail": "posta ekle",
    "YouDontHaveCompanyYet": "Henüz bir şirketin yok",
    "NoCompanyText": "Yazar kasa ile çalışmaya başlamak için bir şirket kurmanız veya bir işverenin davetini kabul etmeniz gerekir.",
    "ConfirmEmail": "Değişikliği onaylamak için bir e-posta gönderildi.",
    "description": "Açıklama",
    "NoDescriptionSpecified": "açıklama yok",
    "DateCreate": "Oluşturma tarihi:",
    "DelComp": "Şirketi sil",
    "Help": "Destek olmak",
    "Email": "Posta",
    "Phone": "Telefon",
    "SureAccDel": "Hesabınızın bağlantısını kaldırmak istediğinizden emin misiniz?",
    "no": "Olumsuzluk",
    "yes": "Evet",
    "confirm": "Teyit etmek",
    "cancel": "İptal",
    "Search": "Arama",
    "dateDelete": "Silme tarihi:",
    "active": "Aktif",
    "delete": "Kaldırıldı",
    "services": "Hizmetler",
    "status": "Durum",
    "recover": "yeniden kurmak",
    "restored": "restore edilmiş",
    "restore": "İyileşmek",
    "DoYouWantRestore": "Kampanyayı geri yüklemek istiyor musunuz?",
    "deletedCompamies": "UZAK ŞİRKETLER",
    "CompanyNotSearch": "Aradığınız kriterlere uygun firma bulunamadı",
    "writeCorrectlyName": "Kullanıcı adını doğru girin",
    "addlogo": "logo ekle",
    "Logo": "Logo",
    "nipmg": "Bu pencereye bir dosyayı tıklayın veya sürükleyin",
    "ErrorDownload": "yükleme hatası",
    "preview": "Ön izleme",
    "onlyimg": "Yalnızca resimler",
    "imgisbig": "Resim boyut sınırını aşıyor",
    "minimgsize": "Minimum resim boyutu",
    "Zagruzka...": "Yükleniyor...",
    "Youbrowserisold": "Tarayıcınız desteklenmiyor, lütfen IE10+ veya diğer tarayıcıları kullanın",
    "failZagruzhen": "Dosya başarıyla yüklendi!",
    "zakrit": "kapat",
    "nazad": "Geri",
    "save": "Kayıt etmek",
    "zagruzit_foto": "Bir fotoğraf yükle",
    "clear": "Açık",
    "logoIsAdd": "logo eklendi",
    "logoIsDel": "Logo temizlendi",
    "editComp": "Şirketi düzenle",
    "giveComp": "transfer şirketi",
    "thisUserNorRegistered": "Bu kullanıcı kayıtlı değil",
    "cantTransferToYou": "Şirketinizi kendinize devredemezsiniz.",
    "youNotOwner": "şirketin sahibi değilsin",
    "Ok": "Tamam",
    "notYetCreateComp": "Şu anda, yeni şirketlerin oluşturulması mümkün değil",
    "EnterToCompany": "şirkete giriş yap",
    "startDescription": "Kişisel hesabınıza hoş geldiniz, başlamak için şirkete giriş yapın",
    "inviteComp": "şirkete davet",
    "visitDemo": "Demo şirketini ziyaret edin",
    "makeUrChoice": "Bir seçim yapmak",
    "ImWorker": "ben bir çalışanım",
    "ImOwner": "Ben bir işverenim, işveren vekili",
    "needInviteComp": "Mevcut bir şirketin davetini kabul etmelisiniz",
    "needCreateComp": "Başlamak için bir şirket oluşturmanız gerekiyor",
    "daysLeft": "Kalan günler",
    "cannotDel": "Kaldırma şu anda kullanılamıyor",
    "NoCompanyCreateText": "Yazar kasa ile çalışmaya başlamak için işverenden gelen bir daveti kabul etmeniz gerekir.",
    "withoutName": "İsimsiz",
    "cropImageHint": "Yüklemek için bir dosyayı tıklayın veya sürükleyin",
    "cropImageNoSupported": "Tarayıcı desteklenmiyor, lütfen IE10+ veya diğer tarayıcıları kullanın.",
    "cropImageSuccess": "İndirme başarılı",
    "cropImageFail": "Yükleme başarısız",
    "cropImageOnlyImgNeed": "Yalnızca resimler",
    "cropImageOutOfSize": "Resim boyutu sınırı aşıyor",
    "cropImageLowestPx": "Minimum resim boyutu",
    "uReachedLimitComp": "Oluşturulan şirket sayısı sınırına ulaştınız",
    "accAlreadyRegister": "Bu hesap zaten başka bir hesaba bağlı",
    "accNotRegister": "Böyle bir hesap mevcut değil",
    "areYourCountry": "burası senin ülken mi?",
    "enterCorrectNumber": "Numarayı uluslararası formatta girin",
    "attach": "bağlamak",
    "UserEmailAlreadyExists": "Bu emaile sahip kullanıcı zaten var",
    "AccountAlreadyLinked": "Hesap zaten bağlı",
    "facebookAccIsAlreadyRegistered": "Bu Facebook hesabı zaten başka bir Skyservice hesabına bağlı",
    "googleAccIsAlreadyRegistered": "Bu Google hesabı zaten başka bir Skyservice hesabına bağlı",
    "telegramAccAlreadyRegister": "Bu Telegram zaten başka bir Skyservice hesabına bağlı",
    "companyBlocked": "Bu şirket aktif değil",
    "allSessions": "Tüm oturumları göster",
    "activeSessions": "Aktif oturumlar",
    "exitAllDevices": "Diğer tüm cihazlarda oturumu kapatın",
    "currentSession": "Geçerli oturum",
    "autoCloseSessions": "Oturum otomatik tamamlama",
    "nonActivePeriod": "Oturum etkin değilse otomatik olarak sonlandır",
    "oneWeek": "1 hafta",
    "oneMonth": "1 ay",
    "threeMonth": "3 ay",
    "sixMonth": "6 ay",
    "terminate": "Tamamlamak",
    "sessionDesc1": "Skyservice hesabınızda oturum açmış tüm cihazlar burada listelenir. Her birinde ayrı ayrı veya hepsinde birden hesabınızdan çıkış yapabilirsiniz.",
    "sessionDesc2": "Listede tanımadığınız bir cihaz bulursanız, hemen bu cihazda oturumu kapatın ve Skyservice hesabınızın şifresini değiştirin.",
    "sureToTerminate": "Tüm etkin oturumları sonlandırmak istediğinizden emin misiniz?",
    "ip": "IP adresi",
    "geoPos": "coğrafi konum",
    "info": "Bilgi",
    "sessionDesc3": "Bu süre içerisinde herhangi bir aktivite olmayan seanslar otomatik olarak sonlandırılacaktır.",
    "terminatesSessions": "Oturumları sonlandırmak",
    "more3monthAgo": "Üç aydan fazla bir süre önce",
    "noData": "Veri yok",
    "goSettingsCompany": "Şirketinizi oluşturuyoruz, bize bir dakikanızı ayırın...",
    "preparingCompany1": "Şirketinizi oluşturuyoruz, lütfen bize bir dakikanızı ayırın...",
    "preparingCompany2": "Mal ve hizmetlerin yönetimi için bir arayüz hazırlıyoruz...",
    "preparingCompany3": "Uygun finansal muhasebe üzerinde çalışıyoruz...",
    "preparingCompany4": "Son ayarları yapıyoruz, neredeyse hazır...",
    "appleAccIsAlreadyRegistered": "Bu AppleID zaten başka bir Skyservice hesabına bağlı",
    "notGetDataFromApple": "Apple'dan veri alınamadı"
}

export default function() {
    return lang;
}