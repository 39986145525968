const lang = {
    "ToAccept": "Қабул кардан",
    "Reject": "Рад кардан",
    "Password": "Рамз",
    "RepeatPassword": "такрори калидвожа",
    "OldPassword": "Калидвожаи куҳна",
    "MyCompany": "Ширкатҳои ман",
    "Company": "Ширкат",
    "CreateNewCompany": "Ширкати худро эҷод кунед",
    "Сancel": "Бекор кардан",
    "Save": "Захира кунед",
    "GeneralInformation": "Маълумоти Умумӣ",
    "CompanyName": "Номи ширкат",
    "LogOut": "Баромадан",
    "SignOutOfAccount": "баромадан",
    "ChangePassword": "Ивази калидвожа",
    "AddPassword": "Парол илова кунед",
    "Language": "Забон",
    "ConfirmThisMail": "Почтаи электронии шумо тасдиқ карда шуд",
    "Invites": "даъватномахо",
    "AcceptInvitation": "Даъватро қабул кунед",
    "EMailOrPhone": "Телефон ё почта",
    "Saved": "Захира карда шуд",
    "Error": "Хатогӣ",
    "FillInAllTheFields": "Ҳама майдонҳоро пур кунед",
    "FirstCreateAnAccountPassword": "Аввалан, пароли ҳисоб эҷод кунед",
    "Settings": "Танзимотҳо",
    "Next": "Минбаъд",
    "MinPassLength": "Рамз хеле кӯтоҳ аст",
    "action": "Амал",
    "NewOwner": "Соҳиби нав",
    "UserMustBeRegistered": "Истифодабаранда бояд ба қайд гирифта шавад",
    "LeaveTheCompany": "Ширкатро тарк кунед",
    "Confirm": "Тасдиқ кунед",
    "WrongFormat": "Формат нодуруст",
    "DeleteAccount": "Ҳисобро нест кунед",
    "WrongPassword": "Пароли нодуруст",
    "PhoneAlreadyRegistered": "Телефон аллакай ба қайд гирифта шудааст",
    "EmailAlreadyRegistered": "Почта аллакай ба қайд гирифта шудааст",
    "Yes": "Бале",
    "CreateACompany": "Ширкат эҷод кунед",
    "MyReferrals": "Муроҷиатҳои ман",
    "Edit": "Тағйир",
    "NewEmail": "Почтаи нав",
    "Proceed": "Давом додан",
    "IncorrectCaptcha": "Captcha нодуруст",
    "CaptchaIsNotValid": "Captcha дигар эътибор надорад",
    "EnterCaptcha": "captcha ворид кунед",
    "EnterSMS": "SMS ворид кунед",
    "SMSCodeIsNoLongerValid": "Рамзи SMS дигар эътибор надорад",
    "SMSCodeIsIncorrect": "Рамзи SMS нодуруст аст",
    "MailNotVerifiedLinkTimedOut": "Почта тасдиқ нашудааст, истинод ба охир расид",
    "ReferralLink": "Истиноди муроҷиат",
    "Date": "санаи",
    "EarnWithUs": "Бо мо пул кор кунед",
    "RecommendSkyservicePOSToYourFriendsAndGetRealMoneyToYourAccount.": "Skyservice POS-ро ба дӯстони худ тавсия диҳед ва дар ҳисоби худ пули ҳақиқӣ ба даст оред.",
    "HowItWorks": "Он чӣ гуна кор мекунад?",
    "ItsVerySimpleYouBringCustomersToSkyservicePOSAndWeShareTheirPaymentsWithYou.": "Ин хеле содда аст: шумо муштариёнро ба Skyservice POS меоред ва мо пардохтҳои онҳоро бо шумо мубодила мекунем.",
    "Copied": "Нусхабардорӣ",
    "TheMinimumWithdrawalAmountIs100USD": "*Маблағи ҳадди ақали гирифтани 100 доллари ИМА",
    "AddEmail": "Почта илова кунед",
    "YouDontHaveCompanyYet": "Шумо ҳоло ширкат надоред",
    "NoCompanyText": "Барои оғози кор бо мошини хазинавӣ, шумо бояд ширкат таъсис диҳед ё даъвати корфарморо қабул кунед.",
    "ConfirmEmail": "Барои тасдиқи тағирот паёми электронӣ фиристода шуд.",
    "description": "Тавсифи",
    "NoDescriptionSpecified": "Тавсиф нест",
    "DateCreate": "Санаи таъсис:",
    "DelComp": "Ширкатро нест кунед",
    "Help": "Дастгирӣ",
    "Email": "Почта",
    "Phone": "Телефон",
    "SureAccDel": "Шумо мутмаин ҳастед, ки мехоҳед ҳисоби худро ҷудо кунед?",
    "no": "Не",
    "yes": "Бале",
    "confirm": "Тасдиқ кунед",
    "cancel": "Бекор кардан",
    "Search": "Ҷустуҷӯ",
    "dateDelete": "Санаи несткунӣ:",
    "active": "Фаъол",
    "delete": "Хориҷ карда шуд",
    "services": "Хизматҳо",
    "status": "Статус",
    "recover": "Аз нав барқарор кардан",
    "restored": "барқарор карда шуд",
    "restore": "Барқароршавӣ",
    "DoYouWantRestore": "Оё шумо мехоҳед маъракаро барқарор кунед",
    "deletedCompamies": "ШИРКАТХОИ ДУРДАСТ",
    "CompanyNotSearch": "Ягон ширкате ёфт нашуд, ки ба меъёрҳои шумо мувофиқат кунад",
    "writeCorrectlyName": "Номи корбарро дуруст ворид кунед",
    "addlogo": "Лого илова кунед",
    "Logo": "Лого",
    "nipmg": "Файлро ба ин тиреза клик кунед ё кашед",
    "ErrorDownload": "хатогии боркунӣ",
    "preview": "пешнамоиш",
    "onlyimg": "Танҳо тасвирҳо",
    "imgisbig": "Тасвир аз маҳдудияти андоза зиёд аст",
    "minimgsize": "Андозаи ҳадди ақали тасвир",
    "Zagruzka...": "Loading...",
    "Youbrowserisold": "Браузери шумо дастгирӣ намешавад, лутфан IE10+ ё браузерҳои дигарро истифода баред",
    "failZagruzhen": "Файл бомуваффақият бор карда шуд!",
    "zakrit": "наздик",
    "nazad": "Бозгашт",
    "save": "Захира кунед",
    "zagruzit_foto": "Аксро бор кунед",
    "clear": "Тоза",
    "logoIsAdd": "Лого илова карда шуд",
    "logoIsDel": "Лого тоза карда шуд",
    "editComp": "Таҳрири ширкат",
    "giveComp": "Ширкати интиқол",
    "thisUserNorRegistered": "Ин корбар сабти ном нашудааст",
    "cantTransferToYou": "Шумо наметавонед ширкати худро ба худатон интиқол диҳед",
    "youNotOwner": "Шумо соҳиби ширкат нестед",
    "Ok": "Хуб",
    "notYetCreateComp": "Дар айни замон таъсиси ширкатҳои нав дастрас нест",
    "EnterToCompany": "Ба ширкат ворид шавед",
    "startDescription": "Хуш омадед ба ҳисоби шахсии худ, барои оғоз ба ширкат ворид шавед",
    "inviteComp": "Даъват ба ширкат",
    "visitDemo": "Боздид аз ширкати намоишӣ",
    "makeUrChoice": "Интихоб кунед",
    "ImWorker": "Ман коргар ҳастам",
    "ImOwner": "Ман корфармо, намояндаи корфармо ҳастам",
    "needInviteComp": "Шумо бояд даъватро ба ширкати мавҷуда қабул кунед",
    "needCreateComp": "Барои оғози кор шумо бояд як ширкат эҷод кунед",
    "daysLeft": "Рӯзҳо мондаанд",
    "cannotDel": "Нест кардан дар айни замон дастнорас аст",
    "NoCompanyCreateText": "Барои оғози кор бо мошини хазинавӣ, шумо бояд даъватномаи корфарморо қабул кунед.",
    "withoutName": "Ном нест",
    "cropImageHint": "Барои боргузорӣ файлро клик кунед ё кашед",
    "cropImageNoSupported": "Браузер дастгирӣ намешавад, лутфан IE10+ ё браузерҳои дигарро истифода баред.",
    "cropImageSuccess": "Зеркашӣ бомуваффақият",
    "cropImageFail": "Зеркашӣ ноком шуд",
    "cropImageOnlyImgNeed": "Танҳо тасвирҳо",
    "cropImageOutOfSize": "Андозаи тасвир аз меъёр зиёд аст",
    "cropImageLowestPx": "Андозаи ҳадди ақали тасвир",
    "uReachedLimitComp": "Шумо ба маҳдудияти шумораи ширкатҳои таъсисшуда расидед",
    "accAlreadyRegister": "Ин ҳисоб аллакай ба ҳисоби дигар пайваст карда шудааст",
    "accNotRegister": "Чунин ҳисоб вуҷуд надорад",
    "areYourCountry": "ин кишвари шумост?",
    "enterCorrectNumber": "Рақамро дар формати байналмилалӣ ворид кунед",
    "attach": "баста",
    "UserEmailAlreadyExists": "Корбар бо ин почтаи электронӣ аллакай вуҷуд дорад",
    "AccountAlreadyLinked": "Ҳисоб аллакай пайваст карда шудааст",
    "facebookAccIsAlreadyRegistered": "Ин ҳисоби Facebook аллакай ба ҳисоби дигари Skyservice пайваст карда шудааст",
    "googleAccIsAlreadyRegistered": "Ин ҳисоби Google аллакай ба ҳисоби дигари Skyservice пайваст карда шудааст",
    "telegramAccAlreadyRegister": "Ин Telegram аллакай ба ҳисоби дигари Skyservice пайваст шудааст",
    "companyBlocked": "Ин ширкат ғайрифаъол аст",
    "allSessions": "Ҳама сессияҳоро нишон диҳед",
    "activeSessions": "Ҷаласаҳои фаъол",
    "exitAllDevices": "Дар ҳама дастгоҳҳои дигар хориҷ шавед",
    "currentSession": "Сессияи ҷорӣ",
    "autoCloseSessions": "Ба таври худкор анҷом додани сессия",
    "nonActivePeriod": "Агар сессия фаъол набошад, ба таври худкор хотима меёбад",
    "oneWeek": "1 Ҳафта",
    "oneMonth": "1 мох",
    "threeMonth": "3 моҳ",
    "sixMonth": "6 моҳ",
    "terminate": "Анҷом",
    "sessionDesc1": "Ҳамаи дастгоҳҳое, ки ба ҳисоби Skyservice-и шумо ворид шудаанд, дар ин ҷо номбар шудаанд. Шумо метавонед аз ҳисоби худ дар ҳар яки онҳо алоҳида ё дар як вақт баромад кунед.",
    "sessionDesc2": "Агар шумо дар рӯйхат дастгоҳи ношиносеро пайдо кунед, фавран аз ин дастгоҳ хориҷ шавед ва пароли ҳисоби Skyservice-и худро иваз кунед.",
    "sureToTerminate": "Шумо мутмаин ҳастед, ки мехоҳед ҳамаи ҷаласаҳои фаъолро хотима диҳед?",
    "ip": "Суроғаи IP",
    "geoPos": "Геопозиция",
    "info": "Маълумот",
    "sessionDesc3": "Сессияҳое, ки дар ин давра фаъолият надоранд, ба таври худкор хотима меёбанд.",
    "terminatesSessions": "Сеансҳои хотимавӣ",
    "more3monthAgo": "Зиёда аз се мох пеш",
    "noData": "Маълумот нест",
    "goSettingsCompany": "Мо ширкати шуморо эҷод мекунем, ба мо як дақиқа вақт диҳед...",
    "preparingCompany1": "Мо ширкати шуморо таъсис медиҳем, лутфан ба мо як дақиқа вақт диҳед...",
    "preparingCompany2": "Мо интерфейси идоракунии молҳо ва хидматҳоро омода карда истодаем...",
    "preparingCompany3": "Мо дар бораи баҳисобгирии молиявии қулай кор мекунем...",
    "preparingCompany4": "Мо танзимоти ниҳоиро анҷом дода истодаем, қариб омода...",
    "appleAccIsAlreadyRegistered": "Ин AppleId аллакай ба ҳисоби дигари Skyservice пайваст шудааст",
    "notGetDataFromApple": "Қабули маълумот аз Apple натавонист"
}

export default function() {
    return lang;
}