<template>
    <smallModal @escape="$emit('close')">
        <div class="head-div">
            <div class="h5-div"><h5 class="larger"><b>{{ lang['inviteComp'] }}</b></h5></div>
            <div class="icon-div" @click="$emit('close')"><b-icon-x scale="2"></b-icon-x></div>
        </div>
        <hr />
        <h6>{{ lang['AcceptInvitation'] }} "{{ companyData.company_name }}"?</h6>
        <hr>
        <div class="buttons">
            <button class="btn btn-danger" @click="deleteInvite">{{ lang['Reject'] }}</button>
            <button class="btn btn-success" @click="save">{{ lang['ToAccept'] }}</button>
        </div>
    </smallModal>
</template>

<script>
import { regAPi } from '@/API.js';
import { store } from '@/store';
import smallModal from '../modals/smallModal';

const apiList = new regAPi();
export default {
    props: ['companyData'],
    components: {
        smallModal
    },
    data() {
        return {
            login: ''
        }
    },
    methods: {
        /**
         * Сохраняем инвапйт от компании
         * компания сразу переносится в список компаний
         */
        save() {
            try {
                apiList.acceptInv({ company_id: this.companyData.company_id, token: store.token }).then(resp => {
                    if (resp.data.status == 'done') {
                        window.say.message(this.lang['Saved']);
                        this.$emit('close')
                    } else {
                        window.say.messageError(this.lang['Error']);
                    }
                })
            } catch(err) {
                window.say.messageError(this.lang['Error']);
            }
        },
        // Удаляем приглос от компании
        deleteInvite() {
            try {
                apiList.canselInv({ company_id: this.companyData.company_id, token: store.token }).then(resp => {
                    if (resp.data.status == 'done') {
                        window.say.message(this.lang['Saved']);
                        this.$emit('close')
                    } else {
                        window.say.messageError(this.lang['Error']);
                    }
                })
            } catch(err) {
                window.say.messageError(this.lang['Error']);
            }
        }
    },
    computed: {
        lang() {
            return window.lang
        }
    }
}
</script>

<style scoped>
.buttons{
    display: flex; 
    justify-content: space-between;
}
.btn-danger, .btn-success{
  width: 49%;
}
.h5-div{
    padding-top: 7px;
    padding-right: 30px;
}
.icon-div{
    margin-left:auto;
    cursor:pointer
}
.head-div{
    display: flex;
    margin-bottom: 1rem;
}
.larger{
    font-size: larger;
}
</style>